import * as Yup from 'yup'

export const schemaAgent = (t: (key: string) => string) => ({
  name: Yup.string().required(t('requiredMessage')),
  supplierWarehouse: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(t('requiredMessage')),
        number: Yup.string().required(t('requiredMessage')),
        address: Yup.object().shape({
          country: Yup.string().required(t('requiredMessage')),
          city: Yup.string().required(t('requiredMessage')),
          neighborhood: Yup.string().required(t('requiredMessage')),
          postalCode: Yup.string().required(t('requiredMessage')),
          state: Yup.string().required(t('requiredMessage')),
          street: Yup.string().required(t('requiredMessage')),
        }),
      }),
    )
    .optional(),
})
