import { useEffect, useState } from 'react'

import {
  Alert,
  Button,
  Input,
  InputNumber,
  Label,
  Select,
  SelectSearch,
  Tooltip,
  QuestionFillIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, onValidationSchema } from '@/helpers'

import { useSupplierList } from '@/services'

import { CatalogSearch } from '@/components'

import {
  shemaProductItemCatalogProduct,
  shemaProductItemNonCatalogProduct,
} from './product-form.constants'

import type { ProductFormProps } from './product-form.type'

import {
  QuotationProductProps,
  QuotationNonCatalogProductProps,
  CatalogProductProps,
  SupplierProps,
  QuotationProductPackageEnum,
} from '@/types'

import styles from './product-form.module.css'

export const ProductForm = ({
  onChangeValues,
  values,
  onNavigateToPreviousStep,
  onHandleSubmit,
  isNewRegister,
}: ProductFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2List')

  const [type, setType] = useState('catalog')
  const [loadedType, setLoadedType] = useState(false)

  const [errors, setErrors] = useState<Record<string, any>>({}) // eslint-disable-line @typescript-eslint/no-explicit-any
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  const [supplierSearch, setSupplierSearch] = useState<string>('')

  const { data: dataSearch, isLoading: isLoadingSearch } = useSupplierList({
    params: { search: supplierSearch },
  })

  useEffect(() => {
    const validate = async () => {
      const schema =
        type === 'catalog'
          ? shemaProductItemCatalogProduct
          : shemaProductItemNonCatalogProduct

      const response = await onValidationSchema(schema(t), values)
      setIsValid(response.isValid)

      if (listenErrors) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [listenErrors, values, type])

  useEffect(() => {
    if (!loadedType) {
      if (!values?.catalogProduct?.uuid && !isNewRegister) {
        setType('rfq')
      } else {
        setType('catalog')
      }

      setLoadedType(true)
    }
  }, [values?.catalogProduct?.uuid])

  const onHandleValues = (key: string, value: string | number) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleValuesProduct = (key: string, value: string | number) => {
    onChangeValues({
      ...values,
      nonCatalogProduct: {
        ...values?.nonCatalogProduct,
        [key]: value,
      } as QuotationNonCatalogProductProps,
    })
  }

  const onSelectCatalogProduct = (
    catalogProductParam: Partial<CatalogProductProps>,
  ) => {
    if (!catalogProductParam) {
      onChangeValues({
        ...values,
        catalogProduct: undefined,
        catalogProductUuid: undefined,
      })

      return
    }

    onChangeValues({
      ...values,
      catalogProduct: catalogProductParam as CatalogProductProps,
      catalogProductUuid: catalogProductParam?.uuid,
    })
  }

  const onSelectSupplier = (supplier: Partial<SupplierProps>) => {
    onChangeValues({
      ...values,
      supplier: supplier as SupplierProps,
      supplierUuid: supplier?.uuid,
    })
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      setListenErrors(true)
      return
    }

    setListenErrors(false)
    onHandleSubmit()
    onNavigateToPreviousStep()
  }

  const getValuesNumber = (key: keyof QuotationProductProps) => {
    if (values === undefined) return 0

    const value = values[key] as string | number

    return value !== undefined && !isNaN(+value) ? +value : 0
  }

  return (
    <>
      <div className={styles.core}>
        {!isNewRegister && type === 'rfq' && (
          <div>
            <Label>{t('quotationFormProductFormTypeLabel')}</Label>
            <Select
              placeholder={t('quotationFormProductFormTypePlaceholder')}
              options={[
                {
                  label: t('quotationFormProductFormTypeOptionCatalog'),
                  value: 'catalog',
                },
                {
                  label: t('quotationFormProductFormTypeOptionNonCatalog'),
                  value: 'rfq',
                },
              ]}
              value={type}
              onChange={setType}
            />
          </div>
        )}

        {type === 'rfq' && (
          <>
            <div>
              <Label>
                {t('quotationFormProductFormDescriptionProductLabel')}
              </Label>
              <Input
                placeholder={t(
                  'quotationFormProductFormDescriptionProductPlaceholder',
                )}
                value={values?.nonCatalogProduct?.description}
                onChange={(newValue) =>
                  onHandleValuesProduct('description', newValue)
                }
                error={errors?.nonCatalogProduct?.description as string}
              />
            </div>

            <div>
              <Label>{t('quotationFormProductFormLinkProductLabel')}</Label>
              <Input
                placeholder={t(
                  'quotationFormProductFormLinkProductPlaceholder',
                )}
                value={values?.nonCatalogProduct?.link}
                onChange={(newValue) => onHandleValuesProduct('link', newValue)}
                error={errors?.nonCatalogProduct?.link as string}
              />
            </div>
          </>
        )}

        {type === 'catalog' && (
          <CatalogSearch
            onSelectProduct={onSelectCatalogProduct}
            selectedProduct={values?.catalogProduct}
            error={errors?.catalogProduct?.uuid as string}
          />
        )}

        <div>
          <Label>{t('quotationFormProductFormPriceLabel')}</Label>
          <InputNumber
            placeholder={t('quotationFormProductFormPricePlaceholder')}
            showIcons
            value={values?.price || 0}
            error={errors?.price}
            decimalPrecision={3}
            prefix="$ "
            onChange={(newValue) => onHandleValues('price', newValue)}
          />
        </div>

        <div>
          <Label>{t('quotationFormProductFormQuantityLabel')}</Label>
          <InputNumber
            placeholder={t('quotationFormProductFormQuantityPlaceholder')}
            value={values?.quantity || 0}
            error={errors?.quantity}
            decimalPrecision={0}
            showIcons
            suffix=" Kg"
            onChange={(newValue) => onHandleValues('quantity', newValue)}
            stepSize={(values?.quantity || 0) >= 1000 ? 1000 : 100}
          />
        </div>

        <Alert title={t('quotationFormProductFormTotalPriceLabel')}>
          {t('quotationFormProductFormTotalPriceDescription')}{' '}
          {formatCurrency(
            (values?.price || 0) * (values?.quantity || 0),
            'es-MX',
            'USD',
          )}
        </Alert>

        <div>
          <Label>{t('quotationFormProductFormPackageLabel')}</Label>
          <Select
            placeholder={t('quotationFormProductFormPackagePlaceholder')}
            options={Object.entries(QuotationProductPackageEnum).map(
              ([key]) => ({
                label: tEnums(`quotation_items_package_${key}`),
                value: key,
              }),
            )}
            value={values?.package}
            error={errors?.package}
            onChange={(newValue) => onHandleValues('package', newValue)}
          />
        </div>

        <div className={styles['card-backoffice']}>
          <div>
            <Label>
              {t('quotationFormProductFormCostLabel')}
              <Tooltip
                text={t('quotationFormProductCostDescription')}
                widthText="16rem"
                orientation="left"
              >
                <QuestionFillIcon />
              </Tooltip>
            </Label>
            <InputNumber
              placeholder={t('quotationFormProductFormCostPlaceholder')}
              showIcons
              value={values?.cost || 0}
              error={errors?.cost}
              decimalPrecision={3}
              prefix="$ "
              onChange={(newValue) => onHandleValues('cost', newValue)}
            />
          </div>

          <Alert title={t('quotationFormProductTotalCostLabel')}>
            {t('quotationFormProductTotalCostDescription')}{' '}
            {formatCurrency(
              getValuesNumber('cost') * getValuesNumber('quantity'),
              'es-MX',
              'USD',
            )}
          </Alert>

          <div>
            <SelectSearch<SupplierProps>
              dataSearch={dataSearch?.data?.results || []}
              isLoadingSearch={isLoadingSearch}
              onSearch={setSupplierSearch}
              onSelectItem={onSelectSupplier}
              selectedItem={values?.supplier}
              keyLabel="name"
              keyValue="uuid"
              translations={{
                inputSearchLabel: t('quotationFormProductFormSupplierLabel'),
                inputSearchPlaceholder: t(
                  'quotationFormProductFormSupplierPlaceholder',
                ),
                noResults: t('quotationFormProductFormSupplierNoResults'),
              }}
              error={(errors?.supplier?.uuid || errors?.supplier) as string}
            />
          </div>

          <div>
            <Label>
              {t('quotationFormProductFormTraderComissionLabel')}
              <Tooltip
                text={t('quotationFormProductFormTraderComissionDescription')}
                widthText="15rem"
              >
                <QuestionFillIcon />
              </Tooltip>
              <span>{t('quotationFormProductFormOptionalLabel')}</span>
            </Label>
            <InputNumber
              placeholder={t(
                'quotationFormProductFormTraderComissionPlaceholder',
              )}
              value={values?.traderCommission || 0}
              error={errors?.traderCommission}
              showIcons
              decimalPrecision={5}
              prefix="$ "
              onChange={(newValue) =>
                onHandleValues('traderCommission', newValue)
              }
            />
          </div>

          <Alert title={t('quotationFormProductTotalTraderComissionLabel')}>
            {t('quotationFormProductTotalTraderComissionDescription')}{' '}
            {formatCurrency(
              getValuesNumber('traderCommission') * getValuesNumber('quantity'),
              'es-MX',
              'USD',
            )}
          </Alert>
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onNavigateToPreviousStep}>
          {t('navigateButtonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>
          {t('navigateButtonFinish')}
        </Button>
      </div>
    </>
  )
}
