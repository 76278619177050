import { Text, Radio, Skeleton } from '@mercai/clever'
import { AddressOptionsProps } from './address-options.type'

import styles from './address-options.module.css'

export const AddressOptions = ({
  addresses,
  onSelectAddress,
  selectedAddress,
  isLoading,
}: AddressOptionsProps) => {
  if (isLoading) {
    return (
      <div className={styles.core}>
        {[0, 1, 2].map((item) => (
          <button key={item} type="button" className={styles.address}>
            <Skeleton
              height="1.2rem"
              width="1.2rem"
              className={styles['skeleton-radio']}
            />

            <div className={styles['address-content']}>
              <Skeleton height="1.2rem" width="100%" />
              <Skeleton height="1rem" width="100%" />
            </div>
          </button>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.core}>
      {addresses?.map((address) => (
        <button
          className={styles.address}
          key={address.id}
          type="button"
          onClick={() => onSelectAddress(address)}
        >
          <Radio
            value={address.id === selectedAddress?.id}
            onChange={() => onSelectAddress(address)}
          />

          <div className={styles['address-content']}>
            <Text variant="title-base">
              ({address.postalCode}) {address.country} - {address.state}
            </Text>
            <Text>
              {address.city} - {address.neighborhood}
            </Text>
          </div>
        </button>
      ))}
    </div>
  )
}
