import { useEffect, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Button,
  DragDropFileList,
  Input,
  InputNumber,
  Label,
  Select,
  Text,
  Switch,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { onValidationSchema, getErrors } from '@/helpers'
import { QUOTATION_REQUEST_UPLOAD_URL } from '@/services/api.urls'
import { api } from '@/services'
import type { CatalogProductProps } from '@/types'

import { CatalogSearch } from '@/components'

import {
  shemaProductItemCatalogProduct,
  shemaProductItemMetal,
  shemaProductItemPlastic,
} from './product-form.constants'

import type { ProductFormProps } from './product-form.type'

import styles from './product-form.module.css'

export const ProductForm = ({
  onChangeValues,
  values,
  catalogCategoryList,
  onHandleSubmit,
  onNavigateToPreviousStep,
  isNewRegister = false,
}: ProductFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('componentsQuotationRequestProductForm')

  const [type, setType] = useState('catalog')
  const [loadedType, setLoadedType] = useState(false)

  const [errors, setErrors] = useState<Record<string, any>>({}) // eslint-disable-line @typescript-eslint/no-explicit-any
  const [isValid, setIsValid] = useState<boolean>(false)
  const [listenErrors, setListenErrors] = useState<boolean>(false)

  const catalogCategoryFormatted =
    catalogCategoryList?.map((item) => ({
      label: `(${item.shortName}) - ${item.name}`,
      value: item.shortName,
    })) || []

  const categorySelected = catalogCategoryList?.find(
    (item) => item.shortName === values?.product?.category,
  )

  useEffect(() => {
    const validate = async () => {
      const schema =
        type === 'catalog'
          ? shemaProductItemCatalogProduct
          : categorySelected?.fullPath?.includes('Metais')
            ? shemaProductItemMetal
            : shemaProductItemPlastic

      const response = await onValidationSchema(schema(t), values)
      setIsValid(response.isValid)

      if (listenErrors) {
        setErrors(response.errors)
      }
    }

    validate()
  }, [listenErrors, values, type])

  useEffect(() => {
    if (!loadedType) {
      if (values?.catalogProduct?.uuid && !isNewRegister) {
        setType('catalog')
      } else {
        setType('rfq')
      }

      setLoadedType(true)
    }
  }, [values?.catalogProduct?.uuid])

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleValuesProduct = (key: string, value: string | number) => {
    onChangeValues({ ...values, product: { ...values?.product, [key]: value } })
  }

  const onSelectCatalogProduct = (
    catalogProductParam: Partial<CatalogProductProps>,
  ) => {
    if (!catalogProductParam) {
      onChangeValues({
        ...values,
        catalogProduct: undefined,
        catalogProductUuid: undefined,
      })

      return
    }

    onChangeValues({
      ...values,
      catalogProduct: catalogProductParam as CatalogProductProps,
      catalogProductUuid: catalogProductParam?.uuid,
    })
  }

  const uploadFileSuccess = (file: string) => {
    onHandleValuesProduct('itemDataSheet', file)
    onHandleValuesProduct('itemDataSheetPath', file)
  }

  const onHandleSubmitLocal = () => {
    if (!isValid) {
      setListenErrors(true)
      return
    }

    setListenErrors(false)
    onHandleSubmit()
    onNavigateToPreviousStep()
  }

  return (
    <>
      <div className={styles.core}>
        {!isNewRegister && type === 'rfq' && (
          <div>
            <Label>{t('productFormTypeLabel')}</Label>
            <Select
              placeholder={t('productFormTypePlaceholder')}
              options={[
                {
                  label: t('productFormTypeOptionCatalog'),
                  value: 'catalog',
                },
                {
                  label: t('productFormTypeOptionNonCatalog'),
                  value: 'rfq',
                },
              ]}
              value={type}
              onChange={setType}
            />
          </div>
        )}

        {type === 'rfq' && (
          <>
            <div>
              <Label>{t('productFormCategoryLabel')}</Label>
              <Select
                placeholder={t('productFormCategoryPlaceholder')}
                value={
                  values?.product?.category
                    ? String(values?.product?.category).toUpperCase()
                    : ''
                }
                error={getErrors(errors, 'product.category')}
                onChange={(newValue) =>
                  onHandleValuesProduct('category', newValue)
                }
                options={catalogCategoryFormatted}
              />
            </div>

            <div>
              <Label>{t('productFormNameProductLabel')}</Label>
              <Input
                placeholder={t('productFormNameProductPlaceholder')}
                value={values?.product?.title}
                onChange={(newValue) =>
                  onHandleValuesProduct('title', newValue)
                }
                error={
                  getErrors(errors, 'product.title')
                    ? getErrors(errors, 'product.title')
                    : getErrors(errors, 'product.title')
                }
              />
            </div>

            {!categorySelected?.fullPath?.includes('Metais') && (
              <>
                <div>
                  <Label>
                    {t('productFormDensityLabel')}{' '}
                    <span>{t('productFormFieldOptional')}</span>
                  </Label>
                  <InputNumber
                    placeholder={t('productFormDensityPlaceholder')}
                    value={values?.product?.density || 0}
                    error={getErrors(errors, 'products.density')}
                    decimalPrecision={3}
                    onChange={(newValue) =>
                      onHandleValuesProduct('density', newValue)
                    }
                  />
                </div>

                <div>
                  <Label>{t('productFormFluidityLabel')}</Label>
                  <Input
                    placeholder={t('productFormFluidityPlaceholder')}
                    value={(values?.product?.fluidity || '') as string}
                    error={getErrors(errors, 'product.fluidity')}
                    onChange={(newValue) =>
                      onHandleValuesProduct('fluidity', newValue)
                    }
                  />
                </div>
              </>
            )}
          </>
        )}

        {type === 'catalog' && (
          <CatalogSearch
            onSelectProduct={onSelectCatalogProduct}
            selectedProduct={values?.catalogProduct}
            error={errors?.catalogProduct?.uuid || errors?.catalogProduct}
          />
        )}

        <div>
          <Label>{t('productFormQuantityLabel')}</Label>
          <InputNumber
            placeholder={t('productFormQuantityPlaceholder')}
            value={values?.quantity || 0}
            error={getErrors(errors, 'quantity')}
            decimalPrecision={0}
            onChange={(newValue) => onHandleValues('quantity', newValue)}
            showIcons
            stepSize={(values?.quantity || 0) >= 1000 ? 1000 : 100}
          />
          <Alert className={styles['alert-sample']} leftIcon={<AlertIcon />}>
            <Text>
              {t('productFormSampleExplanation')}{' '}
              <strong>{t('productFormSample')}</strong>
            </Text>
          </Alert>
        </div>

        <div>
          <Label>
            {t('productFormTargetPriceLabel')}
            <span>{t('productFormFieldOptional')}</span>
          </Label>
          <InputNumber
            placeholder={t('productFormTargetPricePlaceholder')}
            value={values?.targetPrice || 0}
            error={getErrors(errors, 'targetPrice')}
            onChange={(newValue) => onHandleValues('targetPrice', newValue)}
            showIcons
            stepSize={0.1}
          />
        </div>

        <div>
          <Label>{t('productFormIsSimilarAcceptedLabel')}</Label>

          <Switch
            checked={values?.isSimilarAccepted || false}
            onChange={(checked) => onHandleValues('isSimilarAccepted', checked)}
          />
        </div>

        <div>
          <Label>{t('productFormFrequencyLabel')}</Label>
          <Select
            placeholder={t('productFormFrequencyPlaceholder')}
            options={[
              {
                label: tEnums('quotation_request_frequency_weekly'),
                value: 'weekly',
              },
              {
                label: tEnums('quotation_request_frequency_biweekly'),
                value: 'biweekly',
              },
              {
                label: tEnums('quotation_request_frequency_monthly'),
                value: 'monthly',
              },
              {
                label: tEnums('quotation_request_frequency_bimonthly'),
                value: 'bimonthly',
              },
              {
                label: tEnums('quotation_request_frequency_quarterly'),
                value: 'quarterly',
              },
              {
                label: tEnums('quotation_request_frequency_semiannualy'),
                value: 'semiannualy',
              },
            ]}
            value={values?.purchaseFrequency}
            error={getErrors(errors, 'purchaseFrequency')}
            onChange={(newValue) =>
              onHandleValues('purchaseFrequency', newValue)
            }
          />
        </div>

        <div>
          <Label>
            {t('productFormFile')} <span>{t('productFormFieldOptional')}</span>
          </Label>
          <Text className={styles['file-observation']}>
            {t('productFormFileObservation')}
          </Text>

          <DragDropFileList
            axiosInstance={api}
            urlAdd={QUOTATION_REQUEST_UPLOAD_URL}
            configsRequest={{
              fileField: 'file',
              method: 'POST',
            }}
            initialData={[]}
            successedUpload={(file) => uploadFileSuccess(file?.filename || '')}
            translations={{
              clickToUpload: t('productFormFileButton'),
              dragFile: t('productFormFileDrag'),
              sizeFile: t('productFormFileSize'),
            }}
            error={getErrors(errors, 'file')}
          />
        </div>

        <div>
          <Label>
            {t('productFormObservationLabel')}{' '}
            <span>{t('productFormFieldOptional')}</span>
          </Label>
          <Input
            isTextArea
            placeholder={t('productFormObservationPlaceholder')}
            value={values?.observation}
            error={getErrors(errors, 'observation')}
            onChange={(newValue) => onHandleValues('observation', newValue)}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button variant="secondary" onClick={onNavigateToPreviousStep}>
          {t('navigateButtonBack')}
        </Button>

        <Button onClick={onHandleSubmitLocal}>
          {t('navigateButtonFinish')}
        </Button>
      </div>
    </>
  )
}
