import { ReactNode, useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  DropDown,
  FilterIcon,
  TableColumns,
  Tag,
  Text,
  Tooltip,
  Select,
  Skeleton,
  Pagination,
  PlusIcon,
  TableColumnsColumnProps,
  TrashIcon,
  InputSearch,
  ArrowRightIcon,
  AlertIcon,
  BurguerIcon,
  StarsIcon,
} from '@mercai/clever'

import { appQuotationLink, DEFAULT_LIMIT_PAGE } from '@/constants'
import {
  formatDate,
  useParamsState,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
  formatCurrency,
  calculateQuotationValues,
  trackEvent,
} from '@/helpers'
import {
  useQuotationList,
  useDeleteQuotation,
  useQuotationClassification,
  useQuotationSlas,
} from '@/services'
import { useAuth } from '@/providers'
import { ROUTES_ADMIN } from '@/router'
import { QuotationProps, QuotationStatusEnum } from '@/types'

import type { QuotationListProps } from './list.type'

import styles from './list.module.css'
import { Link, useLocation } from 'react-router-dom'
import {
  BusinessMetricCards,
  CatalogProductsCarousel,
  Tabs,
} from '../../components'
import {
  generateOrderingButtonClasses,
  getReferenceColumnStatus,
  ReferenceColumnStatusEnum,
} from './list.constants'
import { EmptyState } from '@/components'
import { QuotationDrawerFilter, QuotationDrawerForm } from './components'
import { TagMoscow } from '@/pages/profile/components'

export const QuotationV2List = ({ buyerProfile }: QuotationListProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2List')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)

  const [totalPages, setTotalPages] = useState(1)

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    recurrencyOption: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
    classification_group: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'classification_group' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleOrdering = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const { data, isLoading, refetch } = useQuotationList({
    ...filter,
    ...(buyerProfile ? { buyerProfile } : {}),
  })

  const { data: dataClassification, isLoading: isLoadingClassification } =
    useQuotationClassification()

  const { data: dataSlas, isLoading: isLoadingSlas } = useQuotationSlas()

  const { isPending, mutateAsync: mutateDelete } = useDeleteQuotation()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationTableDeleteConfirmTitle'),
      t('quotationTableDeleteConfirmMessage'),
      t('quotationTableDeleteConfirmButtonDelete'),
      t('quotationTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('quotationTableDeleteSuccessTitle'),
          t('quotationTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('quotationTableDeleteErrorTitle'),
          t('quotationTableDeleteErrorMessage'),
        )
      }
    }
  }

  const refereceColumn = ({ record }: { record: QuotationProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    const referenceStatusObject: Record<ReferenceColumnStatusEnum, ReactNode> =
      {
        default: (
          <div className={`${styles['reference-column']}`}>
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
          </div>
        ),
        success: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-success']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationTableReferenceTooltipSuccess')}
              orientation="right"
            >
              <StarsIcon />
            </Tooltip>
          </div>
        ),
        warning: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-warning']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationTableReferenceTooltipWarning')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
        danger: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-danger']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationTableReferenceTooltipDanger')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
      }

    return referenceStatusObject[referenceStatus]
  }

  const bodyColumn = ({ record }: { record: QuotationProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    if (record?.status === 'pending' && referenceStatus === 'danger') {
      return styles['column-danger']
    }

    return ''
  }

  const stateObject: Record<QuotationStatusEnum, ReactNode> = {
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_status_pending')}
      </Tag>
    ),
    active: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_status_active')}
      </Tag>
    ),
    accepted: (
      <Tag variant="success" model="light">
        {tEnums('quotation_status_accepted')}
      </Tag>
    ),
    expired: (
      <Tag variant="basic" model="light">
        {tEnums('quotation_status_expired')}
      </Tag>
    ),
    rejected: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_status_rejected')}
      </Tag>
    ),
    pending_approval: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_status_pending_approval')}
      </Tag>
    ),
    cancelled: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_status_cancelled')}
      </Tag>
    ),
  }

  const metrics = [
    {
      value: t('quotationMetricHours', {
        value: (dataSlas?.data?.pendingToActiveSla || 0).toFixed(2),
      }),
      label: t('quotationMetricSLAPendingToActive'),
      change: dataSlas?.data?.pendingToActiveSlaVariation || 0,
      isLoading: isLoadingSlas,
    },
    {
      value: `${(dataSlas?.data?.pendingToActiveConvertion || 0).toFixed(2)}%`,
      label: t('quotationMetricConversionPendingToActive'),
      change: dataSlas?.data?.pendingToActiveConvertionVariation || 0,
      isLoading: isLoadingSlas,
    },
    {
      value: t('quotationMetricHours', {
        value: (dataSlas?.data?.activeToAcceptedSla || 0).toFixed(2),
      }),
      label: t('quotationMetricSLAActiveToAccepted'),
      change: dataSlas?.data?.activeToAcceptedSlaVariation || 0,
      isLoading: isLoadingSlas,
    },
    {
      value: `${(dataSlas?.data?.activeToAcceptedConvertion || 0).toFixed(2)}%`,
      label: t('quotationMetricConversionActiveToAccepted'),
      change: dataSlas?.data?.activeToAcceptedConvertionVariation || 0,
      isLoading: isLoadingSlas,
    },
  ]

  const columns: TableColumnsColumnProps<QuotationProps>[] = [
    {
      title: t('quotationTableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9.6rem',
      render: ({ record }) => refereceColumn({ record }),
    },
    {
      title: t('quotationTableBuyerName'),
      key: 'buyerProfile.companyName',
      dataKey: 'buyerProfile.companyName',
      render: ({ record }) => (
        <div className={styles['buyer-profile']}>
          <Tooltip
            text={record?.buyerProfile?.companyName || '-'}
            orientation="top"
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(
                record?.buyerProfile?.uuid,
              )}
              className={styles.link}
            >
              {record?.buyerProfile?.companyName || '-'}
            </Link>
          </Tooltip>
          <Tooltip text={record?.buyerProfile?.email || '-'}>
            <div>
              <Text variant="text-small" className={styles.email}>
                {record?.buyerProfile?.email || '-'}
              </Text>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('quotationTableState'),
      key: 'status',
      dataKey: 'status',
      render: ({ record }) =>
        stateObject[(record?.status || 'pending') as QuotationStatusEnum] ||
        '-',
      width: '9rem',
    },
    {
      title: t('quotationTableAmount'),
      key: 'totalPrice',
      dataKey: 'totalPrice',
      render: ({ value }) =>
        value ? formatCurrency(value, 'es-MX', 'USD') : '-',
      width: '12rem',
    },
    {
      title: t('quotationTableExpiredAt'),
      key: 'expiresAt',
      dataKey: 'expiresAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
      width: '8rem',
    },
    {
      title: t('quotationTableKAM'),
      key: 'buyerProfile.keyAccountManager',
      dataKey: 'buyerProfile.keyAccountManager',
      render: ({ value }) => value || '-',
      width: '15rem',
    },
    {
      title: isSuperUser ? t('quotationTableActions') : '',
      key: 'uuid',
      dataKey: 'uuid',
      width: isSuperUser ? '8rem' : '4.375rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            model="square"
            variant="primary"
            href={ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(record.uuid)}
          >
            <ArrowRightIcon />
          </Button>
          {isSuperUser && (
            <Button
              model="square"
              variant="danger"
              onClick={() => onHandleDelete(record.uuid)}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      ),
      renderLoading: (
        <div className={styles['action-buttons']}>
          <Skeleton height="2rem" width="2rem" />
          {isSuperUser && <Skeleton height="2rem" width="2rem" />}
        </div>
      ),
    },
  ]

  const ExpandedComponent = ({ record }: { record: QuotationProps }) => {
    const { discount, iva, subTotal, total } = calculateQuotationValues(
      record.products || [],
      record?.deliveryOption === 'pick_up'
        ? 0
        : +(record?.totalFreightPrice || 0),
      +(record?.discount || 0),
      record?.includeIva,
    )

    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('quotationTableDelivery')}</strong>
            {': '}
            {record?.deliveryAddress?.city || '-'}
          </Text>
          <Text>
            <strong>{t('quotationTablePhone')}</strong>
            {': '}
            {record?.buyerProfile?.phone || '-'}
          </Text>
          <Text>
            <div className={styles.moscow}>
              <strong>
                {t('quotationTableMoscow')}
                {': '}
              </strong>
              <TagMoscow
                moscowClassification={
                  record?.buyerProfile?.moscowClassification
                }
              />
            </div>
          </Text>
          <Text>
            <strong>
              {t('quotationTableRFQ')}
              {': '}
            </strong>
            {record?.quotationRequest ? (
              <Link
                to={ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(
                  record?.quotationRequest.uuid,
                )}
                className={styles.link}
                target="_blank"
              >
                {`#${record?.quotationRequest.reference}`}
              </Link>
            ) : (
              <Text>-</Text>
            )}
          </Text>
          <Text>
            <strong>
              {t('quotationTableOrder')}
              {': '}
            </strong>
            {record?.orders?.length > 0 ? (
              record?.orders?.map((order) => (
                <Link
                  to={`${ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.fullPath()}?search=${order.reference}`}
                  className={styles.link}
                  target="_blank"
                  key={order?.uuid}
                >
                  {`#${order?.reference}`}
                </Link>
              ))
            ) : (
              <Text>-</Text>
            )}
          </Text>
          {record?.rejectedReason && (
            <Text>
              <strong>{t('quotationTableRejectedReason')}</strong>
              {': '}
              <Tooltip text={record?.rejectedReason}>
                {record?.rejectedReason?.length > 150
                  ? `${record?.rejectedReason.substring(0, 150)}...`
                  : record?.rejectedReason}
              </Tooltip>
            </Text>
          )}
          <Text>
            <strong>{t('quotationTableCreatorName')}</strong>
            {': '}
            {record?.creatorEmail || '-'}
          </Text>
          <Text>
            <strong>{t('quotationTableAppLink')}</strong>
            {': '}
            <Link
              to={appQuotationLink(record?.uuid || '')}
              className={styles.link}
              target="_blank"
            >
              Link
            </Link>
          </Text>
        </div>
        <div>
          <Text>
            <strong>{t('quotationTableSubtotal')}</strong>
            {': '}
            {formatCurrency(subTotal)}
          </Text>
          <Text>
            <strong>{t('quotationTableDiscount')}</strong>
            {': '}
            {formatCurrency(discount)}
          </Text>
          <Text>
            <strong>{t('quotationTableIVA')}</strong>
            {': '}
            {formatCurrency(iva)}
          </Text>
          <Text>
            <strong>{t('quotationTableTotalCost')}</strong>
            {': '}
            {formatCurrency(total)}
          </Text>
        </div>
        <div className={styles['expanded-content-products']}>
          <CatalogProductsCarousel products={record?.products || []} />
        </div>
      </div>
    )
  }

  const Ordering = useCallback(
    () => (
      <DropDown.Root>
        <DropDown.ButtonTrigger variant="secondary" hasArrow={false}>
          <BurguerIcon />
          {t('quotationTableOrderingButton')}
          {filter?.ordering && (
            <Tag variant="primary" model="light" size="small">
              1
            </Tag>
          )}
        </DropDown.ButtonTrigger>
        <DropDown.Content
          orientation="bottom-right"
          className={styles.ordering}
        >
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'createdAt',
            )}
            onClick={() => onHandleOrdering('createdAt')}
          >
            {t('quotationTableOrderingCreatedAtButton')}
          </div>
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'buyer_profile__moscow_classification',
            )}
            onClick={() =>
              onHandleOrdering('buyer_profile__moscow_classification')
            }
          >
            {t('quotationTableOrderingMoscowButton')}
          </div>
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'buyer_profile__company_name',
            )}
            onClick={() => onHandleOrdering('buyer_profile__company_name')}
          >
            {t('quotationTableOrderingAZButton')}
          </div>
        </DropDown.Content>
      </DropDown.Root>
    ),
    [filter],
  )

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminQuotationListPageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-content']}>
          <div>
            <Text variant="title-large">{t('quotationTitle')}</Text>
            <div>
              <Text variant="text-base" className={styles['sub-title']}>
                {t('quotationSubtitle')}
              </Text>
            </div>
          </div>
          <div className={styles['header-actions']}>
            <Button onClick={onHandleNewRegister}>
              <PlusIcon />
              {t('quotationTableNewQuotationButton')}
            </Button>
          </div>
        </div>
      </header>

      <Tabs
        isLoading={isLoadingClassification}
        hasCount
        items={[
          {
            title: t('quotationTabAll'),
            isActive: !filter.classification_group,
            onSelect: () => handleFilter('classification_group', ''),
            count: dataClassification?.data?.total || 0,
          },
          {
            title: t('quotationTabPending'),
            isActive: filter.classification_group === 'pending',
            onSelect: () => handleFilter('classification_group', 'pending'),
            count: dataClassification?.data?.pending || 0,
          },
          {
            title: t('quotationTabApproval'),
            isActive: filter.classification_group === 'pendingApproval',
            onSelect: () =>
              handleFilter('classification_group', 'pendingApproval'),
            count: dataClassification?.data?.pendingApproval || 0,
          },
          {
            title: t('quotationTabActive'),
            isActive: filter.classification_group === 'active',
            onSelect: () => handleFilter('classification_group', 'active'),
            count: dataClassification?.data?.active || 0,
          },
          {
            title: t('quotationTabAccepted'),
            isActive: filter.classification_group === 'accepted',
            onSelect: () => handleFilter('classification_group', 'accepted'),
            count: dataClassification?.data?.accepted || 0,
          },
          {
            title: t('quotationTabExpired'),
            isActive: filter.classification_group === 'expired',
            onSelect: () => handleFilter('classification_group', 'expired'),
            count: dataClassification?.data?.expired || 0,
          },
          {
            title: t('quotationTabRejected'),
            isActive: filter.classification_group === 'rejected',
            onSelect: () => handleFilter('classification_group', 'rejected'),
            count: dataClassification?.data?.rejected || 0,
          },
        ]}
      />

      <BusinessMetricCards metrics={metrics} />

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('quotationTableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <Ordering />
        </div>
      </div>

      <TableColumns<QuotationProps>
        isLoading={isLoading || isPending}
        columns={columns}
        expandableComponent={ExpandedComponent}
        classNames={{
          bodyColumn,
        }}
        data={data?.data?.results || []}
      />
      {!isLoading && data?.data?.results?.length === 0 && (
        <EmptyState
          title={t('quotationTableEmptyTitle')}
          description={t('quotationTableEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('quotationTableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <QuotationDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <QuotationDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        quotationUuid={queryParams.uuid}
      />
    </div>
  )
}
