export const BASE_URL = import.meta.env.VITE_BASE_URL
export const KAI_BASE_URL = import.meta.env.VITE_KAI_BASE_URL
export const AI_BASE_URL = import.meta.env.VITE_AI_BASE_URL
export const BASE_ADMIN = `${BASE_URL}backoffice/`

export const AUTH_SIGN_IN_URL = `${BASE_URL}api/token/`
export const AUTH_SIGN_IN_REFRESH_URL = `${BASE_URL}api/token/refresh/`
export const WHO_AM_I_URL = `${BASE_URL}users/whoami/`

export const QUOTATIONS_URL = `${BASE_ADMIN}quotations/`
export const QUOTATION_CLASSIFICATION_URL = `${QUOTATIONS_URL}get_classification/`
export const QUOTATION_SLAS_URL = `${QUOTATIONS_URL}get_slas/`
export const QUOTATION_URL = (uuid: string) =>
  `${BASE_ADMIN}quotations/${uuid}/`

export const QUOTATION_REQUESTS_URL = `${BASE_ADMIN}quotations/requests/`
export const QUOTATION_REQUESTS_CLASSIFICATION_URL = `${QUOTATION_REQUESTS_URL}get_classification/`
export const QUOTATION_REQUESTS_SLAS_URL = `${QUOTATION_REQUESTS_URL}get-slas/`
export const QUOTATION_REQUEST_URL = (uuid: string) =>
  `${BASE_ADMIN}quotations/requests/${uuid}/`
export const QUOTATION_REQUEST_UPLOAD_URL = `${BASE_URL}base/files/quotation/upload/`

export const PROFILES_URL = `${BASE_ADMIN}users/profile/`
export const PROFILE_ADD_USER_URL = (uuid: string) =>
  `${BASE_ADMIN}users/profile/${uuid}/add_user/`
export const PROFILE_UPDATE_USER_URL = (uuid: string) =>
  `${BASE_ADMIN}users/profile/${uuid}/update_user/`

export const USERS_URL = `${BASE_ADMIN}users/users/`
export const USER_URL = (uuid: string) => `${BASE_ADMIN}users/users/${uuid}/`

export const ADD_ADDRESS_URL = (profileUuid: string) =>
  `${BASE_ADMIN}users/profile/${profileUuid}/add_address/`
export const ADDRESSES_URL = (profileUuid: string) =>
  `${BASE_ADMIN}users/profile/${profileUuid}/`

export const FINANCE_PROFILE_URL = (uuid: string) =>
  `${BASE_ADMIN}users/profile/finance/${uuid}/`

export const FINANCE_PROFILES_URL = `${BASE_ADMIN}users/profile/finance/`

export const CATALOG_CATEGORIES_URL = `${BASE_URL}catalog/categories/`
export const CATALOG_CATEGORY_URL = (uuid: string) =>
  `${BASE_ADMIN}catalog/categories/${uuid}/`

export const CATALOG_PRODUCT_UPLOAD_URL = `${BASE_URL}base/files/catalog/upload/`
export const CATALOG_PRODUCT_PUBLIC_UPLOAD_URL = (app: string) =>
  `${BASE_URL}base/public_files/${app}/upload/`

export const CATALOG_PRODUCTS_URL = `${BASE_ADMIN}catalog/products/`
export const CATALOG_PRODUCT_URL = (uuid: string) =>
  `${BASE_ADMIN}catalog/products/${uuid}/`

export const CATALOG_PRODUCT_UPDATE_IMAGE_URL = (reference: string) =>
  `${BASE_ADMIN}catalog/products/${reference}/update_product_image/`

export const BASE_FILE_SIGN_URL = `${BASE_URL}base/files/sign/`

export const CATALOG_ATTRIBUTE_OPTION_URL = `${BASE_ADMIN}catalog/attribute-options/`

export const ORDERS_URL = `${BASE_ADMIN}orders/`
export const ORDER_URL = (uuid: string) => `${BASE_ADMIN}orders/${uuid}/`
export const ORDER_PAYMENT_PROOF_URL = (id: string) =>
  `${BASE_URL}orders/payment_proof/${id}/`
export const ORDER_INVOICE_PDF_URL = (id: string) =>
  `${BASE_URL}orders/invoice_pdf/${id}/`
export const ORDER_INVOICE_XML_URL = (id: string) =>
  `${BASE_URL}orders/invoice_xml/${id}/`

export const ORDER_ITEM_URL = (uuid: string) =>
  `${BASE_ADMIN}orders/items/${uuid}/`

export const ACCOUNT_LEVEL_QUESTION_URL = `${BASE_URL}onboarding/questions/`
export const ONBOARDING_FILES_URL = `${BASE_URL}base/upload_file/`

export const SEARCH_ENGINE_URL = `${BASE_URL}base/search/`

export const SUPPLIERS_URL = `${BASE_ADMIN}supplier/`
export const SUPPLIER_URL = (uuid: string) => `${BASE_ADMIN}supplier/${uuid}/`
export const SUPPLIER_PRODUCTS_URL = `${BASE_ADMIN}supplier/products/`
export const SUPPLIER_PRODUCT_URL = (uuid: string) =>
  `${BASE_ADMIN}supplier/products/${uuid}/`
export const SUPPLIER_WAREHOUSES_URL = `${BASE_ADMIN}supplier/warehouse/`
export const SUPPLIER_WAREHOUSE_URL = (uuid: string) =>
  `${BASE_ADMIN}supplier/warehouse/${uuid}/`
export const TRANSPORT_COMPANY_URL = `${BASE_ADMIN}logistic/transport-companies/`
export const TRANSPORT_COMPANY_ROUTES_URL = `${BASE_ADMIN}logistic/transport-company-routes/`
export const LOGISTIC_ROUTES_URL = `${BASE_ADMIN}logistic/routes/`
export const LOGISTIC_VEHICLES_URL = `${BASE_ADMIN}logistic/vehicles/`

export const ORDER_TRACKER_URL = `${BASE_ADMIN}analytics/orders/`
export const ORDER_TRACKER_REGENERATE_URL = `${BASE_ADMIN}analytics/orders/generate_data/`

export const KAI_PRODUCTS_URL = `${KAI_BASE_URL}utils/product/`
export const KAI_PRODUCT_URL = (uuid: string) =>
  `${KAI_BASE_URL}utils/product/${uuid}/`
export const KAI_SMART_SEARCHS_URL = `${KAI_BASE_URL}searchengine/`
export const KAI_SMART_SEARCH_URL = (uuid: string) =>
  `${KAI_BASE_URL}searchengine/${uuid}/`

export const KAI_PRODUCTS_BY_REFERENCE_URL = `${KAI_BASE_URL}utils/product/enrichment-processor/queue/`
export const KAI_PRODUCTS_BY_TEXT_URL = `${KAI_BASE_URL}utils/product/raw-information-processor/queue/`
export const KAI_PRODUCTS_BY_FILE_URL = `${KAI_BASE_URL}utils/product/file-processor/queue/`

export const DOLLAR_EXCHANGE_RATE_URL = `${BASE_URL}base/dollar-exchange-rate/`
export const DOLLAR_QUOTATION_URL = `${BASE_URL}base/dollar-exchange-rate/latest/`

export const ORDERS_CENTER_PROFILE_URL = `${BASE_ADMIN}sale/buyers/`
export const ORDERS_CENTER_FINANCE_URL = `${BASE_ADMIN}sale/finances/`
export const ORDERS_CENTER_FINANCE_CLASSIFICATION_URL = `${BASE_ADMIN}finance/get_classification/`
export const ORDERS_CENTER_LOGISTIC_URL = `${BASE_ADMIN}sale/logistics/`
export const ORDERS_CENTER_LOGISTIC_CLASSIFICATION_URL = `${BASE_ADMIN}logistic/shipments/get_classification/`
export const ORDERS_CENTER_SALE_ORDERS_URL = `${BASE_ADMIN}sale/orders/`
export const ORDERS_CENTER_SALE_ORDER_URL = (uuid: string) =>
  `${BASE_ADMIN}sale/orders/${uuid}/`

export const ORDERS_CENTER_INVOCE_URL = (uuid: string) =>
  `${BASE_ADMIN}finance/invoices/${uuid}/`
export const ORDERS_CENTER_PAYMENT_URL = (uuid: string) =>
  `${BASE_ADMIN}finance/payments/${uuid}/`
export const ORDER_CENTER_SHIPMENT_URL = (uuid: string) =>
  `${BASE_ADMIN}logistic/shipments/${uuid}/`

export const CREDIT_ORDERS_URL = `${BASE_ADMIN}credit/orders/`
export const CREDIT_ORDER_URL = (uuid: string) =>
  `${BASE_ADMIN}credit/orders/${uuid}/`

export const TRANSACTIONS_URL = `${BASE_ADMIN}finance/transactions/`
export const TRANSACTION_URL = (uuid: string) =>
  `${BASE_ADMIN}finance/transactions/${uuid}/`

export const SALE_BOARD_METRICS_URL = `${BASE_ADMIN}analytics/sale_board/metrics/`
export const SALE_BOARD_NOTICATIONS_URL = `${BASE_URL}base/notifications/`
export const SALE_BOARD_NOTIFICATION_URL = (uuid: string) =>
  `${BASE_URL}base/notifications/${uuid}/`
export const SALE_BOARD_BEST_SELLERS_URL = `${BASE_ADMIN}analytics/best-seller-products/`

export const WORDPRESS_BASE_URL = `https://mercai.blog/`
export const WORDPRESS_POSTS_URL = `${WORDPRESS_BASE_URL}wp-json/wp/v2/posts?per_page=1&orderby=date&order=desc`
export const CALCULATE_MARGIN_URL = `${BASE_URL}base/calculate-margin/`
export const CALCULATE_UNIT_PRICE_URL = `${BASE_URL}base/calculate-unit-price/`
export const ADDRESS_URL = `${BASE_URL}base/address/`

export const AI_AGENTS_URL = `${AI_BASE_URL}agents/`
export const AI_AGENT_URL = (uuid?: string) => `${AI_BASE_URL}agents/${uuid}`

export const AI_CONVERSATIONS_URL = `${AI_BASE_URL}conversations/`
export const AI_CONVERSATION_URL = (uuid?: string) =>
  `${AI_BASE_URL}conversations/${uuid}`
export const AI_CONVERSATION_EMAIL_URL = (email?: string) =>
  `${AI_BASE_URL}conversations/email/${email}`
export const AI_MESSAGES_URL = `${AI_BASE_URL}messages`
export const AI_MESSAGE_URL = (uuid?: string) =>
  `${AI_BASE_URL}messages/${uuid}`
