import { QuotationProps } from '@/types'
import styles from './list.module.css'

const calculateDateDifference = (date1: Date, date2: Date): number => {
  const day1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const day2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())

  const time1 = day1.getTime()
  const time2 = day2.getTime()

  const differenceInMilliseconds = Math.abs(time1 - time2)

  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24)

  return differenceInDays
}

export enum ReferenceColumnStatusEnum {
  'default' = 'default',
  'success' = 'success',
  'warning' = 'warning',
  'danger' = 'danger',
}

export const getReferenceColumnStatus = (record: QuotationProps) => {
  const now = new Date()
  const createdAt = new Date(record?.createdAt)

  if (
    record?.status === 'pending' &&
    calculateDateDifference(createdAt, now) > 5
  ) {
    return ReferenceColumnStatusEnum.danger
  }

  if (
    record?.status === 'pending' &&
    calculateDateDifference(createdAt, now) > 3
  ) {
    return ReferenceColumnStatusEnum.warning
  }

  if (
    record?.status === 'pending' &&
    calculateDateDifference(createdAt, now) === 0
  ) {
    return ReferenceColumnStatusEnum.success
  }

  return ReferenceColumnStatusEnum.default
}

export const generateOrderingButtonClasses = (isActive: boolean) => {
  return `${styles['ordering-button']} ${isActive ? styles['ordering-button-active'] : ''}`
}
