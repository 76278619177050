import { Accordion as AccordionLib, Tag, Text } from '@mercai/clever'

import styles from './accordion.module.css'

import { AccordionProps } from './accordion.type'
import { useTranslation } from 'react-i18next'

export const Accordion = ({
  children,
  title,
  activeItems,
  setActiveItems,
  id,
  isComplete,
}: AccordionProps) => {
  const { t } = useTranslation('screenBusinessV2')

  return (
    <AccordionLib.Root
      activeItems={activeItems}
      onHandleActiveItems={setActiveItems}
    >
      <AccordionLib.Item
        id={id}
        className={`${styles['header-container']} ${activeItems.length === 0 ? styles.border : ''}`}
        header={
          <div className={styles.header}>
            <Text variant="title-medium" className={styles.title}>
              {title}
            </Text>
            {isComplete !== undefined ? (
              !isComplete ? (
                <Tag variant="warning" model="light">
                  {t('sectionIncompleteLabel')}
                </Tag>
              ) : (
                <Tag variant="success" model="light">
                  {t('sectionCompleteLabel')}
                </Tag>
              )
            ) : (
              <></>
            )}
          </div>
        }
      >
        <section className={styles['general-info']}>{children}</section>
      </AccordionLib.Item>
    </AccordionLib.Root>
  )
}
