import { Button, DatePicker, Label, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { SaleOrderProps } from '@/types'

import type { OrderDateFormProps } from './order-date-form.type'

import styles from './order-date-form.module.css'

export const OrderDateForm = ({
  onChangeValues,
  values,
  onBack,
  onHandleSubmit,
}: OrderDateFormProps) => {
  const { t } = useTranslation('componentsOrderDateForm')
  const { t: tEnums } = useTranslation('enums')

  const onHandleValuesArray = (
    objectKey: keyof SaleOrderProps,
    objectIndex: number,
    attributeKey: keyof SaleOrderProps[keyof SaleOrderProps],
    value?: string | number | boolean,
  ) => {
    const originalArray = Array.isArray(values?.[objectKey])
      ? values?.[objectKey]
      : []

    const updatedArray = [...(originalArray as any[])] // eslint-disable-line

    updatedArray[objectIndex] = {
      ...updatedArray[objectIndex],
      [attributeKey]: value,
    }

    onChangeValues({ ...values, [objectKey]: updatedArray })
  }

  return (
    <>
      <div className={styles.core}>
        <div className={styles['card-backoffice']}>
          <Text
            className={styles['card-backoffice-title']}
            variant="title-medium"
          >
            {t('orderFormInvoiceLabel')}
          </Text>

          {values?.invoices?.map((invoice, index) => (
            <div key={invoice.uuid}>
              <Label>
                {t('orderFormInvoiceAtLabel')} <span>{t('optionalLabel')}</span>
              </Label>
              <DatePicker
                placeholder={t('orderFormInvoiceAtPlaceholder')}
                value={invoice.invoicedAt}
                onChange={(newValue) =>
                  onHandleValuesArray(
                    'invoices',
                    index,
                    'invoicedAt' as keyof SaleOrderProps[keyof SaleOrderProps],
                    newValue,
                  )
                }
              />
            </div>
          ))}
        </div>

        <div className={styles['card-backoffice']}>
          <Text
            className={styles['card-backoffice-title']}
            variant="title-medium"
          >
            {t('orderFormPaymentLabel')}
          </Text>

          {values?.payments?.map((payment, index) => {
            const showParcelLabel = ['partial'].includes(payment?.form)

            const parcelLabel = `(${payment?.instalment}/${values?.payments?.length})`

            return (
              <div key={payment.uuid}>
                <Label>
                  {t('orderFormPaidAtLabel')} -{' '}
                  {tEnums(`finance_payment_form_${payment?.form}`)}{' '}
                  {showParcelLabel && parcelLabel}
                  <span>{t('optionalLabel')}</span>
                </Label>

                <DatePicker
                  placeholder={t('orderFormPaidAtPlaceholder')}
                  value={payment?.paidAt}
                  onChange={(newValue) =>
                    onHandleValuesArray(
                      'payments',
                      index,
                      'paidAt' as keyof SaleOrderProps[keyof SaleOrderProps],
                      newValue,
                    )
                  }
                />
              </div>
            )
          })}
        </div>

        <div className={styles['card-backoffice']}>
          <Text
            className={styles['card-backoffice-title']}
            variant="title-medium"
          >
            {t('orderFormShipmentLabel')}
          </Text>

          {values?.shipments?.map((shipment, index) => (
            <div
              key={shipment.uuid}
              className={styles['card-backoffice-content']}
            >
              <div>
                <Label>
                  {t('orderFormExpectedDeliveryAtLabel')}
                  <span>{t('optionalLabel')}</span>
                </Label>
                <DatePicker
                  placeholder={t('orderFormExpectedDeliveryAtPlaceholder')}
                  value={shipment?.expectedDeliveryAt}
                  onChange={(newValue) =>
                    onHandleValuesArray(
                      'shipments',
                      index,
                      'expectedDeliveryAt' as keyof SaleOrderProps[keyof SaleOrderProps],
                      newValue,
                    )
                  }
                />
              </div>

              <div>
                <Label>
                  {t('orderFormDeliveredAtLabel')}
                  <span>{t('optionalLabel')}</span>
                </Label>
                <DatePicker
                  placeholder={t('orderFormDeliveredAtPlaceholder')}
                  value={shipment?.deliveredAt}
                  onChange={(newValue) =>
                    onHandleValuesArray(
                      'shipments',
                      index,
                      'deliveredAt' as keyof SaleOrderProps[keyof SaleOrderProps],
                      newValue,
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={onBack}>
          {t('buttonBack')}
        </Button>

        <Button onClick={onHandleSubmit}>{t('buttonSubmit')}</Button>
      </div>
    </>
  )
}
