import { ReactNode, useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Button,
  Checkbox,
  DropDown,
  FilterIcon,
  InputSearch,
  TableColumns,
  Tag,
  Tooltip,
  Text,
  Skeleton,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  EyeIcon,
  Select,
  TrashIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE, appOrderLink } from '@/constants'
import {
  formatDateWithTime,
  trimString,
  useParamsState,
  useCookieState,
  formatCurrency,
  onConfirmDangerMessage,
  onSuccessMessage,
  onErrorMessage,
  getOrderItemDescription,
} from '@/helpers'
import { useOrderList, useDeleteOrder } from '@/services'
import { ROUTES_ADMIN } from '@/router'
import { useAuth } from '@/providers'
import { OrderDetailProps } from '@/types'

import {
  OrderDrawerFilter,
  OrderDrawerShow,
  OrderDrawerForm,
  ProfileDrawerForm,
} from './components'

import { TagMoscow } from '../../../profile/components'

import type { OrderListsProps } from './list.type'

import styles from './list.module.css'

export const OrderList = ({ buyerProfile }: OrderListsProps) => {
  const { t } = useTranslation('screenOrderList')

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [totalPages, setTotalPages] = useState(1)
  const [isOpenProfileDrawerForm, setIsOpenProfileDrawerForm] = useState(false)
  const [orderProfileSelected, setOrderProfileSelected] =
    useState<OrderDetailProps | null>(null)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/order-list-columns',
    [
      'reference',
      'buyerProfile.name',
      'buyerProfile.companyName',
      'buyerProfile.email',
      'buyerProfile.phone',
      'status',
      'buyerTaxStatus',
      'buyerProfile.moscowClassification',
      'totalPrice',
      'products',
      'creatorEmail',
      'expiresAt',
      'createdAt',
      'buyerProfile.keyAccountManager.email',
      'quotationRequest',
      'uuid',
    ],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) => !!filter[key] && key !== 'page' && key !== 'limit',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const { data, isLoading, refetch } = useOrderList(
    buyerProfile ? { ...filter, buyerProfile } : filter,
  )

  const { isPending, mutateAsync: mutateDelete } = useDeleteOrder()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('orderTableDeleteConfirmTitle'),
      t('orderTableDeleteConfirmMessage'),
      t('orderTableDeleteConfirmButtonDelete'),
      t('orderTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('orderTableDeleteSuccessTitle'),
          t('orderTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('orderTableDeleteErrorTitle'),
          t('orderTableDeleteErrorMessage'),
        )
      }
    }
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(
        Math.ceil(data?.data?.count / +(filter?.limit || DEFAULT_LIMIT_PAGE)),
      )
    }
  }, [data])

  const onHandleShowRegister = (uuid: string) => {
    setQueryParams({ show: 'true', uuid, form: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ form: 'true', uuid, show: '' })
  }

  const onHandleOpenProfile = (orderParam: OrderDetailProps) => {
    setIsOpenProfileDrawerForm(true)
    setOrderProfileSelected(orderParam)
  }

  const stateObject: Record<string, ReactNode> = {
    pending_buyer_data: (
      <Tag variant="warning" model="light">
        {t('orderTableStatusPendingBuyerData')}
      </Tag>
    ),
    pending_invoice: (
      <Tag variant="warning" model="light">
        {t('orderTableStatusPendingInvoice')}
      </Tag>
    ),
    pending_payment: (
      <Tag variant="warning" model="light">
        {t('orderTableStatusPendingPayment')}
      </Tag>
    ),
    waiting_payment_approval: (
      <Tag variant="warning" model="light">
        {t('orderTableStatusWaitingPaymentApproval')}
      </Tag>
    ),
    paid: (
      <Tag variant="primary" model="light">
        {t('orderTableStatusPaid')}
      </Tag>
    ),
    partial_delivered: (
      <Tag variant="primary" model="light">
        {t('orderTableStatusPartialDelivered')}
      </Tag>
    ),
    delivered: (
      <Tag variant="success" model="light">
        {t('orderTableStatusDelivered')}
      </Tag>
    ),
    canceled: (
      <Tag variant="danger" model="light">
        {t('orderTableStatusCanceled')}
      </Tag>
    ),
    fraudulent: (
      <Tag variant="danger" model="light">
        {t('orderTableStatusFraudulent')}
      </Tag>
    ),
  }

  const getTaxStateContent = (orderParam: OrderDetailProps): ReactNode => {
    const object: Record<string, ReactNode> = {
      pending: (
        <Tag variant="warning" model="light">
          {t('orderTableTaxStatusPending')}
        </Tag>
      ),
      review: (
        <Tag variant="danger" model="light">
          {t('orderTableTaxStatusReview')}
        </Tag>
      ),
      completed: (
        <Tag variant="success" model="light">
          {t('orderTableTaxStatusCompleted')}
        </Tag>
      ),
      invalid: (
        <Tag variant="basic" model="light">
          {t('orderTableTaxStatusInvalid')}
        </Tag>
      ),
    }

    return (
      <div className={styles['tax-status-column']}>
        {object[orderParam.buyerTaxStatus as string]}

        <Button
          variant="ghost"
          size="small"
          onClick={() => onHandleOpenProfile(orderParam)}
        >
          <EyeIcon />
        </Button>
      </div>
    )
  }

  const getContentProduct = useCallback(
    (items: OrderDetailProps['items'], index: number) => {
      const concatItems = items
        ?.map((item) => getOrderItemDescription(item))
        .join(', ')

      return (
        <Tooltip
          text={concatItems}
          widthText="18.75rem"
          orientation={index >= 8 ? 'top' : 'bottom'}
        >
          {trimString(concatItems, 52, '...')}
        </Tooltip>
      )
    },
    [data],
  )

  const columns: TableColumnsColumnProps<OrderDetailProps>[] = [
    {
      title: t('orderTableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9rem',
      render: ({ value }) => `#${value}`,
    },
    {
      title: t('orderTableBuyerName'),
      key: 'buyerProfile.usersNames',
      dataKey: 'buyerProfile.usersNames',
      render: ({ record }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {record?.buyerProfile?.usersNames
            ? record?.buyerProfile?.usersNames?.join(', ')
            : '-'}
        </Link>
      ),
    },
    {
      title: t('orderTableBusinessName'),
      key: 'buyerProfile.companyName',
      dataKey: 'buyerProfile.companyName',
      width: '18rem',
      render: ({ record, value }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {value || '-'}
        </Link>
      ),
    },
    {
      title: t('orderTableEmail'),
      key: 'buyerProfile.email',
      dataKey: 'buyerProfile.email',
      width: '22rem',
      render: ({ record, value }) => (
        <Link
          target="_blank"
          to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(record?.buyerProfile?.uuid)}
          className={styles.link}
        >
          {value || '-'}
        </Link>
      ),
    },
    {
      title: t('orderTablePhone'),
      key: 'buyerProfile.phone',
      dataKey: 'buyerProfile.phone',
    },
    {
      title: t('orderTableState'),
      key: 'status',
      dataKey: 'status',
      width: '15rem',
      render: ({ record }) =>
        stateObject[(record?.status || 'pending_buyer_data') as string] || '-',
    },
    {
      title: t('orderTableTaxState'),
      key: 'buyerTaxStatus',
      dataKey: 'buyerTaxStatus',
      render: ({ record }) => getTaxStateContent(record),
    },
    {
      title: t('orderTableMoscow'),
      key: 'buyerProfile.moscowClassification',
      dataKey: 'buyerProfile.moscowClassification',
      render: ({ record }) => (
        <TagMoscow
          moscowClassification={record?.buyerProfile?.moscowClassification}
        />
      ),
    },
    {
      title: t('orderTableAmount'),
      key: 'totalPrice',
      dataKey: 'totalPrice',
      render: ({ value }) =>
        value ? formatCurrency(value, 'es-MX', 'USD') : '-',
    },
    {
      title: t('orderTableProducts'),
      key: 'items',
      dataKey: 'items',
      width: '20rem',
      render: ({ record, index }) => getContentProduct(record.items, index),
    },
    {
      title: t('orderTableCreatedBy'),
      key: 'creatorEmail',
      dataKey: 'creatorEmail',
    },
    {
      title: t('orderTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDateWithTime(value) : '-'),
    },
    {
      title: t('orderTableSellerName'),
      key: 'buyerProfile.keyAccountManager.email',
      dataKey: 'buyerProfile.keyAccountManager.email',
      width: '18.75rem',
    },
    {
      title: t('orderTableQuotation'),
      key: 'quotation',
      dataKey: 'quotation',
      width: '8rem',
      render: ({ record }) =>
        record?.quotation ? (
          <Link
            to={ROUTES_ADMIN.BUSINESS.QUOTATION_OLD.SHOW.fullPath(
              record?.quotation,
            )}
            target="_blank"
            className={styles.link}
          >
            {t('orderTableQuotationLink')}
          </Link>
        ) : (
          <Text>-</Text>
        ),
    },
    {
      title: t('orderTableAppLink'),
      key: 'uuid',
      dataKey: 'uuid',
      render: ({ value }) => (
        <Link
          to={appOrderLink(value || '')}
          target="_blank"
          className={styles.link}
        >
          {t('orderTableAppLink')}
        </Link>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('orderTableFilterButton')}
            {getContentTotalFiltered()}
          </Button>
        </div>

        <div className={styles['filter-group']}>
          <DropDown.Root>
            <DropDown.ButtonTrigger
              variant="secondary"
              model="square"
              hasArrow={false}
            >
              <SettingIcon />
            </DropDown.ButtonTrigger>

            <DropDown.Content orientation="bottom-right">
              {columns?.map((column) => (
                <DropDown.Item key={column.key}>
                  <Checkbox
                    onChange={() => onHandleColumnsShow(column.key)}
                    value={getColumnsShow(column.key)}
                  >
                    {column.title}
                  </Checkbox>
                </DropDown.Item>
              ))}
            </DropDown.Content>
          </DropDown.Root>
        </div>
      </div>

      <TableColumns<OrderDetailProps>
        isLoading={isLoading || isPending}
        columns={memoizedColumns}
        fixedColumns={[
          {
            title: t('orderTableActions'),
            key: 'uuid',
            dataKey: 'uuid',
            render: ({ record }) => (
              <div className={styles['action-buttons']}>
                <Button
                  variant="secondary"
                  onClick={() => onHandleEditRegister(record.uuid)}
                >
                  {t('orderTableActionsEdit')}
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => onHandleShowRegister(record.uuid)}
                >
                  {t('orderTableActionsView')}
                </Button>
                {isSuperUser && (
                  <Button
                    model="square"
                    variant="danger"
                    onClick={() => onHandleDelete(record.uuid)}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>
            ),
            renderLoading: (
              <div className={styles['action-buttons']}>
                <Skeleton height="2rem" width="5.15rem" />
                <Skeleton height="2rem" width="5.7rem" />
                {isSuperUser && <Skeleton height="2rem" width="2rem" />}
              </div>
            ),
          },
        ]}
        data={data?.data?.results || []}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('orderTableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', page)}
          currentPage={+filter.page as number}
        />
      </div>

      <OrderDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <OrderDrawerShow
        isOpen={!!queryParams.show}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, show: '', uuid: '' })
        }
        orderUuid={queryParams.uuid}
      />

      <OrderDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        orderUuid={queryParams.uuid}
      />

      <ProfileDrawerForm
        isOpen={isOpenProfileDrawerForm}
        onHandleOpen={() => setIsOpenProfileDrawerForm(false)}
        email={orderProfileSelected?.buyerProfile?.email || ''}
        taxStatus={orderProfileSelected?.buyerTaxStatus || ''}
        taxSituation={orderProfileSelected?.buyerTaxSituation || ''}
        id={orderProfileSelected?.buyer || ''}
      />
    </div>
  )
}
