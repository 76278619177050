import {
  Tag,
  Text,
  Skeleton,
  Button,
  MonetizationIcon,
  ProfileIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber } from '@/helpers'

import styles from './summary-products.module.css'

import { QuotationSummaryProductsProps } from './summary-products.type'
import { useMemo, useState } from 'react'
import { ProductCard } from '@/pages/business/components/product-card'
import { CardItem } from '../card-item/card-item'
import { ROUTES_ADMIN } from '@/router'

const QuotationSummaryProductsDefault = ({
  products,
  dollarQuotation,
}: QuotationSummaryProductsProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2Show')

  const [selected, setSelected] = useState(0)

  const productSelected = useMemo(
    () => products?.[selected],
    [selected, products],
  )

  return (
    <div>
      <div className={styles['button-container']}>
        {products?.map((product, i) => {
          return (
            <Button
              key={`${product?.uuid}-${i}`}
              model="pill"
              variant="primary-outline"
              className={`${styles.button} ${selected === i ? styles['button-selected'] : ''}`}
              onClick={() => setSelected(i)}
            >
              {`${t('quotationShowSummaryProductButton')} ${i + 1}`}
            </Button>
          )
        })}
      </div>

      <div className={styles.content}>
        <div>
          <ProductCard
            actions={null}
            img={
              products?.[selected]?.catalogProduct?.attributes
                ?.mainImage as string
            }
            title={
              products?.[selected]?.catalogProduct?.attributes?.title ||
              t('quotationShowSummaryEmpty')
            }
            category={
              products?.[selected]?.catalogProduct?.category?.name ||
              t('quotationShowSummaryEmpty')
            }
            density={
              products?.[selected]?.catalogProduct?.attributes?.density ||
              t('quotationShowSummaryEmpty')
            }
            fluidity={
              products?.[selected]?.catalogProduct?.attributes?.fluidity ||
              t('quotationShowSummaryEmpty')
            }
            quantity={products?.[selected]?.quantity}
          />
        </div>

        <div className={styles['items-container']}>
          <CardItem
            title={t('quotationShowSummaryProductCostTitle')}
            icon={<MonetizationIcon />}
          >
            <div className={styles.table}>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductQuantity')}:
                </Text>
                <Text variant="text-base">
                  {formatNumber(productSelected?.quantity)}
                </Text>

                <Tag
                  size="small"
                  variant="secondary"
                  className={styles['table-item-packaging']}
                >
                  {tEnums(`order_itens_package_${productSelected?.package}`)}
                </Tag>
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductUnitPrice')}:
                </Text>
                <Text variant="text-base">
                  {formatCurrency(
                    +productSelected?.price * +dollarQuotation,
                    'es-MX',
                    'MXN',
                    3,
                  )}{' '}
                  ({formatCurrency(productSelected?.price, 'es-MX', 'USD', 3)})
                </Text>
              </div>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductTotalPrice')}:
                </Text>
                <Text variant="text-base">
                  {formatCurrency(
                    +productSelected?.quantity *
                      +productSelected?.price *
                      +dollarQuotation,
                    'es-MX',
                    'MXN',
                  )}{' '}
                  (
                  {formatCurrency(
                    +productSelected?.quantity * +productSelected?.price,
                    'es-MX',
                    'USD',
                  )}
                  )
                </Text>
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductUnitCost')}:
                </Text>
                {productSelected?.cost ? (
                  <Text variant="text-base">
                    {formatCurrency(
                      +productSelected?.cost * +dollarQuotation,
                      'es-MX',
                      'MXN',
                      3,
                    )}{' '}
                    ({formatCurrency(productSelected?.cost, 'es-MX', 'USD', 3)})
                  </Text>
                ) : (
                  <Text variant="text-base">
                    {t('quotationShowSummaryProductNoData')}
                  </Text>
                )}
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductTotalCost')}:
                </Text>
                {productSelected?.cost ? (
                  <Text variant="text-base">
                    {formatCurrency(
                      +productSelected?.quantity *
                        +productSelected?.cost *
                        +dollarQuotation,
                      'es-MX',
                      'MXN',
                    )}{' '}
                    (
                    {formatCurrency(
                      +productSelected?.quantity * +productSelected?.cost,
                      'es-MX',
                      'USD',
                    )}
                    )
                  </Text>
                ) : (
                  <Text variant="text-base">
                    {t('quotationShowSummaryProductNoData')}
                  </Text>
                )}
              </div>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryTraderComission')}:
                </Text>
                <Text variant="text-base">
                  {formatCurrency(
                    +productSelected?.traderCommission * +dollarQuotation,
                    'es-MX',
                    'MXN',
                  )}{' '}
                  (
                  {formatCurrency(
                    +productSelected?.traderCommission,
                    'es-MX',
                    'USD',
                  )}
                  )
                </Text>
              </div>
            </div>
          </CardItem>

          <CardItem
            title={t('quotationShowSummaryProductSupplierTitle')}
            icon={<ProfileIcon />}
            actions={
              <Button
                variant="primary-outline"
                model="square"
                target="_blank"
                href={ROUTES_ADMIN.SUPPLY.SUPPLIERS.SHOW.fullPath(
                  productSelected?.supplier?.uuid || '',
                )}
              >
                {t(`quotationShowSummaryShowButton`)}
              </Button>
            }
          >
            <div className={styles.table}>
              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductSupplierCompanyName')}:
                </Text>
                <Text variant="text-base">
                  {productSelected?.supplier?.name ||
                    t('quotationShowSummaryEmpty')}
                </Text>
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductSupplierEmail')}:
                </Text>
                <Text variant="text-base">
                  {productSelected?.supplier?.email ||
                    t('quotationShowSummaryEmpty')}
                </Text>
              </div>

              <div className={styles['table-item-row']}>
                <Text variant="title-base">
                  {t('quotationShowSummaryProductSupplierPhone')}:
                </Text>
                <Text variant="text-base">
                  {productSelected?.supplier?.phone ||
                    t('quotationShowSummaryEmpty')}
                </Text>
              </div>
            </div>
          </CardItem>
        </div>
      </div>
    </div>
  )
}

const QuotationSummaryProductsLoading = () => {
  const { t } = useTranslation('screenQuotationV2Show')
  return (
    <div>
      <div className={styles['button-container']}>
        <Skeleton height="2rem" width="8rem" />
        <Skeleton height="2rem" width="8rem" />
        <Skeleton height="2rem" width="8rem" />
      </div>
      <div className={styles.content}>
        <div className={styles['product-container-skeleton']}>
          <div>
            <Skeleton height="6rem" width="6rem" />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '0.65rem',
            }}
          >
            <Skeleton height="1rem" width="40%" />
            <Skeleton height="1rem" width="30%" />
            <Skeleton height="1rem" width="20%" />
            <Skeleton height="1rem" width="30%" />
          </div>
        </div>
        <div className={styles['items-container']}>
          <CardItem
            title={t('quotationShowSummaryProductCostTitle')}
            icon={<MonetizationIcon />}
          >
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="50%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="40%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="50%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="60%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="40%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="35%" />
            </div>
          </CardItem>
          <CardItem
            title={t('quotationShowSummaryProductSupplierTitle')}
            icon={<ProfileIcon />}
            actions={<Skeleton height="2rem" width="2rem" />}
          >
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="50%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="40%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="50%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="60%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="40%" />
            </div>
            <div className={styles['table-item-row']}>
              <Skeleton height="1rem" width="35%" />
            </div>
          </CardItem>
        </div>
      </div>
    </div>
  )
}

export const QuotationSummaryProducts = (
  props: QuotationSummaryProductsProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryProductsLoading />
  }

  return <QuotationSummaryProductsDefault {...props} />
}
