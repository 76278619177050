import { useMutation, useQuery } from '@tanstack/react-query'
import { convertObjectToCamelCase, convertObjectToSnakeCase } from '@/helpers'

import {
  SupplierProps,
  SupplierListWithPagesProps,
  SupplierWarehouseListWithPagesProps,
  SupplierWarehouseProps,
} from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { SUPPLIER_WAREHOUSES_URL, SUPPLIERS_URL } from './api.urls'

export const useSupplierList = ({
  params,
  isEnable = true,
}: {
  params?: Record<string, string | number>
  isEnable?: boolean
}) => {
  const getSupplierList = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SUPPLIERS_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Suppliers fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get suppliers!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER.module, params],
    enabled: isEnable,
    queryFn: () => getSupplierList(),
  })
}

export const useSupplierDetail = (uuid?: string) => {
  const getSupplierDetail = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierProps
  }> => {
    try {
      const { data } = await api.get(`${SUPPLIERS_URL}${uuid}/`)

      return {
        success: true,
        message: 'Supplier fetched successfully!',
        data: convertObjectToCamelCase(data) as SupplierProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER.module, uuid],
    queryFn: () => getSupplierDetail(),
    enabled: !!uuid,
  })
}

export const useCreateSupplier = () => {
  const createSupplier = async (
    payload: Partial<SupplierProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierProps
  }> => {
    try {
      const { data } = await api.post(
        SUPPLIERS_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Supplier created successfully!',
        data: convertObjectToCamelCase(data) as SupplierProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create supplier!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER.module],
    mutationFn: (payload: Partial<SupplierProps>) => createSupplier(payload),
  })
}

export const useUpdateSupplier = () => {
  const updateSupplier = async (
    payload: Partial<SupplierProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierProps
  }> => {
    try {
      const { data } = await api.patch(
        `${SUPPLIERS_URL}${payload.uuid}/`,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Supplier updated successfully!',
        data: convertObjectToCamelCase(data) as SupplierProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update supplier!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER.module],
    mutationFn: (payload: Partial<SupplierProps>) => updateSupplier(payload),
  })
}

export const useSupplierWarehouseList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getSupplierWarehouseList = async (): Promise<{
    success: boolean
    message: string
    data?: SupplierWarehouseListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(SUPPLIER_WAREHOUSES_URL, {
        params: {
          ...(convertObjectToSnakeCase(formattedParams) as Record<
            string,
            string
          >),
          offset,
        },
      })

      return {
        success: true,
        message: 'Supplier warehouses fetched successfully!',
        data: convertObjectToCamelCase(
          data,
        ) as SupplierWarehouseListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier warehouses!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.SUPPLIER_WAREHOUSE.module, params],
    queryFn: () => getSupplierWarehouseList(),
  })
}

export const useCreateSupplierWarehouse = () => {
  const createSupplierWarehouse = async (
    payload: Partial<SupplierWarehouseProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: SupplierWarehouseProps
  }> => {
    try {
      const { data } = await api.post(
        SUPPLIER_WAREHOUSES_URL,
        convertObjectToSnakeCase(payload),
      )

      return {
        success: true,
        message: 'Supplier Warehouse created successfully!',
        data: convertObjectToCamelCase(data) as SupplierWarehouseProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create supplier warehouse!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.SUPPLIER_WAREHOUSE.module],
    mutationFn: (payload: Partial<SupplierWarehouseProps>) =>
      createSupplierWarehouse(payload),
  })
}
