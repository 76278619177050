import { SVGProps } from 'react'

export const CheckCircleOutilineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.99967 1.33398C4.31967 1.33398 1.33301 4.32065 1.33301 8.00065C1.33301 11.6807 4.31967 14.6673 7.99967 14.6673C11.6797 14.6673 14.6663 11.6807 14.6663 8.00065C14.6663 4.32065 11.6797 1.33398 7.99967 1.33398ZM7.99967 13.334C5.05967 13.334 2.66634 10.9407 2.66634 8.00065C2.66634 5.06065 5.05967 2.66732 7.99967 2.66732C10.9397 2.66732 13.333 5.06065 13.333 8.00065C13.333 10.9407 10.9397 13.334 7.99967 13.334ZM11.0597 5.05398L6.66634 9.44732L4.93967 7.72732L3.99967 8.66732L6.66634 11.334L11.9997 6.00065L11.0597 5.05398Z" />
    </svg>
  )
}
