import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useQuotationRequestDetail } from '@/services'
import { ROUTES_ADMIN } from '@/router'

import {
  QuotationRequestShowStatusAlert,
  QuotationRequestListItems,
  QuotationRequestShowQuotationList,
  QuotationRequestBuyerData,
  QuotationRequestAddressData,
  InternalInformation,
} from './components'

import { QuotationDrawerForm } from '../../quotation/list/components'

import { Header } from '@/components'

import styles from './show.module.css'

export const QuotationRequestShow = () => {
  const { t } = useTranslation('screenQuotationRequestShow')

  const { uuid } = useParams()
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [isOpenQuotationForm, setIsOpenQuotationForm] = useState(false)

  const { data: quotationRequest, isLoading: isLoadingRequest } =
    useQuotationRequestDetail(uuid)

  const isLoading = isLoadingRequest || quotationRequest?.data === undefined

  const onHandleQuote = () => {
    setIsOpenQuotationForm(true)
  }

  return (
    <div>
      <Header
        title={t('headerTitle', {
          reference: quotationRequest?.data?.reference,
        })}
        description={t('headerSubtitle')}
        link={ROUTES_ADMIN.BUSINESS.RFQ_OLD.fullPath()}
      />

      <div className={styles.core}>
        <QuotationRequestShowStatusAlert
          status={quotationRequest?.data?.status}
          isLoading={isLoading}
        />

        <div className={styles.content}>
          <QuotationRequestListItems
            items={quotationRequest?.data?.items}
            isLoading={isLoading}
            onSelectItems={setSelectedItems}
            selectedItems={selectedItems}
            onClickQuote={onHandleQuote}
          />

          <div className={styles['column-right']}>
            <QuotationRequestShowQuotationList
              file={quotationRequest?.data?.file}
              quotations={quotationRequest?.data?.quotations}
              status={quotationRequest?.data?.status}
              isLoading={isLoading}
            />

            <QuotationRequestBuyerData
              companyName={quotationRequest?.data?.buyerProfile?.companyName}
              email={quotationRequest?.data?.buyerProfile?.email}
              name={
                quotationRequest?.data?.buyerProfile?.usersNames
                  ? quotationRequest?.data?.buyerProfile?.usersNames.join(', ')
                  : '-'
              }
              phone={quotationRequest?.data?.buyerProfile?.phone}
              isLoading={isLoading}
            />

            <QuotationRequestAddressData
              address={quotationRequest?.data?.address}
              isLoading={isLoading}
            />

            <InternalInformation
              refusedBy={quotationRequest?.data?.refusedBy}
              refusedReason={quotationRequest?.data?.refusedReason}
              isLoading={isLoading}
            />
          </div>
        </div>

        <QuotationDrawerForm
          isOpen={isOpenQuotationForm}
          onHandleOpen={setIsOpenQuotationForm}
          quotationRequestSelected={quotationRequest?.data}
          quotationRequestItemsSelected={selectedItems}
        />
      </div>
    </div>
  )
}
