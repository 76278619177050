import { QuotationRequestItemProps } from '@/types'
import styles from './update-rfq.module.css'

export const generateRightContentClasses = (isActive: boolean) => {
  return `${styles['right-content']} ${isActive ? styles['right-content-active'] : ''}`
}

export const checkHasChange = (
  array1: QuotationRequestItemProps[],
  array2: QuotationRequestItemProps[],
) => {
  if (array1.length !== array2.length) {
    return true
  }

  const sortedArray1 = [...array1].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b)),
  )
  const sortedArray2 = [...array2].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b)),
  )

  for (let i = 0; i < sortedArray1.length; i++) {
    if (JSON.stringify(sortedArray1[i]) !== JSON.stringify(sortedArray2[i])) {
      return true
    }
  }

  return false
}
