import { useRoutes, Navigate } from 'react-router-dom'

import { useAuth } from '@/providers'

import { AdminPanelLayout, AuthLayout, BoardLayout } from '@/layouts'
import {
  AnalyticsDashboard,
  AuthSignIn,
  ProductList,
  QuotationRequestShow,
  BusinessList,
  ProfileList,
  ProfileShow,
  SupplySupplierList,
  AnalyticsOrderTracker,
  AnalyticsTotalEconomics,
  CatalogProcessorList,
  OrderCenterPage,
  SupplyTransportCompaniesList,
  SupplyTransportCompaniesShow,
  SupplySupplierShow,
  ProductShow,
  QuotationRequestV2Show,
  QuotationRequestV2List,
  QuotationV2Show,
  QuotationV2Update,
  QuotationV2List,
  AiCenter,
} from '@/pages'

import { ROUTES_ADMIN, ROUTES_AUTH } from './router-routes'
import { SaleBoard } from '@/pages/board/sale-board'

const routes = (isSigned: boolean) => [
  {
    path: '/',
    element: <Navigate to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()} />,
  },
  {
    path: ROUTES_AUTH.path,
    element: <AuthLayout />,
    children: [
      {
        element: <AuthSignIn />,
        path: ROUTES_AUTH.SIGN_IN.path,
      },
    ],
  },
  {
    path: ROUTES_ADMIN.path,
    element: <BoardLayout />,
    children: [
      {
        path: ROUTES_ADMIN.BOARD.SALE.path,
        element: <SaleBoard />,
      },
    ],
  },
  {
    path: ROUTES_ADMIN.path,
    element: isSigned ? (
      <AdminPanelLayout />
    ) : (
      <Navigate to={ROUTES_AUTH.SIGN_IN.fullPath()} />
    ),
    children: [
      {
        element: <ProductList />,
        path: ROUTES_ADMIN.CATALOG.PRODUCT.path,
      },
      {
        element: <ProductShow />,
        path: ROUTES_ADMIN.CATALOG.PRODUCT.SHOW.path,
      },
      {
        element: <BusinessList />,
        path: ROUTES_ADMIN.BUSINESS.path,
      },
      {
        element: <BusinessList />,
        path: ROUTES_ADMIN.BUSINESS.RFQ_OLD.path,
      },
      {
        element: <QuotationRequestShow />,
        path: `${ROUTES_ADMIN.BUSINESS.RFQ_OLD.SHOW.path}`,
      },
      {
        element: <QuotationRequestV2List />,
        path: `${ROUTES_ADMIN.BUSINESS.RFQ.path}`,
      },
      {
        element: <QuotationRequestV2Show />,
        path: `${ROUTES_ADMIN.BUSINESS.RFQ.SHOW.path}`,
      },
      {
        element: <QuotationV2List />,
        path: `${ROUTES_ADMIN.BUSINESS.QUOTATION.path}`,
      },
      {
        element: <QuotationV2Show />,
        path: `${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.path}`,
      },
      {
        element: <QuotationV2Update />,
        path: `${ROUTES_ADMIN.BUSINESS.QUOTATION.UPDATE.path}`,
      },
      {
        element: <QuotationV2Show />,
        path: `${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.path}`,
      },
      {
        element: <ProfileList />,
        path: ROUTES_ADMIN.PROFILE.path,
      },
      {
        element: <ProfileShow />,
        path: `${ROUTES_ADMIN.PROFILE.path}/:uuid`,
      },
      {
        element: <SupplySupplierList />,
        path: `${ROUTES_ADMIN.SUPPLY.SUPPLIERS.path}`,
      },
      {
        element: <SupplySupplierShow />,
        path: `${ROUTES_ADMIN.SUPPLY.SUPPLIERS.SHOW.path}`,
      },
      {
        element: <SupplyTransportCompaniesList />,
        path: `${ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.path}`,
      },
      {
        element: <SupplyTransportCompaniesShow />,
        path: `${ROUTES_ADMIN.SUPPLY.TRANSPORT_COMPANIES.SHOW.path}`,
      },
      {
        element: <AnalyticsDashboard />,
        path: `${ROUTES_ADMIN.ANALYTICS.DASHBOARD.path}`,
      },
      {
        element: <AnalyticsOrderTracker />,
        path: `${ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.path}`,
      },
      {
        element: <AnalyticsTotalEconomics />,
        path: `${ROUTES_ADMIN.ANALYTICS.TOTAL_ECONOMICS.path}`,
      },
      {
        element: <CatalogProcessorList />,
        path: `${ROUTES_ADMIN.CATALOG.PROCESSOR.path}`,
      },
      {
        element: <OrderCenterPage />,
        path: `${ROUTES_ADMIN.ORDER_CENTER.path}`,
      },
      {
        element: <AiCenter />,
        path: `${ROUTES_ADMIN.AI_CENTER.path}`,
      },
    ],
  },
]

export const Routes = () => {
  const { isSigned } = useAuth()

  const routing = useRoutes(routes(isSigned))

  return routing
}
