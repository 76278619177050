import {
  CalculateUnitPricePayloadProps,
  QuotationProps,
  QuotationRequestProps,
  QuotationRequestStatusEnum,
} from '@/types'

export const accordionsByStatusObject: Record<
  QuotationRequestStatusEnum,
  Array<string>
> = {
  approved_for_quotation: [
    'general-information',
    'order-data',
    'catalog-products',
  ],
  completed: ['general-information', 'catalog-products'],
  disqualified: ['general-information', 'catalog-products'],
  qualified: ['general-information', 'catalog-products'],
  expired: ['general-information', 'catalog-products'],
  invalid: ['general-information', 'catalog-products'],
  processed: ['general-information', 'catalog-products'],
  pending: ['general-information', 'catalog-products'],
}

export const getQuotationSuggestedUnitPricePayload = ({
  quotationValues,
}: {
  quotationValues: QuotationProps
}): CalculateUnitPricePayloadProps => {
  return {
    items: quotationValues.products.map((product) => ({
      cost: Number(product.cost) || 0,
      price: Number(product.price) || 0,
      quantity: Number(product.quantity) || 0,
      traderCommission: Number(product.traderCommission) || 0,
      cm3:
        Number(product?.catalogProduct?.category?.expectedCm3ProfitRate) || 0,
    })),
    customCost: Number(quotationValues.customsFreightCost || 0),
    firstMileCost: Number(quotationValues.firstMileFreightCost || 0),
    lastMileCost: Number(quotationValues.lastMileFreightCost || 0),
    discount: Number(quotationValues.discount || 0),
  }
}

export const getQuotationRequestWithOrdenedItems = (
  quotationRequest?: QuotationRequestProps,
): QuotationRequestProps | undefined => {
  if (!quotationRequest) return

  const orderedItems = quotationRequest?.items?.sort((a, b) => {
    return (
      new Date(a.createdAt || '').getTime() -
      new Date(b.createdAt || '').getTime()
    )
  })

  return {
    ...quotationRequest,
    items: orderedItems,
  }
}
