import { Card, Checkbox, Tag, Text } from '@mercai/clever'

import styles from './product-card.module.css'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '@/helpers'
import { ProductCardProps } from './product-card.types'

export const ProductCard = ({
  actions,
  img,
  title,
  category,
  density,
  fluidity,
  quantity,
  purchaseFrequency,
  targetPrice,
  observation,
  isSimilarAccepted,
  onSelectedProduct,
  isSelectedProduct,
}: ProductCardProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  return (
    <Card className={styles['product-container']}>
      {onSelectedProduct && (
        <Checkbox
          value={!!isSelectedProduct}
          onChange={(value) => onSelectedProduct(value)}
        />
      )}
      <img
        src={img || '/images/product-placeholder.webp'}
        alt="placeholder"
        className={styles.img}
      />

      <div className={styles.content}>
        <div className={styles.title}>
          <Text variant="title-base">{title || '-'}</Text>

          {isSimilarAccepted ? (
            <Tag variant="primary" model="light" size="small">
              {t('catalogProductsItemIsSimilarAccepted')}
            </Tag>
          ) : (
            <Tag variant="basic" model="light" size="small">
              {t('catalogProductsItemIsSimilarNotAccepted')}
            </Tag>
          )}
        </div>

        <div className={styles.row}>
          <Text>
            <strong>{t('catalogProductsItemCategoryLabel')}:</strong>{' '}
            {category || '-'}
          </Text>
          <Text className={styles.pipe}>|</Text>
          <Text>
            <strong>{t('catalogProductsItemDensityLabel')}:</strong>{' '}
            {density || '-'} g/cm³
          </Text>
          <Text className={styles.pipe}>|</Text>
          <Text>
            <strong>{t('catalogProductsItemFluidityLabel')}:</strong>{' '}
            {fluidity || '-'} g/10min
          </Text>
        </div>

        <div className={styles.row}>
          <Text>
            <strong>{t('catalogProductsItemQuantityLabel')}:</strong>{' '}
            {quantity || '-'} Kg
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text>
            <strong>{t('catalogProductsItemFrequencyLabel')}:</strong>{' '}
            {purchaseFrequency
              ? tEnums(`quotation_request_frequency_${purchaseFrequency}`)
              : '-'}
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text>
            <strong>{t('catalogProductsItemTargetPriceLabel')}:</strong>{' '}
            {targetPrice
              ? `${formatCurrency(targetPrice, 'es-MX', 'USD')}`
              : '-'}
          </Text>

          <Text className={styles.pipe}>|</Text>

          <Text className={styles['product-details']}>
            <strong>{t('catalogProductsItemSampleLabel')}:</strong>{' '}
            {+(quantity || 0) < 1000 ? t('yesLabel') : t('noLabel')}
          </Text>
        </div>

        <div className={styles.row}>
          <Text>
            <strong>{t('catalogProductsItemObservationLabel')}:</strong>{' '}
            {observation || t('catalogProductsItemObservationEmpty')}
          </Text>
        </div>
      </div>

      {actions}
    </Card>
  )
}
