import { useState } from 'react'
import {
  Input,
  Label,
  Select,
  Skeleton,
  Text,
  InputDebounce,
  Radio,
  MultiSelect,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { useGetAddress } from '@/services'

import { AddressOptions } from './components'

import styles from './address-form.module.css'

import type { AddressFormProps } from './address-form.type'
import { LogisticShipmentVehicleTypeEnum, type BaseAddressProps } from '@/types'

export const AddressForm = ({
  onChangeValues,
  values,
  errors,
  isLoading,
  isWarehouse = false,
}: AddressFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('componentsAddressForm')

  const { data: addressResponse, mutateAsync, isPending } = useGetAddress()

  const onHandleValues = (
    key: string,
    value: string | number | boolean | string[],
  ) => {
    onChangeValues({ ...values, [key]: value })
  }

  const [selectedAddress, setSelectedAddress] = useState<BaseAddressProps>()

  const onSelectAddress = (address: BaseAddressProps) => {
    onChangeValues({
      ...values,
      city: address?.city,
      country: address?.country,
      neighborhood: address?.neighborhood,
      postalCode: address?.postalCode,
      state: address?.state,
    })
    setSelectedAddress(address)
  }

  const onSearchAddress = async (value: string) => {
    const country = (values?.country || 'MX') as 'MX' | 'US'
    onChangeValues({ ...values, country, postalCode: value })

    if (!values?.postalCode) return

    const response = await mutateAsync({
      postalCode: value,
      country,
    })

    if (response.success && response.data?.length === 1) {
      setSelectedAddress(response?.data?.[0])

      onChangeValues({
        ...values,
        city: response?.data?.[0]?.city,
        country: response?.data?.[0]?.country,
        neighborhood: response?.data?.[0]?.neighborhood,
        postalCode: response?.data?.[0]?.postalCode,
        state: response?.data?.[0]?.state,
      })
    }
  }

  const Loading = () => (
    <>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
      <div className={styles.row}>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
      </div>
      <div className={styles.row}>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
        <div>
          <Label>
            <Skeleton height="2rem" width="40%" />
          </Label>
          <Skeleton height="2rem" width="100%" />
        </div>
      </div>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
    </>
  )

  return (
    <div className={styles.core}>
      <div className={styles['core-header']}>
        <Text variant="text-large-bold">
          {t('addressFormInformationTitle')}
        </Text>
      </div>
      <div className={styles['core-content']}>
        {!isLoading ? (
          <>
            {isWarehouse && (
              <div>
                <div>
                  <Label>{t('addressFormNameLabel')}</Label>
                  <Input
                    value={values?.name}
                    placeholder={t('addressFormNamePlaceholder')}
                    onChange={(value) => onHandleValues('name', value)}
                    error={errors?.name ? (errors?.name as string) : ''}
                  />
                </div>
              </div>
            )}
            <div className={styles.row}>
              <div>
                <Label>{t('addressFormCountryLabel')}</Label>
                <Select
                  placeholder={t('addressFormCountryPlaceholder')}
                  value={values?.country}
                  options={[
                    {
                      label: t('addressFormCountryMxLabel'),
                      value: 'MX',
                    },
                    {
                      label: t('addressFormCountryUsLabel'),
                      value: 'US',
                    },
                  ]}
                  onChange={(value) => {
                    onHandleValues('country', value)
                  }}
                />
              </div>

              <div>
                <Label>{t('addressFormZipCodeLabel')}</Label>
                <InputDebounce
                  value={values?.postalCode || ''}
                  placeholder={t('addressFormZipCodePlaceholder')}
                  onChange={onSearchAddress}
                  error={
                    errors?.postalCode ? (errors?.postalCode as string) : ''
                  }
                />
              </div>
            </div>

            <AddressOptions
              addresses={addressResponse?.data || []}
              onSelectAddress={onSelectAddress}
              selectedAddress={selectedAddress}
              isLoading={isPending}
            />

            <div className={styles.card}>
              <div className={styles.row}>
                <div>
                  <Label>{t('addressFormStateLabel')}</Label>
                  <Input
                    value={values?.state}
                    placeholder={t('addressFormStatePlaceholder')}
                    onChange={(value) => onHandleValues('state', value)}
                    error={errors?.state ? (errors?.state as string) : ''}
                  />
                </div>

                <div>
                  <Label>{t('addressFormCityLabel')}</Label>
                  <Input
                    value={values?.city}
                    placeholder={t('addressFormCityPlaceholder')}
                    onChange={(value) => onHandleValues('city', value)}
                    error={errors?.city ? (errors?.city as string) : ''}
                  />
                </div>
              </div>

              <div>
                <Label>{t('addressFormNeighborhoodLabel')}</Label>
                <Input
                  value={values?.neighborhood}
                  placeholder={t('addressFormNeighborhoodPlaceholder')}
                  onChange={(value) => onHandleValues('neighborhood', value)}
                  error={
                    errors?.neighborhood ? (errors?.neighborhood as string) : ''
                  }
                />
              </div>

              <div className={styles.row}>
                <div>
                  <Label>{t('addressFormStreetLabel')}</Label>
                  <Input
                    value={values?.street}
                    placeholder={t('addressFormStreetPlaceholder')}
                    onChange={(value) => onHandleValues('street', value)}
                    error={errors?.street ? (errors?.street as string) : ''}
                  />
                </div>
                <div>
                  <Label>{t('addressFormNumberLabel')}</Label>
                  <Input
                    value={values?.number ? `${values.number}` : `0`}
                    placeholder={t('addressFormNumberPlaceholder')}
                    onChange={(value) => onHandleValues('number', value)}
                    error={errors?.number ? (errors?.number as string) : ''}
                  />
                </div>
              </div>

              <div>
                <Label>{t('addressFormComplementLabel')}</Label>
                <Input
                  value={values?.complement}
                  placeholder={t('addressFormComplementPlaceholder')}
                  onChange={(value) => onHandleValues('complement', value)}
                  error={
                    errors?.complement ? (errors?.complement as string) : ''
                  }
                />
              </div>

              <div>
                <Label>{t('addressFormReferenceLabel')}</Label>
                <Input
                  value={values?.reference}
                  placeholder={t('addressFormReferencePlaceholder')}
                  onChange={(value) => onHandleValues('reference', value)}
                  error={errors?.reference ? (errors?.reference as string) : ''}
                />
              </div>

              <div>
                <Label>{t('addressFormNeedsUnloadingSupport')}</Label>
                <Radio
                  value={!!values?.needsUnloadingSupport}
                  onChange={() => onHandleValues('needsUnloadingSupport', true)}
                >
                  {t('addressFormYes')}
                </Radio>

                <Radio
                  value={!values?.needsUnloadingSupport}
                  onChange={() =>
                    onHandleValues('needsUnloadingSupport', false)
                  }
                >
                  {t('addressFormNo')}
                </Radio>
              </div>
              <div>
                <Label>{t('addressFormRestrictedVehicles')}</Label>
                <MultiSelect
                  options={Object.values(LogisticShipmentVehicleTypeEnum).map(
                    (value) => ({
                      label: tEnums(`logistic_shipment_type_${value}`),
                      value,
                    }),
                  )}
                  values={values?.restrictedVehicles || []}
                  onChange={(newValue) =>
                    onHandleValues('restrictedVehicles', newValue)
                  }
                />
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}
