import { useEffect, useState } from 'react'

import {
  Button,
  Card,
  EditIcon,
  PlusIcon,
  Radio,
  Text,
  TrashIcon,
} from '@mercai/clever'

import { useTranslation } from 'react-i18next'

import { UpdateRfqProps } from './update-rfq.types'

import styles from './update-rfq.module.css'
import { ProductCard } from '@/pages/business/components/product-card'
import {
  checkHasChange,
  generateRightContentClasses,
} from './update-rfq.constants'
import { QuotationRequestItemProps, QuotationRequestSplitEnum } from '@/types'
import { ProductDrawerForm } from '@/pages/business/components'
import { useCatalogCategoryList } from '@/services'
import { EmptyState } from '@/components'
import { onConfirmDangerMessage, onErrorMessage } from '@/helpers'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'

export const UpdateRfq = ({
  onHandleClose,
  onHandleSubmit,
  items = [],
  reference = '',
  isLoading,
}: UpdateRfqProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const { data: catalogCategoryList } = useCatalogCategoryList()

  const [option, setOption] =
    useState<keyof typeof QuotationRequestSplitEnum>('keep_in_current')

  const [changeItem, setChangeItem] = useState<number>()

  const [newProductIsOpen, setNewProductIsOpen] = useState(false)
  const [newProductValues, setNewProductValues] =
    useState<QuotationRequestItemProps>()

  const onHandleCloseNewProductValues = () => {
    setNewProductValues(undefined)
    setNewProductIsOpen(false)
    setChangeItem(undefined)
  }

  const [internalItems, setInternalItems] = useState(items)

  const [selectedProducts, setSelectedProducts] = useState<boolean[]>(
    items?.map(() => false) || [],
  )

  const onHandleSelectProduct = (value: boolean, index: number) => {
    if (
      value &&
      selectedProducts?.filter((value) => value).length ===
        selectedProducts.length - 1
    ) {
      return onErrorMessage(
        t('updateRfqMaxProductsSelectedLabel'),
        t('updateRfqMaxProductsSelectedDescription'),
      )
    }

    const newSelectedProducts = [...selectedProducts]
    newSelectedProducts[index] = value
    setSelectedProducts(newSelectedProducts)
  }

  const hasProductSelected = selectedProducts.some((value) => value)

  const hasChange = checkHasChange(items, internalItems) || hasProductSelected

  const onHandleDelete = (index: number) => {
    const newSelectedProducts = [...selectedProducts]
    newSelectedProducts.splice(index, 1)
    setSelectedProducts(newSelectedProducts)

    const newInternalItems = [...internalItems]
    newInternalItems.splice(index, 1)
    setInternalItems(newInternalItems)

    onHandleSubmit({
      selectedProducts: newInternalItems,
      action: '',
    })
  }

  const onHandleCancel = async () => {
    if (!hasChange) return onHandleClose()

    const confirm = await onConfirmDangerMessage(
      t('updateRfqUpdateConfirmTitle'),
      t('updateRfqUpdateConfirmCancelMessage'),
      t('updateRfqUpdateConfirmButtonUpdate'),
      t('updateRfqUpdateConfirmButtonCancel'),
    )

    if (confirm) {
      onHandleClose()
    }
  }

  const onHandleAddNewProduct = () => {
    if (!newProductValues) return
    const newSelectedProducts = [...selectedProducts]
    newSelectedProducts.unshift(false)
    setSelectedProducts(newSelectedProducts)

    const newInternalItems = [...internalItems]
    if (changeItem || changeItem === 0) {
      newInternalItems[changeItem] = newProductValues
      setChangeItem(undefined)
    } else {
      newInternalItems.unshift(newProductValues)
      setNewProductValues(undefined)
    }

    setInternalItems(newInternalItems)

    onHandleSubmit({
      selectedProducts: newInternalItems,
      action: '',
    })
  }

  const onHandleSubmitComplete = async () => {
    const confirm = await onConfirmDangerMessage(
      t('updateRfqUpdateConfirmTitle'),
      t('updateRfqUpdateConfirmMessage'),
      t('updateRfqUpdateConfirmButtonUpdate'),
      t('updateRfqUpdateConfirmButtonCancel'),
    )

    if (confirm) {
      onHandleSubmit({
        selectedProducts: hasProductSelected
          ? internalItems.filter((_, i) => selectedProducts[i])
          : internalItems,
        action: hasProductSelected ? option : '',
      })
    }
  }

  useEffect(() => {
    setInternalItems(items)
    setSelectedProducts(items?.map(() => false) || [])
  }, [items])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Text variant="text-small" className={styles.breadcrumb}>
          <Link
            to={ROUTES_ADMIN.BUSINESS.RFQ.fullPath()}
          >{`${t('quotationRequestTitle')}`}</Link>
          {` / `}
          <span>{reference}</span>
        </Text>
        <div className={styles['header-content']}>
          <div>
            <div className={styles['sub-title']}>
              <Text variant="title-large">
                {t(
                  items?.length === 1
                    ? 'quotationIdentifier'
                    : 'quotationIdentifierPlural',
                  {
                    rfq: reference,
                    quantity: items?.length,
                  },
                )}
              </Text>
            </div>
            <Text
              variant="text-base"
              className={styles['sub-title-instructions']}
            >
              {t('instructionGenerateProposals')}
            </Text>
          </div>
          <div className={styles['header-actions']}>
            <Button variant="secondary" onClick={onHandleCancel}>
              {t('updateRfqCancelButton')}
            </Button>

            <Button
              variant="primary"
              onClick={onHandleSubmitComplete}
              disabled={!hasChange || isLoading}
              isActive={hasChange}
            >
              {t('updateRfqSaveButton')}
            </Button>
          </div>
        </div>
      </header>

      <div>
        <Button variant="primary" onClick={() => setNewProductIsOpen(true)}>
          <PlusIcon /> {t('updateRfqAddProductButton')}
        </Button>
      </div>

      <div className={styles.content}>
        <div className={styles['left-content']}>
          {internalItems?.map((item, index) => (
            <div key={index} className={styles['product-item']}>
              <ProductCard
                onSelectedProduct={(value) =>
                  onHandleSelectProduct(value, index)
                }
                isSelectedProduct={selectedProducts[index]}
                img={item?.catalogProduct?.attributes?.mainImage as string}
                title={
                  item?.catalogProduct?.attributes?.title ||
                  item?.product?.title ||
                  '-'
                }
                category={
                  item?.catalogProduct?.category?.name ||
                  item?.product?.category ||
                  '-'
                }
                density={
                  item?.catalogProduct?.attributes?.density ||
                  item?.product?.density ||
                  '-'
                }
                fluidity={
                  item?.catalogProduct?.attributes?.fluidity ||
                  item?.product?.fluidity ||
                  '-'
                }
                quantity={item?.quantity}
                purchaseFrequency={item?.purchaseFrequency}
                targetPrice={item?.targetPrice}
                observation={item?.observation}
                actions={
                  <div className={styles['button-actions']}>
                    <Button
                      model="square"
                      size="small"
                      variant="secondary"
                      onClick={() => onHandleDelete(index)}
                    >
                      <TrashIcon />
                    </Button>

                    <Button
                      model="square"
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        setChangeItem(index)
                        setNewProductValues(item)
                      }}
                    >
                      <EditIcon />
                    </Button>
                  </div>
                }
              />
            </div>
          ))}
          {internalItems?.length === 0 && (
            <EmptyState
              title={t('updateRfqEmptyTitle')}
              description={t('updateRfqEmptyDescription')}
            />
          )}
        </div>
        <div className={generateRightContentClasses(hasProductSelected)}>
          <Text variant="text-large-bold">{t('updateRfqEditRfqButton')}</Text>
          <div className={styles['options-container']}>
            <Card
              className={styles['options-card']}
              onClick={() => setOption('keep_in_current')}
            >
              <div className={styles['options-card-header']}>
                <Radio
                  value={option === 'keep_in_current'}
                  onChange={() => setOption('keep_in_current')}
                />
                <Text variant="title-base">{t('updateRfqOptionLabel')} 1</Text>
              </div>
              <Text>{t('updateRfqOptionKeepProductsLabel')}</Text>
            </Card>

            <Card
              className={styles['options-card']}
              onClick={() => setOption('create_for_each')}
            >
              <div className={styles['options-card-header']}>
                <Radio
                  value={option === 'create_for_each'}
                  onChange={() => setOption('create_for_each')}
                />
                <Text variant="title-base">{t('updateRfqOptionLabel')} 2</Text>
              </div>
              <Text>{t('updateRfqOptionNewByProductLabel')}</Text>
            </Card>

            <Card
              className={styles['options-card']}
              onClick={() => setOption('create_with_selected')}
            >
              <div className={styles['options-card-header']}>
                <Radio
                  value={option === 'create_with_selected'}
                  onChange={() => setOption('create_with_selected')}
                />
                <Text variant="title-base">{t('updateRfqOptionLabel')} 3</Text>
              </div>
              <Text>{t('updateRfqOptionNewLabel')}</Text>
            </Card>
          </div>
        </div>
      </div>

      <ProductDrawerForm
        isOpen={!!(changeItem || changeItem === 0) || newProductIsOpen}
        onHandleOpen={() => onHandleCloseNewProductValues()}
        values={newProductValues as QuotationRequestItemProps}
        onChangeValues={(values) =>
          setNewProductValues(values as QuotationRequestItemProps)
        }
        onHandleSubmit={onHandleAddNewProduct}
        catalogCategoryList={catalogCategoryList?.data?.results || []}
      />
    </div>
  )
}
