import { useEffect, useState } from 'react'
import { AddressProps } from '@/types'
import {
  onErrorMessage,
  onSuccessMessage,
  useValidationErrors,
} from '@/helpers'

import { useQueryClient } from '@tanstack/react-query'

import { useTranslation } from 'react-i18next'

import { Button, DrawerStepperProgress } from '@mercai/clever'

import {
  QUERIES_ADMIN,
  useAddressList,
  useCreateAddress,
  useUpdateQuotation,
  useUpdateQuotationRequest,
} from '@/services'

import { addressSchema } from './address-drawer-form.constants'
import { AddressDrawerFormProps } from './address-drawer-form.type'

import { AddressForm } from '../address-form'
import { AddressList } from '../address-list'

export const AddressDrawerForm = ({
  quotationRequest,
  quotation,
  isOpen,
  onHandleOpen,
  setAddress,
}: AddressDrawerFormProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<AddressProps>>()

  const [selectedAddress, setSelectedAddress] =
    useState<Partial<AddressProps>>()

  const profileUuid =
    quotationRequest?.buyerProfile?.uuid ||
    quotationRequest?.buyerProfileUuid ||
    quotation?.buyerProfile?.uuid ||
    quotation?.buyerProfileUuid ||
    ''

  const { data: dataAddresses } = useAddressList(profileUuid)

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    addressSchema(t),
    data,
  )

  const { mutateAsync: mutateAsyncNewAddress, isPending: isPendingNewAddress } =
    useCreateAddress(profileUuid)

  const {
    mutateAsync: mutateAsyncUpdateQuotationRequest,
    isPending: isPendingQuotationRequest,
  } = useUpdateQuotationRequest(quotationRequest?.uuid || '')

  const {
    mutateAsync: mutateAsyncUpdateQuotation,
    isPending: isPendingQuotation,
  } = useUpdateQuotation(quotation?.uuid || '')

  const onSubmitNewAddress = async ({
    onNavigateToPreviousStep,
  }: {
    onNavigateToPreviousStep: () => void
  }) => {
    if (!isValid) {
      return onHandleListenErrors(true)
    }

    onHandleListenErrors(false)

    const response = await mutateAsyncNewAddress(data as AddressProps)

    if (response.success) {
      onNavigateToPreviousStep()

      setSelectedAddress(response?.data as Partial<AddressProps>)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.ADDRESS.module],
      })
    }
  }

  const onHandleNewAddress = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('addressForm')
  }

  const onSubmitCompleted = async () => {
    const response = await (quotation
      ? mutateAsyncUpdateQuotation({
          ...quotation,
          buyerProfileUuid: profileUuid,
          deliveryAddressUuid: selectedAddress?.uuid,
          deliveryAddress: undefined,
          products: quotation?.products?.map((product) => ({
            ...product,
            catalogProductUuid: product?.catalogProduct?.uuid,
          })),
        })
      : mutateAsyncUpdateQuotationRequest({
          ...quotationRequest,
          buyerProfileUuid: profileUuid,
          addressUuid: selectedAddress?.uuid,
          address: undefined,
          items: quotationRequest?.items?.map((product) => ({
            ...product,
            catalogProductUuid: product?.catalogProduct?.uuid,
          })),
        }))

    if (response.success) {
      onSuccessMessage(
        t('addressDrawerFormSuccessTitle'),
        t('addressDrawerFormSuccessMessage'),
      )

      onHandleOpen()
      setAddress(selectedAddress as Partial<AddressProps>)
    } else {
      onErrorMessage(
        t('addressDrawerFormErrorTitle'),
        t('addressDrawerFormErrorMessage'),
      )
    }
  }

  useEffect(() => {
    if (quotationRequest?.address) {
      setSelectedAddress(quotationRequest?.address)
    }

    if (quotation?.deliveryAddress) {
      setSelectedAddress(quotation?.deliveryAddress)
    }

    if (!isOpen) {
      setSelectedAddress({})
      setData({})
    }
  }, [quotationRequest, quotation, isOpen])

  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      initialStep="root"
      translations={{ optional: '' }}
      title={t('addressDrawerFormTitle')}
      description={t('addressDrawerFormDescription')}
      steps={{
        root: {
          isOptional: true,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <AddressList
              onClickNewAddress={() =>
                onHandleNewAddress({ onNavigateToNextStep })
              }
              onChangeAddressSelected={(address) => setSelectedAddress(address)}
              addressSelected={selectedAddress as AddressProps}
              addresses={dataAddresses?.data?.addresses || []}
            />
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={onHandleOpen}>
                {t('drawerFormBack')}
              </Button>

              <Button
                onClick={onSubmitCompleted}
                isActive={!isPendingQuotationRequest || !isPendingQuotation}
                disabled={isPendingQuotationRequest || isPendingQuotation}
              >
                {t('drawerFormSubmit')}
              </Button>
            </>
          ),
        },
        addressForm: {
          isOptional: false,
          backNavigate: 'root',
          component: () => (
            <AddressForm
              onChangeValues={setData}
              errors={errors}
              values={data}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onHandleOpen}>
                {t('drawerFormBack')}
              </Button>
              <Button
                onClick={() => onSubmitNewAddress({ onNavigateToPreviousStep })}
                disabled={isPendingNewAddress}
                isActive={!isPendingNewAddress}
              >
                {t('drawerFormSubmit')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
