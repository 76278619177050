import { useTranslation } from 'react-i18next'
import { Text, ProfileIcon, Skeleton, Button } from '@mercai/clever'

import styles from './summary-profile.module.css'
import type { QuotationSummaryProfileProps } from './summary-profile.type'
import { CardItem } from '../card-item/card-item'
import { ROUTES_ADMIN } from '@/router'

const QuotationSummaryProfileDefault = ({
  companyName,
  email,
  phone,
  uuid,
  customerLevel,
  moscow,
}: QuotationSummaryProfileProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2Show')

  return (
    <CardItem
      icon={<ProfileIcon />}
      title={t('quotationShowTitleSummaryProfile')}
      actions={
        <Button
          variant="primary-outline"
          model="square"
          target="_blank"
          href={`/${ROUTES_ADMIN.PROFILE.path}/${uuid}`}
        >
          {t('quotationShowSummaryShowButton')}
        </Button>
      }
    >
      <div className={styles.content}>
        <Text>
          <strong>{t('quotationShowSummaryProfileCompanyName')}:</strong>{' '}
          {companyName || t('quotationShowSummaryEmpty')}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfileEmail')}:</strong>{' '}
          {email || t('quotationShowSummaryEmpty')}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfileCustomerLevel')}:</strong>{' '}
          {customerLevel
            ? tEnums(`profile_customer_${customerLevel}`)
            : t('quotationShowSummaryEmpty')}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfileMoSCoW')}:</strong>{' '}
          {moscow || t('quotationShowSummaryEmpty')}
        </Text>

        <Text>
          <strong>{t('quotationShowSummaryProfilePhone')}:</strong>{' '}
          {phone || t('quotationShowSummaryEmpty')}
        </Text>
      </div>
    </CardItem>
  )
}

const QuotationSummaryProfileLoading = () => {
  const { t } = useTranslation('screenQuotationV2Show')
  return (
    <CardItem
      icon={<ProfileIcon />}
      title={t('quotationShowTitleSummaryProfile')}
      actions={<Skeleton height="2rem" width="2rem" />}
    >
      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="40%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="50%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="30%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="40%" />
        </div>
      </div>
    </CardItem>
  )
}

export const QuotationSummaryProfile = (
  props: QuotationSummaryProfileProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryProfileLoading />
  }

  return <QuotationSummaryProfileDefault {...props} />
}
