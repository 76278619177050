import { useTranslation } from 'react-i18next'
import { Alert, Button, Text, PlusIcon, Tag, TrashIcon } from '@mercai/clever'

import { formatCurrency } from '@/helpers'

import type { ProductListProps } from './product-list.type'

import styles from './product-list.module.css'

export const ProductList = ({
  onSelectedProduct,
  onClickNewProduct,
  onClickRemoveProduct,
  productItems,
  errors,
}: ProductListProps) => {
  const { t } = useTranslation('screenQuotationRequestListV2')

  const errorsArray = Object.values(errors)

  const onHandleClickRemove = (
    event: React.MouseEvent<HTMLButtonElement>,
    productIndex: number,
  ) => {
    event.stopPropagation()

    onClickRemoveProduct(productIndex)
  }

  return (
    <div className={styles.core}>
      <Text>{t('productListDescription')}</Text>

      {productItems?.map((productItem, productIndex) => {
        const { product, catalogProduct } = productItem

        const title = catalogProduct?.attributes?.title || product?.title || '-'
        const category =
          catalogProduct?.category?.shortName || product?.category || '-'
        const density = catalogProduct?.attributes?.density || product?.density
        const fluidity =
          catalogProduct?.attributes?.fluidity || product?.fluidity
        const size = catalogProduct?.attributes?.size || product?.size
        const quantityPerPackage =
          catalogProduct?.attributes?.quantityPerPackage ||
          product?.quantityPerPackage

        return (
          <button
            className={styles['product-item']}
            type="button"
            key={productIndex}
            onClick={() => onSelectedProduct(productIndex)}
          >
            <Text variant="title-base">{title}</Text>
            <Text>
              <strong>{t('productListItemCategory')}:</strong> {category}
            </Text>

            {density && (
              <Text>
                <strong>{t('productListItemDensity')}:</strong>{' '}
                {`${density} g/cm³`}
              </Text>
            )}

            {fluidity && (
              <Text>
                <strong>{t('productListItemFluidity')}:</strong>{' '}
                {`${fluidity} g/10min`}
              </Text>
            )}

            {size && (
              <Text>
                <strong>{t('productListItemSize')}:</strong> {size}
              </Text>
            )}

            {quantityPerPackage && (
              <Text>
                <strong>{t('productListItemQuantityPerPackage')}:</strong>{' '}
                {quantityPerPackage}
              </Text>
            )}

            <Text>
              <strong>{t('productListItemTargetPrice')}:</strong>{' '}
              {productItem?.targetPrice
                ? formatCurrency(productItem.targetPrice, 'es-MX', 'USD')
                : '-'}
            </Text>

            <div className={styles['product-item-row']}>
              <Text>
                <strong>{t('productListItemQuantity')}:</strong>{' '}
                {productItem.quantity ? `${productItem.quantity} kg` : ' - '}
              </Text>

              {+(productItem?.quantity || 0) < 1000 && (
                <Tag variant="basic" model="light" className={styles.tag}>
                  {t('productListItemSample')}
                </Tag>
              )}

              {productItem?.isSimilarAccepted && (
                <Tag variant="primary" model="outline">
                  {t('productListItemIsSimilarAccepted')}
                </Tag>
              )}
            </div>

            <Button
              variant="danger-light"
              model="square"
              className={styles['product-item-remove-button']}
              onClick={(event) => onHandleClickRemove(event, productIndex)}
            >
              <TrashIcon />
            </Button>
          </button>
        )
      })}

      <Button variant="light" onClick={onClickNewProduct}>
        <PlusIcon /> {t('productListButton')}
      </Button>

      {errorsArray?.length > 0 && (
        <Alert variant="danger">
          {errorsArray?.map((error) => <div key={error}>{error}</div>)}
        </Alert>
      )}
    </div>
  )
}
