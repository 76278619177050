import { useEffect, useState } from 'react'
import { Button, DrawerStepper } from '@mercai/clever'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import {
  useValidationErrors,
  onErrorMessage,
  onSuccessMessage,
  onConfirmDangerMessage,
} from '@/helpers'
import {
  useAddressList,
  useCreateAddress,
  useCreateQuotationRequest,
  useUpdateQuotationRequest,
  useQuotationRequestDetail,
  useCreateProfile,
  useCatalogCategoryList,
  useProfileList,
  QUERIES_ADMIN,
} from '@/services'

import {
  AddressProps,
  QuotationRequestProps,
  QuotationRequestItemProps,
  ProfileProps,
} from '@/types'

import {
  schemaProductList,
  shemaAddressItem,
  schemaProfileItem,
} from './drawer-form.constants'

import {
  ProductList,
  SearchMethod,
  UploadFile,
  ProfileList,
  ProfileForm,
  QuotationRequestForm,
} from './components'

import { QuotationRequestDrawerFormProps } from './drawer-form.type'
import {
  AddressList,
  AddressForm,
  ProductForm,
} from '@/pages/business/components'

export const QuotationRequestDrawerForm = ({
  isOpen,
  onHandleOpen,
  quotationRequestUuid,
}: QuotationRequestDrawerFormProps) => {
  const { t } = useTranslation('screenQuotationRequestListV2')

  const onHandleOpenConfirmation = async (value: boolean) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationRequestConfirmCloseTitle'),
      t('quotationRequestConfirmCloseMessage'),
      t('quotationRequestConfirmCloseButtonDelete'),
      t('quotationRequestConfirmCloseButtonCancel'),
    )

    if (confirm) {
      onHandleOpen(value)
    }
  }

  const isNewRegister = !quotationRequestUuid

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<QuotationRequestProps>>({})
  const [initialStep, setInitialStep] = useState('root')

  const [missingFile, setMissingFile] = useState(false)

  const [method, setMethod] = useState('file')

  const [profileItem, setProfileItem] = useState<Partial<ProfileProps>>({})
  const {
    isValid: isValidProfileItem,
    errors: profileItemErrors,
    onHandleListenErrors: onHandleListenErrorsProfile,
  } = useValidationErrors(schemaProfileItem(t), profileItem)
  const [profileFilters, setProfileFilters] = useState({
    currentPage: 1,
    search: '',
  })
  const [productItem, setProductItem] = useState<
    Partial<QuotationRequestItemProps>
  >({})
  const [productIndex, setProductIndex] = useState<number | undefined>()

  const { data: catalogCategoryList } = useCatalogCategoryList()

  const {
    isValid: isValidProductList,
    errors: productListErrorsList,
    onHandleListenErrors: onHandleListenErrorsProductList,
  } = useValidationErrors(schemaProductList(t), data)

  const [addressItem, setAddressItem] = useState<Partial<AddressProps>>({})
  const {
    isValid: isValidAddressItem,
    errors: addressItemErrors,
    onHandleListenErrors: onHandleListenErrorsAddressItem,
  } = useValidationErrors(shemaAddressItem(t), addressItem)

  const { data: quotationRequestData } =
    useQuotationRequestDetail(quotationRequestUuid)

  useEffect(() => {
    if (isOpen) {
      if (isNewRegister) {
        setInitialStep('profileList')

        setData({
          origin: 'backoffice_quotation_request',
          status: 'qualified',
        })
        setProfileItem({})
        setMethod('file')
        setProfileFilters({
          currentPage: 1,
          search: '',
        })
      } else {
        setInitialStep('root')

        if (quotationRequestData?.data) {
          setData({
            ...quotationRequestData.data,
            items: quotationRequestData.data?.items?.map((item) => ({
              ...item,
              catalogProductUuid: item?.catalogProduct?.uuid,
            })),
          })

          setProfileFilters({
            search: quotationRequestData.data?.buyerProfile?.email || '',
            currentPage: 1,
          })

          setProfileItem(quotationRequestData.data?.buyerProfile || {})
          setMethod(quotationRequestData.data?.filePath ? 'file' : 'manual')
        }
      }
    }
  }, [isOpen, quotationRequestData])

  const onHandleNewProfile = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    setProfileItem({})
    onNavigateToNextStep('profileForm')
  }

  const { data: profiles, isLoading: isLoadingProfiles } = useProfileList({
    params: {
      pagination: profileFilters.currentPage,
      search: profileFilters.search,
    },
  })

  const onSelectProfile = (profile: Partial<ProfileProps>) => {
    setProfileItem(profile)
  }

  const onSubmitProfile = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (profileItem?.uuid) {
      setData({ ...data, buyerProfileUuid: profileItem.uuid })
    }

    onNavigateToNextStep('method')
  }

  const { mutateAsync: mutateAsyncCreateProfile } = useCreateProfile()

  const onSubmitNewProfile = async ({
    onNavigateToPreviousStep,
  }: {
    onNavigateToPreviousStep: () => void
  }) => {
    if (isValidProfileItem) {
      const response = await mutateAsyncCreateProfile(profileItem)

      if (response.success) {
        setProfileItem(response?.data || {})
        onHandleListenErrorsProfile(false)
        onNavigateToPreviousStep()

        setProfileFilters({
          search: response.data?.email || '',
          currentPage: 1,
        })
        setData({ ...data, buyerProfileUuid: response?.data?.uuid })
      }
    } else {
      onHandleListenErrorsProfile(true)
    }
  }

  const onSubmitMethod = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    const methodRoute: Record<string, string> = {
      file: 'file',
      manual: 'manualProductList',
    }

    onNavigateToNextStep(methodRoute?.[method])
  }

  const onUploadFile = (filePath: string) => {
    setData({
      ...data,
      filePath,
      file: filePath,
    })

    setMissingFile(false)
  }

  const onSubmitFile = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (!data.file) {
      setMissingFile(true)
      return
    }

    setMissingFile(false)
    onNavigateToNextStep('addressList')
  }

  const onSubmitProducts = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    if (isValidProductList) {
      onHandleListenErrorsProductList(false)

      onNavigateToNextStep('addressList')
    } else {
      onHandleListenErrorsProductList(true)
    }
  }

  const onHandleNewProduct = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    setProductIndex(undefined)
    setProductItem({})
    onNavigateToNextStep('manualNewProduct')
  }

  const onHandleEditProduct = ({
    productIndex: productIndexParam,
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
    productIndex: number
  }) => {
    const product = data.items?.[productIndexParam]

    setProductIndex(productIndexParam)
    setProductItem(product as Partial<QuotationRequestItemProps>)
    onNavigateToNextStep('manualNewProduct')
  }

  const onHandleSubmitProduct = async () => {
    if (productIndex || productIndex === 0) {
      const copyItems = [...(data.items || [])]
      copyItems[productIndex] = productItem

      setData({
        ...data,
        items: copyItems,
      })
    } else {
      setData({
        ...data,
        items: [...(data?.items || []), productItem],
      })
    }

    setProductItem({})
    setProductIndex(undefined)
  }

  const onHandleClickRemove = (productIndex: number) => {
    const newItems = data.items?.filter((_, index) => index !== productIndex)

    setData({
      ...data,
      items: newItems,
    })
  }

  const { data: dataAddresses } = useAddressList(profileItem?.uuid || '')

  const onHandleNewAddress = ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    onNavigateToNextStep('addressForm')
  }

  const { mutateAsync } = useCreateAddress(profileItem?.uuid || '')

  const onSubmitNewAddress = async ({
    onNavigateToPreviousStep,
  }: {
    onNavigateToPreviousStep: () => void
  }) => {
    if (isValidAddressItem) {
      const response = await mutateAsync(addressItem)

      if (response.success) {
        onHandleListenErrorsAddressItem(false)
        onNavigateToPreviousStep()

        setAddressItem(response?.data || {})
        setData({ ...data, addressUuid: response?.data?.uuid })

        queryClient.invalidateQueries({
          queryKey: [QUERIES_ADMIN.ADDRESS.module, profileItem?.uuid],
        })
      }
    } else {
      onHandleListenErrorsAddressItem(true)
    }
  }

  const { mutateAsync: mutateAsyncCreateQuotationRequest } =
    useCreateQuotationRequest()

  const { mutateAsync: mutateAsyncUpdateQuotationRequest } =
    useUpdateQuotationRequest(quotationRequestUuid || '')

  const onSubmitCompleted = async ({
    onNavigateToNextStep,
  }: {
    onNavigateToNextStep: (route: string) => void
  }) => {
    let response: {
      success: boolean
      message: string
      data?: QuotationRequestProps
    } = {
      success: false,
      message: '',
      data: {} as QuotationRequestProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateQuotationRequest(data)
    } else {
      response = await mutateAsyncUpdateQuotationRequest(data)
    }

    if (response.success) {
      if (isNewRegister) {
        onSuccessMessage(t('successCreateTitle'), t('successCreateMessage'))
      } else {
        onSuccessMessage(t('successUpdateTitle'), t('successUpdateMessage'))
      }

      onHandleOpen(false)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })

      setData({})

      onHandleOpen(false)

      onNavigateToNextStep('root')
    } else {
      if (isNewRegister) {
        onErrorMessage(t('errorCreateTitle'), t('errorCreateMessage'))
      } else {
        onErrorMessage(t('errorUpdateTitle'), t('errorUpdateMessage'))
      }
    }
  }

  return (
    <DrawerStepper
      translations={{
        optional: t('optionalLabel'),
      }}
      initialStep={initialStep}
      isOpen={isOpen}
      onHandleOpen={onHandleOpenConfirmation}
      title={
        isNewRegister
          ? t('quotationRequestCreateDrawerTitle')
          : t('quotationRequestUpdateDrawerTitle')
      }
      steps={{
        root: {
          title: t('quotationsRequestFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <QuotationRequestForm
              onChangeValues={setData}
              values={data}
              onHandleSubmit={() => onNavigateToNextStep('profileList')}
              onBack={() => onHandleOpen(false)}
              isNewRegister={isNewRegister}
              onHandleSubmitCompleted={() =>
                onSubmitCompleted({ onNavigateToNextStep })
              }
            />
          ),
        },
        profileList: {
          title: t('searchProfileTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: ({ onNavigateToNextStep }) => (
            <ProfileList
              onSelectedProfile={onSelectProfile}
              onClickNewProfile={() =>
                onHandleNewProfile({ onNavigateToNextStep })
              }
              profileSelected={profileItem}
              profiles={profiles?.data?.results || []}
              onChangeSearch={(search) =>
                setProfileFilters({ ...profileFilters, search })
              }
              search={profileFilters.search}
              isLoading={isLoadingProfiles}
            />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitProfile({ onNavigateToNextStep })}>
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        profileForm: {
          title: t('searchProfileFormTitle'),
          isOptional: false,
          backNavigate: 'profileList',
          component: (
            <ProfileForm
              errors={profileItemErrors as Record<string, string>}
              onChangeValues={setProfileItem}
              values={profileItem}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonCancel')}
              </Button>

              <Button
                onClick={() => onSubmitNewProfile({ onNavigateToPreviousStep })}
              >
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        method: {
          title: t('searchMethodTitle'),
          isOptional: false,
          backNavigate: 'root',
          component: (
            <SearchMethod method={method} onChangeMethod={setMethod} />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitMethod({ onNavigateToNextStep })}>
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        file: {
          title: t('uploadFileTitle'),
          isOptional: false,
          backNavigate: 'method',
          component: (
            <UploadFile
              onSetFile={onUploadFile}
              errorMissingFile={missingFile}
              file={data?.file}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitFile({ onNavigateToNextStep })}>
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        manualProductList: {
          title: t('productListTitle'),
          isOptional: false,
          backNavigate: 'method',
          component: ({ onNavigateToNextStep }) => (
            <ProductList
              onClickNewProduct={() =>
                onHandleNewProduct({ onNavigateToNextStep })
              }
              onClickRemoveProduct={onHandleClickRemove}
              onSelectedProduct={(productIndex) =>
                onHandleEditProduct({ onNavigateToNextStep, productIndex })
              }
              productItems={data.items || []}
              errors={productListErrorsList as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToNextStep, onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitProducts({ onNavigateToNextStep })}
              >
                {t('navigateButtonNext')}
              </Button>
            </>
          ),
        },
        manualNewProduct: {
          title: t('productFormTitle'),
          isOptional: false,
          backNavigate: 'manualProductList',
          component: ({ onNavigateToPreviousStep }) => (
            <ProductForm
              values={productItem}
              onChangeValues={(newData) => setProductItem(newData)}
              catalogCategoryList={catalogCategoryList?.data?.results || []}
              onHandleSubmit={onHandleSubmitProduct}
              onNavigateToPreviousStep={onNavigateToPreviousStep}
            />
          ),
        },
        addressList: {
          title: t('addressListTitle'),
          isOptional: true,
          backNavigate: method === 'file' ? 'file' : 'manualProductList',
          component: ({ onNavigateToNextStep }) => (
            <AddressList
              onClickNewAddress={() =>
                onHandleNewAddress({ onNavigateToNextStep })
              }
              onChangeAddressSelected={(address) =>
                setData({
                  ...data,
                  address,
                  addressUuid: address?.uuid,
                })
              }
              addressSelected={data?.address as AddressProps}
              addresses={dataAddresses?.data?.addresses || []}
            />
          ),
          footer: ({ onNavigateToPreviousStep, onNavigateToNextStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitCompleted({ onNavigateToNextStep })}
              >
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
        addressForm: {
          title: t('addressFormTitle'),
          isOptional: false,
          backNavigate: 'addressList',
          component: (
            <AddressForm
              onChangeValues={setAddressItem}
              values={addressItem}
              errors={addressItemErrors as Record<string, string>}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button variant="secondary" onClick={onNavigateToPreviousStep}>
                {t('navigateButtonBack')}
              </Button>

              <Button
                onClick={() => onSubmitNewAddress({ onNavigateToPreviousStep })}
              >
                {t('navigateButtonFinish')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
