import { useState } from 'react'

import { SparksIcon } from '@mercai/clever'

import { DrawerFormAIChat } from '@/components'

import { AiWrapperProps } from './ai-wrapper.type'

import styles from './ai.wrapper.module.css'

export const AiWrapper = ({ children }: AiWrapperProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [uuid, setUuid] = useState('')

  return (
    <>
      {children}

      {!isOpen && (
        <button className={styles.image} onClick={() => setIsOpen(true)}>
          <SparksIcon className={styles['image-svg']} />
        </button>
      )}

      <DrawerFormAIChat
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        uuid={uuid}
        onChangeUuid={setUuid}
      />
    </>
  )
}
