import { useTranslation } from 'react-i18next'
import { Alert, Button, Text, PlusIcon, Tag, TrashIcon } from '@mercai/clever'

import { formatCurrency, transformObjectToArrayOfStrings } from '@/helpers'

import type { ProductListProps } from './product-list.type'

import type { QuotationProductProps } from '@/types'

import styles from './product-list.module.css'

export const ProductList = ({
  onSelectedProduct,
  onClickNewProduct,
  onClickRemoveProduct,
  productItems,
  errors,
}: ProductListProps) => {
  const { t } = useTranslation('screenQuotationList')

  const errorsArray = Object.values(errors)

  const onHandleClickRemove = (
    event: React.MouseEvent<HTMLButtonElement>,
    productItem: Partial<QuotationProductProps>,
  ) => {
    event.stopPropagation()

    onClickRemoveProduct(productItem)
  }

  return (
    <div className={styles.core}>
      <Text>{t('quotationFormProductListDescription')}</Text>

      {productItems?.map((productItem) => (
        <button
          className={styles['product-item']}
          type="button"
          key={productItem.uuid}
          onClick={() => onSelectedProduct(productItem)}
        >
          <Text variant="title-base">
            {productItem?.catalogProduct?.attributes?.title ||
              productItem?.nonCatalogProduct?.description}
          </Text>

          <div className={styles['product-item-row']}>
            <Text>
              <strong>{t('quotationFormProductListItemQuantity')}:</strong>{' '}
              {productItem.quantity ? `${productItem.quantity} kg` : ' - '}
            </Text>

            {+(productItem?.quantity || 0) < 500 && (
              <Tag variant="basic" model="light" className={styles.tag}>
                {t('quotationFormProductListItemSample')}
              </Tag>
            )}
          </div>

          <Text>
            <strong>{t('quotationFormProductListItemPrice')}:</strong>{' '}
            {productItem.price
              ? formatCurrency(productItem?.price, 'es-MX', 'USD', 3)
              : ' - '}
          </Text>

          <Text>
            <strong>{t('quotationFormProductListItemTotalPrice')}:</strong>{' '}
            {productItem.price && productItem?.quantity
              ? formatCurrency(
                  productItem.price * productItem?.quantity,
                  'es-MX',
                  'USD',
                  3,
                )
              : ' - '}
          </Text>
          <Button
            variant="danger-light"
            model="square"
            className={styles['product-item-remove-button']}
            onClick={(event) => onHandleClickRemove(event, productItem)}
          >
            <TrashIcon />
          </Button>
        </button>
      ))}

      <Button variant="light" onClick={onClickNewProduct}>
        <PlusIcon /> {t('quotationFormProductListButton')}
      </Button>

      {errorsArray?.length > 0 && (
        <Alert variant="danger">
          {transformObjectToArrayOfStrings(errors)?.map((error) => (
            <div key={error}>{error}</div>
          ))}
        </Alert>
      )}
    </div>
  )
}
