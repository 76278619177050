import { useEffect, useRef, useState, RefObject } from 'react'
import { TableRowsProps } from './table-rows.type'

import { Skeleton } from '../../core/skeleton'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EyeIcon,
  EyeSlashIcon,
  UnlockIcon,
  LockIcon,
} from '../../../icons'

import {
  generateClassesOfTableControl,
  generateClassesOfHighlightColumn,
} from './table-rows.constants'
import styles from './table-rows.module.css'
import { Button } from '../../core'

export const TableRows = <T,>({
  columns,
  data,
  isLoading,
}: TableRowsProps<T>) => {
  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const fixedElementRef: RefObject<HTMLButtonElement> = useRef(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  const [isShowBorder, setIsShowBorder] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)

  const handleMouseMove = (e: MouseEvent) => {
    if (isMouseDown && containerRef.current && fixedElementRef.current) {
      const newTop = e.clientY
      const maxTop = window.innerHeight - fixedElementRef.current.offsetHeight

      if (newTop >= 0 && newTop <= maxTop) {
        fixedElementRef.current.style.top = `${newTop}px`
      }
    }
  }

  const handleMouseUp = () => {
    if (isMouseDown) {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }

  const handleMouseDown = () => {
    if (isMouseDown) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }
  }

  const localData = isLoading ? ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9] as T[]) : data

  function getValueByPath<U>(
    object: U,
    path: string | number,
  ): string | undefined {
    const keys = `${path}`.split('.')
    let result: any = object // eslint-disable-line
    for (const key of keys) {
      if (result == null) {
        return '-'
      }
      result = result[key]
    }
    return result
  }

  const scrollLeft = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -600, behavior: 'smooth' })
    }
  }

  const scrollRight = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 600, behavior: 'smooth' })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      const fixedElement = fixedElementRef.current

      if (container && fixedElement) {
        const rect = container.getBoundingClientRect()
        const containerWidth = container.offsetWidth
        const scrollTop = window.scrollY || document.documentElement.scrollTop

        const absoluteTop = rect.top + scrollTop

        fixedElement.style.top = `${absoluteTop}px`
        fixedElement.style.left = `${rect.left}px`
        fixedElement.style.width = `${containerWidth}px`
      }
    }

    window.addEventListener('resize', handleScroll)
    handleScroll()

    return () => {
      window.removeEventListener('resize', handleScroll)
    }
  }, [])

  return (
    <div className={styles['table-wrap']} ref={containerRef}>
      <div className={styles['sidebar-table']}>
        <ul className={styles.body}>
          {columns?.map((column, index) => {
            const style = column?.height
              ? { minHeight: column.height, maxHeight: column.height }
              : undefined

            return (
              <li
                className={styles['body-row']}
                key={`column_${column.key}_${index}`}
                style={style}
              >
                <div
                  className={generateClassesOfHighlightColumn(
                    true,
                    !!column.isHighlight,
                  )}
                >
                  {column.isBold ? (
                    <strong>{column.title}</strong>
                  ) : (
                    column.title
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>

      <div className={styles['table-overflow']} ref={scrollRef}>
        <div className={styles.table}>
          {localData?.map((record, recordIndex) => (
            <ul className={styles.body} key={`record_${recordIndex}`}>
              {columns?.map((column, colIndex) => {
                const value = column.isHighlight
                  ? ''
                  : getValueByPath(record, column?.dataKey as string) || '-'
                const style = column?.height
                  ? { minHeight: column.height, maxHeight: column.height }
                  : undefined

                if (isLoading) {
                  return (
                    <div
                      className={generateClassesOfHighlightColumn(
                        false,
                        !!column.isHighlight,
                      )}
                      key={`column_${column.key}_${colIndex}` as string}
                      style={style}
                    >
                      {column.renderLoading || (
                        <Skeleton height="1rem" width="100%" />
                      )}
                    </div>
                  )
                }

                const render = column.render
                  ? column.render({ value, record, index: recordIndex })
                  : value

                return (
                  <div
                    className={generateClassesOfHighlightColumn(
                      false,
                      !!column.isHighlight,
                    )}
                    key={column.key as string}
                    style={style}
                  >
                    {column.isBold ? <strong>{render}</strong> : render}
                  </div>
                )
              })}
            </ul>
          ))}
        </div>
      </div>

      <button
        type="button"
        className={generateClassesOfTableControl(isShowBorder, !isMouseDown)}
        ref={fixedElementRef}
        onMouseDown={handleMouseDown}
      >
        <div className={styles['table-control-buttons-wrap']}>
          <Button
            size="small"
            variant="secondary"
            onClick={() => setIsShowBorder(!isShowBorder)}
            model="square"
            className={`${styles['table-control-button']} ${styles['hidden-buttons']}`}
          >
            {isShowBorder ? <EyeSlashIcon /> : <EyeIcon />}
          </Button>
          <Button
            size="small"
            variant="secondary"
            onClick={() => setIsMouseDown(!isMouseDown)}
            model="square"
            className={`${styles['table-control-button']} ${styles['hidden-buttons']}`}
          >
            {isMouseDown ? <LockIcon /> : <UnlockIcon />}
          </Button>
          <Button
            size="small"
            variant="secondary"
            onClick={scrollLeft}
            model="square"
            className={styles['table-control-button']}
          >
            <ChevronLeftIcon />
          </Button>
          <Button
            size="small"
            variant="secondary"
            onClick={scrollRight}
            model="square"
            className={styles['table-control-button']}
          >
            <ChevronRightIcon />
          </Button>
        </div>
      </button>
    </div>
  )
}
