import { SVGProps } from 'react'

export const PersonAddOutlineIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.3337 5.99935V3.99935H12.0003V5.99935H10.0003V7.33268H12.0003V9.33268H13.3337V7.33268H15.3337V5.99935H13.3337ZM6.00033 7.99935C7.47366 7.99935 8.66699 6.80602 8.66699 5.33268C8.66699 3.85935 7.47366 2.66602 6.00033 2.66602C4.52699 2.66602 3.33366 3.85935 3.33366 5.33268C3.33366 6.80602 4.52699 7.99935 6.00033 7.99935ZM6.00033 3.99935C6.73366 3.99935 7.33366 4.59935 7.33366 5.33268C7.33366 6.06602 6.73366 6.66602 6.00033 6.66602C5.26699 6.66602 4.66699 6.06602 4.66699 5.33268C4.66699 4.59935 5.26699 3.99935 6.00033 3.99935ZM10.2603 9.70602C9.14033 9.13268 7.68699 8.66602 6.00033 8.66602C4.31366 8.66602 2.86033 9.13268 1.74033 9.70602C1.07366 10.046 0.666992 10.7327 0.666992 11.4793V13.3327H11.3337V11.4793C11.3337 10.7327 10.927 10.046 10.2603 9.70602ZM10.0003 11.9993H2.00033V11.4793C2.00033 11.226 2.13366 10.9993 2.34699 10.8927C3.14033 10.486 4.42033 9.99935 6.00033 9.99935C7.58033 9.99935 8.86032 10.486 9.65366 10.8927C9.86699 10.9993 10.0003 11.226 10.0003 11.4793V11.9993Z" />
    </svg>
  )
}
