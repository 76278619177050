import { SVGProps } from 'react'

export const CreditLetterIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M12 11L20 6H4L12 11ZM12 13L4 8V18H9.15L11.15 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V10.35L20 12.35V8L12 13Z" />
      <path d="M16.3743 22V20.8056C15.8075 20.6944 15.3182 20.4815 14.9064 20.1667C14.4947 19.8519 14.1925 19.4074 14 18.8333L15.1872 18.4167C15.3476 18.8611 15.5856 19.1991 15.9011 19.4306C16.2166 19.662 16.631 19.7778 17.1444 19.7778C17.5829 19.7778 17.9545 19.6921 18.2594 19.5208C18.5642 19.3495 18.7166 19.0833 18.7166 18.7222C18.7166 18.3981 18.5989 18.1412 18.3636 17.9514C18.1283 17.7616 17.5829 17.5463 16.7273 17.3056C15.8075 17.0556 15.1765 16.7569 14.8342 16.4097C14.492 16.0625 14.3209 15.6389 14.3209 15.1389C14.3209 14.537 14.5455 14.0694 14.9947 13.7361C15.4439 13.4028 15.9037 13.213 16.3743 13.1667V12H17.6578V13.1667C18.1925 13.2407 18.6337 13.4097 18.9813 13.6736C19.3289 13.9375 19.5829 14.2593 19.7433 14.6389L18.5561 15.0833C18.4278 14.787 18.246 14.5648 18.0107 14.4167C17.7754 14.2685 17.4545 14.1944 17.0481 14.1944C16.5775 14.1944 16.2193 14.2847 15.9733 14.4653C15.7273 14.6458 15.6043 14.8704 15.6043 15.1389C15.6043 15.4444 15.7647 15.6852 16.0856 15.8611C16.4064 16.037 16.9626 16.2222 17.754 16.4167C18.492 16.6019 19.0508 16.8958 19.4305 17.2986C19.8102 17.7014 20 18.1667 20 18.6944C20 19.3519 19.7754 19.8519 19.3262 20.1944C18.877 20.537 18.3209 20.75 17.6578 20.8333V22H16.3743Z" />
    </svg>
  )
}
