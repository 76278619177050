import { Text } from '../text'

import { MultiSelectProps, MultiSelectSizeEnum } from './multi-select.type'

import { generateClasses } from './multi-select.constants'

import styles from './multi-select.module.css'
import { useEffect, useRef, useState } from 'react'
import { ChevronDownIcon, CloseIcon } from '@/lib/icons'

const MultiSelect = ({
  className,
  disabled,
  onChange,
  size = MultiSelectSizeEnum.medium,
  values = [],
  iconLeft,
  iconRight,
  placeholder,
  id,
  options,
  error,
}: MultiSelectProps) => {
  const classes = generateClasses(values.length === 0, size, !!error)

  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>(
    'bottom',
  )

  const selectRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState<string[]>(values)

  const optionsObject: Record<string, string> = options.reduce(
    (acc, item) => {
      acc[item.value] = item.label
      return acc
    },
    {} as Record<string, string>,
  )

  const handleToggleOpen = () => {
    !disabled && setIsOpen(!isOpen)
  }

  const handleRemove = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    selectedValue: string,
  ) => {
    e.stopPropagation()
    if (disabled) return

    const newValues = selectedValues.filter((v) => v !== selectedValue)
    setSelectedValues(newValues)
    onChange?.(newValues)
  }

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    selectedValue: string,
  ) => {
    e.stopPropagation()
    if (disabled) return

    const newValues = selectedValues.includes(selectedValue)
      ? selectedValues.filter((v) => v !== selectedValue)
      : [...selectedValues, selectedValue]
    setSelectedValues(newValues)
    onChange?.(newValues)
  }

  const SelectedOption = ({ value }: { value: string }) => (
    <div className={styles['selected-option']} key={value}>
      <Text variant="text-small">{optionsObject[value]}</Text>
      <CloseIcon
        className={styles['remove-icon']}
        onClick={(e) => handleRemove(e, value)}
      />
    </div>
  )

  useEffect(() => {
    if (isOpen && selectRef.current) {
      const rect = selectRef.current.getBoundingClientRect()
      const spaceBelow = window.innerHeight - rect.bottom
      const spaceAbove = rect.top

      if (spaceBelow < 310 && spaceAbove > spaceBelow) {
        setDropdownPosition('top')
      } else {
        setDropdownPosition('bottom')
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectRef.current &&
        isOpen &&
        !selectRef.current?.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isOpen])

  return (
    <div className={className} id={id} ref={selectRef}>
      <div className={classes} onClick={handleToggleOpen}>
        {!iconLeft || <div className={styles['left-icon']}>{iconLeft}</div>}
        <div className={styles.select}>
          {selectedValues.length > 0
            ? selectedValues.map((val) => (
                <SelectedOption key={val} value={val} />
              ))
            : placeholder}
        </div>
        {!iconRight || <div className={styles['right-icon']}>{iconRight}</div>}
        <div className={styles.arrow} onClick={handleToggleOpen}>
          <ChevronDownIcon />
        </div>
        {isOpen && (
          <ul className={`${styles.dropdown} ${styles[dropdownPosition]}`}>
            {options.map((option) => (
              <li
                key={option.value}
                className={
                  selectedValues.includes(option.value) ? styles.selected : ''
                }
                onClick={(e) => handleSelect(e, option.value)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {!error || <Text className={styles.error}>{error}</Text>}
    </div>
  )
}

export default MultiSelect
