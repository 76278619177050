import styles from './tag.module.css'

import { TagSizeEnum, TagVariantEnum, TagModelEnum } from './tag.type'

export const sizes: Record<TagSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
}

export const variantsFill: Record<TagVariantEnum, string> = {
  basic: styles['variant-basic'],
  primary: styles['variant-primary'],
  secondary: styles['variant-secondary'],
  warning: styles['variant-warning'],
  success: styles['variant-success'],
  danger: styles['variant-danger'],
  info: styles['variant-info'],
}

export const variantsOutline: Record<TagVariantEnum, string> = {
  basic: styles['variant-outline-basic'],
  primary: styles['variant-outline-primary'],
  secondary: styles['variant-outline-secondary'],
  warning: styles['variant-outline-warning'],
  success: styles['variant-outline-success'],
  danger: styles['variant-outline-danger'],
  info: styles['variant-outline-info'],
}

export const variantsLight: Record<TagVariantEnum, string> = {
  basic: styles['variant-light-basic'],
  primary: styles['variant-light-primary'],
  secondary: styles['variant-light-secondary'],
  warning: styles['variant-light-warning'],
  success: styles['variant-light-success'],
  danger: styles['variant-light-danger'],
  info: styles['variant-light-info'],
}

export const compousedVariants: Record<
  TagModelEnum,
  Record<TagVariantEnum, string>
> = {
  fill: variantsFill,
  outline: variantsOutline,
  light: variantsLight,
}

export const generateClasses = (
  size: keyof typeof TagSizeEnum,
  variant: keyof typeof TagVariantEnum,
  model: keyof typeof TagModelEnum,
  className?: string,
) => {
  const variants = compousedVariants[model]

  return `${styles.core} ${sizes[size]} ${variants[variant]} ${className}`
}
