import {
  CalculateUnitPricePayloadProps,
  QuotationProps,
  QuotationStatusEnum,
} from '@/types'

export const accordionsByStatusObject: Record<
  QuotationStatusEnum,
  Array<string>
> = {
  accepted: ['general-information', 'order-data', 'catalog-products'],
  active: ['general-information', 'order-data', 'catalog-products'],
  cancelled: ['general-information', 'order-data', 'catalog-products'],
  expired: ['general-information', 'order-data', 'catalog-products'],
  pending: ['general-information', 'order-data', 'catalog-products'],
  rejected: ['general-information', 'order-data', 'catalog-products'],
  pending_approval: ['general-information', 'order-data', 'catalog-products'],
}

export const getQuotationSuggestedUnitPricePayload = ({
  quotationValues,
}: {
  quotationValues: QuotationProps
}): CalculateUnitPricePayloadProps => {
  return {
    items: quotationValues.products.map((product) => ({
      cost: Number(product.cost) || 0,
      price: Number(product.price) || 0,
      quantity: Number(product.quantity) || 0,
      traderCommission: Number(product.traderCommission) || 0,
      cm3:
        Number(product?.catalogProduct?.category?.expectedCm3ProfitRate) || 0,
    })),
    customCost: Number(quotationValues.customsFreightCost || 0),
    firstMileCost: Number(quotationValues.firstMileFreightCost || 0),
    lastMileCost: Number(quotationValues.lastMileFreightCost || 0),
    discount: Number(quotationValues.discount || 0),
  }
}

export const getQuotationWithOrdenedProducts = (
  quotation?: QuotationProps,
): QuotationProps | undefined => {
  if (!quotation) return

  const orderedItems = quotation?.products?.sort((a, b) => {
    return (
      new Date(a.createdAt || '').getTime() -
      new Date(b.createdAt || '').getTime()
    )
  })

  return {
    ...quotation,
    products: orderedItems,
  }
}
