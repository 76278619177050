import styles from './card-item.module.css'
import { CardItemProps } from './card-item.types'

export const CardItem = ({
  title,
  actions,
  icon,
  children,
  className,
}: CardItemProps) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.header}>
        <div className={styles['title-container']}>
          {icon}
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles['actions-container']}>{actions}</div>
      </div>
      <div>{children}</div>
    </div>
  )
}
