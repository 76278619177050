import {
  QuotationProps,
  SupplierProductProps,
  SupplierWarehouseProps,
} from '@/types'
import styles from './catalog-products.module.css'
import { deepCopy } from '@/helpers'

export const variants: Record<string, string> = {
  isSelected: styles['button-selected'],
  isUnselected: '',
}

export const generateButtonClasses = (
  isSelected: boolean,
  invalid: boolean,
) => {
  return `${invalid && styles.invalid} ${styles.button} ${isSelected ? variants.isSelected : variants.isUnselected}`
}

export const insertSupplierInSupplierProductsData = (
  data: SupplierProductProps[] | undefined,
  quotationValues: Partial<QuotationProps> | undefined,
  selected: number,
) => {
  if (!data || !quotationValues) return []

  const supplierProducts = deepCopy(data || [])

  if (
    quotationValues?.products?.[selected]?.supplier &&
    !quotationValues?.products?.[selected]?.isSupplierWarehouseSelected
  ) {
    return [
      {
        uuid: quotationValues?.products?.[selected]?.supplier?.uuid,
        supplierWarehouse: {
          supplier: quotationValues?.products?.[selected]?.supplier,
          supplierUuid: quotationValues?.products?.[selected]?.supplier?.uuid,
        } as SupplierWarehouseProps,
      },
      ...supplierProducts,
    ]
  }

  return supplierProducts
}
