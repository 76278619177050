import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  TableColumns,
  Text,
  Select,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  PlusIcon,
  TrashIcon,
  InputSearch,
  ArrowRightIcon,
  CheckIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import {
  useParamsState,
  useCookieState,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
  trackEvent,
} from '@/helpers'

import { useAIAgentList, useAIDeleteAgent } from '@/services'
import { IAAgentProps } from '@/types'

import { AgentDrawerForm } from '../components'

import styles from './list.module.css'

export const AgentsList = () => {
  const { t } = useTranslation('screenAiCenter')

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/agents-list-columns',
    ['name', 'active', 'type', 'role', 'context', 'createdAt', 'actions'],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading, refetch } = useAIAgentList({ params: filter })

  const { mutateAsync: mutateDelete, isPending } = useAIDeleteAgent()

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminAiCenterAgentsListPageView')
  }, [])

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const onHandleDeleteAgent = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('agentTableDeleteConfirmTitle'),
      t('agentTableDeleteConfirmMessage'),
      t('agentTableDeleteConfirmButtonDelete'),
      t('agentTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('agentTableDeleteSuccessTitle'),
          t('agentTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('agentTableDeleteErrorTitle'),
          t('agentTableDeleteErrorMessage'),
        )
      }
    }
  }

  const columns: TableColumnsColumnProps<IAAgentProps>[] = [
    {
      title: t('agentTableName'),
      key: 'name',
      dataKey: 'name',
    },
    {
      title: t('agentTableActive'),
      key: 'active',
      dataKey: 'active',
      render: ({ record }) => (
        <CheckIcon
          className={
            record?.active ? styles['active-icon'] : styles['inactive-icon']
          }
        />
      ),
    },
    {
      title: t('agentTableType'),
      key: 'type',
      dataKey: 'type',
    },
    {
      title: t('agentTableRole'),
      key: 'role',
      dataKey: 'role',
    },
    {
      title: t('agentTablePrompts'),
      key: 'context',
      dataKey: 'context',
      render: ({ value }) => `${value?.length} prompts`,
    },
    {
      title: t('agentTableActions'),
      key: 'actions',
      dataKey: 'uuid',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            variant="primary"
            onClick={() => onHandleEditRegister(record.uuid || '')}
            model="square"
          >
            <ArrowRightIcon />
          </Button>

          <Button
            variant="danger"
            onClick={() => onHandleDeleteAgent(record.uuid || '')}
            model="square"
          >
            <TrashIcon />
          </Button>
        </div>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <>
      <div>
        <div className={styles.filter}>
          <div className={styles['filter-group']}>
            <InputSearch
              value={filter.search as string}
              onChange={(value) => handleFilter('search', value)}
              className={styles['search-input']}
              translations={{
                inputPlaceholder: t('searchFilterPlaceholder'),
              }}
            />
          </div>

          <div className={styles['filter-group']}>
            <Button onClick={onHandleNewRegister}>
              <PlusIcon />
              {t('agentTableNewRegister')}
            </Button>

            <DropDown.Root>
              <DropDown.ButtonTrigger
                variant="secondary"
                model="square"
                hasArrow={false}
              >
                <SettingIcon />
              </DropDown.ButtonTrigger>

              <DropDown.Content orientation="bottom-right">
                {columns?.map((column) => (
                  <DropDown.Item key={column.dataKey}>
                    <Checkbox
                      onChange={() => onHandleColumnsShow(column.key)}
                      value={getColumnsShow(column.key)}
                    >
                      {column.title}
                    </Checkbox>
                  </DropDown.Item>
                ))}
              </DropDown.Content>
            </DropDown.Root>
          </div>
        </div>

        <TableColumns<IAAgentProps>
          isLoading={isLoading || isPending}
          columns={memoizedColumns}
          data={data?.data?.results || []}
        />

        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('agentTableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', `${page}`)}
            currentPage={+filter.page as number}
          />
        </div>
      </div>

      <AgentDrawerForm
        isOpen={!!queryParams.form}
        onHandleOpen={() =>
          setQueryParams({ ...queryParams, form: '', uuid: '' })
        }
        agentUuid={queryParams.uuid}
      />
    </>
  )
}
