import { AddressProps, ProfileProps, QuotationProductProps } from '@/types'
import * as Yup from 'yup'

export const schemaQuotation = (t: (key: string) => string) => ({
  status: Yup.string().required(t('requiredMessage')),
  rejectedReason: Yup.string().when('status', (status, schema) => {
    if (String(status) === 'rejected') {
      return schema.required(t('requiredMessage'))
    }

    return schema
  }),
  deliveryAddressUuid: Yup.string().required(t('requiredMessage')),
  buyerProfileUuid: Yup.string().required(t('requiredMessage')),
  paymentMethodOption: Yup.string().required(t('requiredMessage')),
  paymentFormOption: Yup.string().required(t('requiredMessage')),
  deliveryOption: Yup.string().required(t('requiredMessage')),
  dollarExchangeRate: Yup.string().required(t('requiredMessage')),
  expiresAt: Yup.string().required(t('requiredMessage')),
  expectedDeliveryDays: Yup.string().required(t('requiredMessage')),
  firstMileFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  lastMileFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  customsFreightCost: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  financingCost: Yup.string().when(
    'purchaseOnCredit',
    (purchaseOnCredit, schema) => {
      if (purchaseOnCredit[0]) {
        return schema
          .required(t('requiredMessage'))
          .min(0, t('requiredMessage'))
      }

      return schema.nullable()
    },
  ),
  totalFreightPrice: Yup.string()
    .required(t('requiredMessage'))
    .min(0, t('requiredMessage')),
  products: Yup.array().of(
    Yup.object().shape({
      price: Yup.mixed().required(t('requiredMessage')),
      cost: Yup.mixed().required(t('requiredMessage')),
      quantity: Yup.mixed().required(t('requiredMessage')),
      package: Yup.mixed().required(t('requiredMessage')),
    }),
  ),
})

export const checkIsCompleteGeneralInformation = ({
  address,
  buyerProfile,
  paymentFormOption,
  paymentMethodOption,
}: {
  buyerProfile?: Partial<ProfileProps>
  address?: Partial<AddressProps>
  paymentFormOption?: string
  paymentMethodOption?: string
}) => {
  return (
    !!address?.uuid &&
    !!buyerProfile?.uuid &&
    !!paymentFormOption &&
    !!paymentMethodOption
  )
}

export const checkIsCompleteOrderData = ({
  customsFreightCost,
  expiresAt,
  expectedDeliveryDays,
  financingCost,
  firstMileFreightCost,
  lastMileFreightCost,
  purchaseOnCredit,
  totalFreightPrice,
  deliveryOption,
}: {
  expiresAt?: string
  expectedDeliveryDays?: number
  totalFreightPrice?: number
  firstMileFreightCost?: number
  lastMileFreightCost?: number
  customsFreightCost?: number
  purchaseOnCredit?: boolean
  financingCost?: number
  deliveryOption?: string
  logisticTransportCompanyUuid?: string
}) => {
  return (
    (!!expectedDeliveryDays || expectedDeliveryDays === 0) &&
    (!!totalFreightPrice || totalFreightPrice === 0) &&
    (!!firstMileFreightCost || firstMileFreightCost === 0) &&
    (!!lastMileFreightCost || lastMileFreightCost === 0) &&
    (!!customsFreightCost || customsFreightCost === 0) &&
    (!!expectedDeliveryDays || expectedDeliveryDays === 0) &&
    !!expiresAt &&
    !!deliveryOption &&
    !!(!purchaseOnCredit ? true : !!financingCost || financingCost === 0)
  )
}

export const checkIsCatalogProductsPrices = (
  values: QuotationProductProps[] = [],
) => {
  const productsValidation = values.map(
    (value) =>
      (!!value.quantity || value.quantity === 0) &&
      (!!value.cost || value.cost === 0) &&
      (!!value.price || value.price === 0) &&
      !!value.supplierUuid &&
      !!value.package,
  )
  return {
    productsIsQuoted: productsValidation.map(
      (value, i) => value && !!values[i].catalogProductUuid,
    ),
    isComplete: productsValidation.filter((value) => !value).length === 0,
  }
}

export const checkIsCompleteQuotation = ({
  generalInformation,
  orderData,
  catalogProductsPrices,
}: {
  generalInformation: boolean
  orderData: boolean
  catalogProductsPrices: boolean
}) => {
  return !!generalInformation && !!orderData && !!catalogProductsPrices
}
