export enum TagVariantEnum {
  basic = 'basic',
  primary = 'primary',
  secondary = 'secondary',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info',
}

export enum TagSizeEnum {
  small = 'small',
  medium = 'medium',
}

export enum TagModelEnum {
  fill = 'fill',
  outline = 'outline',
  light = 'light',
}

export interface TagProps {
  children: React.ReactNode
  className?: string
  variant?: keyof typeof TagVariantEnum
  size?: keyof typeof TagSizeEnum
  isActive?: boolean
  model?: keyof typeof TagModelEnum
}
