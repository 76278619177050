import styles from './multi-select.module.css'

import { MultiSelectSizeEnum } from './multi-select.type'

export const sizes: Record<MultiSelectSizeEnum, string> = {
  small: styles['size-small'],
  medium: styles['size-medium'],
  large: styles['size-large'],
}

export const generateClasses = (
  isEmpty: boolean,
  size: keyof typeof MultiSelectSizeEnum,
  error?: boolean,
) => {
  return `${styles.core} ${sizes[size]} ${!error || styles['state-danger']} ${isEmpty ? styles.placeholder : ''}`
}
