import { useTranslation } from 'react-i18next'

import { Text } from '@mercai/clever'

import { formatCurrency, formatLongDate } from '@/helpers'

import type { OrderCenterExpandedRowProps } from './rows.type'

import styles from './rows.module.css'

export const OrderCenterExpandedRowExpectedDeliveryDate = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">
        {t('expandedColumnExpectedDeliveryDays')}
      </Text>
      <Text>
        {record.shipments?.[0]?.expectedDeliveryDate
          ? formatLongDate(record.shipments?.[0]?.expectedDeliveryDate)
          : `${record.shipments?.[0]?.expectedDeliveryDays} ${t('expandedColumnExpectedDeliveryDaysValue')}`}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowDeliveryAt = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnDeliveryAt')}</Text>
      <Text>
        {record.shipments?.[0]?.deliveredAt
          ? formatLongDate(record.shipments?.[0]?.deliveredAt)
          : `-`}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowOrigin = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnOrigin')}</Text>
      <Text>{record?.shipments?.[0]?.originAddress || '-'}</Text>
    </div>
  )
}

export const OrderCenterExpandedRowDestination = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnDestination')}</Text>
      <Text>{record?.shipments?.[0]?.destinationAddress || '-'}</Text>
    </div>
  )
}

export const OrderCenterExpandedRowTransportPrice = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnTransportPrice')}</Text>
      <Text>{formatCurrency(record?.totalFreightPrice, 'es-MX', 'USD')}</Text>
    </div>
  )
}

export const OrderCenterExpandedRowAmount = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnAmount')}</Text>
      <Text>{formatCurrency(record?.totalOrderPrice, 'es-MX', 'USD')}</Text>
    </div>
  )
}

export const OrderCenterExpandedRowKam = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnKAM')}</Text>
      <Text>
        {typeof record?.keyAccountManager === 'string'
          ? record?.keyAccountManager
          : record?.keyAccountManager?.name}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowPaymentMethod = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnPaymentMethod')}</Text>
      <Text>
        {record?.payments?.[0]?.method
          ? tEnums(`transaction_payment_method_${record.payments?.[0]?.method}`)
          : '-'}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowPaymentCondition = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')
  const { t: tEnums } = useTranslation('enums')

  const percentage =
    (+(record?.payments?.[0]?.dollarValue || 0) /
      +(record?.totalOrderPrice || 0)) *
    100

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnPaymentCondition')}</Text>
      <Text>
        {tEnums(
          `financial_profile_payment_forms_${record?.payments?.[0]?.form}`,
        )}

        {record?.payments?.[0]?.form === 'partial'
          ? ` (${percentage.toFixed(2)}%) (${(100 - percentage).toFixed(2)}%)`
          : ''}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowSubtotal = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">
        {t('expandedColumnFinancePaymentSubtotal')}
      </Text>
      <Text>
        {formatCurrency(
          +(record?.totalProductPrice || 0) *
            +(record?.payments?.[0]?.currency === 'USD'
              ? 1
              : record?.payments?.[0]?.dollarExchangeRate || 0),
          'es-MX',
          record?.payments?.[0]?.currency,
        )}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowTotalFreight = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnPaymentTotalFreight')}</Text>
      <Text>
        {formatCurrency(
          +(record?.totalFreightPrice || 0) *
            +(record?.payments?.[0]?.currency === 'USD'
              ? 1
              : record?.payments?.[0]?.dollarExchangeRate || 0),
          'es-MX',
          record?.payments?.[0]?.currency,
        )}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowTaxPrice = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">
        {t('expandedColumnFinancePaymentTaxPrice')}
      </Text>
      <Text>
        {' '}
        {formatCurrency(
          +(record?.taxPrice || 0) *
            +(record?.payments?.[0]?.currency === 'USD'
              ? 1
              : record?.payments?.[0]?.dollarExchangeRate || 0),
          'es-MX',
          record?.payments?.[0]?.currency,
        )}
      </Text>
    </div>
  )
}

export const OrderCenterExpandedRowInvoiceId = ({
  record,
}: OrderCenterExpandedRowProps) => {
  const { t } = useTranslation('screenOrderCenterPage')

  return (
    <div className={styles.row}>
      <Text variant="title-base">{t('expandedColumnFinanceInvoiceId')}</Text>
      <Text> {record?.invoices?.[0]?.invoiceId || `-`}</Text>
    </div>
  )
}
