import { SVGProps } from 'react'

export const AlertHighlightIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M15.3333 7.99677L13.7066 6.14344L13.9333 3.6901L11.5266 3.14344L10.2666 1.02344L7.99996 1.99677L5.73329 1.02344L4.47329 3.14344L2.06663 3.68344L2.29329 6.13677L0.666626 7.99677L2.29329 9.8501L2.06663 12.3101L4.47329 12.8568L5.73329 14.9768L7.99996 13.9968L10.2666 14.9701L11.5266 12.8501L13.9333 12.3034L13.7066 9.8501L15.3333 7.99677ZM12.3266 9.40344L12.5 11.2634L10.6733 11.6768L9.71996 13.2834L7.99996 12.5434L6.27996 13.2834L5.32663 11.6768L3.49996 11.2634L3.67329 9.39677L2.43996 7.99677L3.67329 6.58344L3.49996 4.7301L5.32663 4.32344L6.27996 2.71677L7.99996 3.4501L9.71996 2.7101L10.6733 4.31677L12.5 4.7301L12.3266 6.5901L13.56 7.99677L12.3266 9.40344ZM7.33329 9.99677H8.66663V11.3301H7.33329V9.99677ZM7.33329 4.66344H8.66663V8.66344H7.33329V4.66344Z" />
    </svg>
  )
}
