import { ReactNode } from 'react'

export enum MultiSelectSizeEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface MultiSelectOptionProps {
  value: string
  label: string
}

export interface MultiSelectProps {
  id?: string
  className?: string
  disabled?: boolean
  size?: keyof typeof MultiSelectSizeEnum
  onChange?: (value: string[]) => void
  values?: string[]
  placeholder?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  options: MultiSelectOptionProps[]
  error?: string
}
