import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { TrashIcon } from '@mercai/clever'
import { marked } from 'marked'

import { onConfirmDangerMessage, isSuperUser } from '@/helpers'
import { QUERIES_ADMIN, useAIRemoveMessage } from '@/services'
import { useAuth } from '@/providers'

import styles from './message.module.css'

import type { MessageProps } from './message.type'

export const Message = ({
  uuid,
  author,
  content,
  context,
  isLoading,
}: MessageProps) => {
  const [html, setHtml] = useState('')
  const queryClient = useQueryClient()
  const { mutate: removeMessage } = useAIRemoveMessage()

  const { whoAmI } = useAuth()

  const onHandleClick = async () => {
    const isConfirmed = await onConfirmDangerMessage(
      'Eliminar mensaje',
      '¿Estás seguro de querer eliminar este mensaje?',
      'Eliminar',
      'Cancelar',
    )

    if (isConfirmed) {
      removeMessage(uuid || '')
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.AI_CONVERSATION.module],
      })
    }
  }

  useEffect(() => {
    const parseMarkdown = async () => {
      const parsedHtml = await marked(content || '')
      setHtml(parsedHtml)
    }

    parseMarkdown()
  }, [content])

  return (
    <div className={`${styles.message} ${styles[author ?? 'assistant']}`}>
      {isLoading ? (
        <div className={styles.loading}>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
          <div className={styles.dot}></div>
        </div>
      ) : (
        <React.Fragment>
          <div className={styles.context}>{context || 'N/D'}</div>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: html }}
          />

          {isSuperUser(whoAmI?.email) && (
            <button
              className={`${styles.delete} ${styles[author ?? 'assistant']}`}
              onClick={onHandleClick}
            >
              <TrashIcon />
            </button>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
