import { CalculateMarginPayloadProps, QuotationProps } from '@/types'

export const getQuotationMetricsPayload = ({
  quotationValues,
}: {
  quotationValues: QuotationProps
}): CalculateMarginPayloadProps => {
  return {
    items: quotationValues.products.map((product) => ({
      cost: Number(product.cost) || 0,
      price: Number(product.price) || 0,
      quantity: Number(product.quantity) || 0,
      traderCommission: Number(product.traderCommission) || 0,
    })),
    customsFreightCost: quotationValues.customsFreightCost,
    deliveryOption: quotationValues.deliveryOption,
    discount: Number(quotationValues.discount || 0),
    financingCost: Number(quotationValues.financingCost) || undefined,
    firstMileFreightCost: Number(quotationValues.firstMileFreightCost || 0),
    includeIva: quotationValues?.includeIva || false,
    lastMileFreightCost: Number(quotationValues.lastMileFreightCost || 0),
    totalFreightPrice: Number(quotationValues.totalFreightPrice || 0),
  }
}
