import type { CardProps } from './card.type'

import { generateClasses } from './card.constants'

const Card = ({
  children,
  className,
  variant = 'base',
  onClick,
}: CardProps) => {
  const generatedClasses = generateClasses(variant, className)

  return (
    <section className={generatedClasses} onClick={onClick}>
      {children}
    </section>
  )
}

export default Card
