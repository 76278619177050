import { Button, Text } from '@mercai/clever'

import styles from './empty-state.module.css'

import type { EmptyStateProps } from './empty-state.type'

export const EmptyState = ({
  className,
  description,
  title,
  onClickButton,
  buttonText,
}: EmptyStateProps) => {
  return (
    <div className={`${styles.core} ${className || ''}`}>
      <img
        width={290}
        height={140}
        alt="Empty state"
        src="/images/empty-state.svg"
        className={styles.image}
      />

      <Text variant="title-large" className={styles.title}>
        {title}
      </Text>
      <Text className={styles['sub-title']}>{description}</Text>

      {onClickButton && (
        <Button variant="primary" onClick={onClickButton}>
          {buttonText}
        </Button>
      )}
    </div>
  )
}
