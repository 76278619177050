import { useEffect, useMemo } from 'react'
import { useCalculateMargin } from '@/services'
import {
  AlertIcon,
  Card,
  InfoIcon,
  Text,
  WarningOutlineIcon,
  Tooltip,
} from '@mercai/clever'

import { QuotationMetricsProps } from './quotation-metrics.types'

import styles from './quotation-metrics.module.css'
import { useTranslation, Trans } from 'react-i18next'
import {
  generateCm3MarginClasses,
  generateGrossMarginClasses,
  generateIconClasses,
} from './quotation-metrics.constants'

export const QuotationMetrics = ({ values }: QuotationMetricsProps) => {
  const { data, mutateAsync } = useCalculateMargin()

  const incomplete = useMemo(() => !data?.success, [data])

  const { t } = useTranslation('screenBusinessV2')

  const Icon = () => {
    return incomplete ? (
      <div
        className={`${styles.icon} ${styles.iconIncomplete} ${styles['icon--info']}`}
      >
        <AlertIcon />
      </div>
    ) : (
      <div
        className={generateIconClasses(
          data?.data || { cm3Margin: 0, grossMargin: 0 },
        )}
      >
        {(data?.data?.grossMargin || 0) >= 0.07 &&
        (data?.data?.cm3Margin || 0) >= 0.02 ? (
          <AlertIcon />
        ) : (
          <WarningOutlineIcon />
        )}
      </div>
    )
  }

  useEffect(() => {
    if (values && Object.keys(values).length > 0) mutateAsync(values)
  }, [values])

  return (
    <Card className={styles.core}>
      <Icon />
      <div>
        <Text className={styles.title} variant="title-base">
          <strong className={styles.strong}>{t('projectedUtilities')} </strong>
          <Text>
            <Trans
              t={t}
              i18nKey="grossMarginLabel"
              values={{
                value: incomplete
                  ? '-'
                  : ((data?.data?.grossMargin || 0) * 100).toFixed(2),
              }}
              components={{
                strong: <strong className={styles.strong} />,
                span: (
                  <span
                    className={generateGrossMarginClasses(
                      data?.data?.grossMargin || 0,
                    )}
                  />
                ),
              }}
            />
          </Text>
          <Text>
            <Trans
              t={t}
              i18nKey="cm3Label"
              values={{
                value: incomplete
                  ? '-'
                  : ((data?.data?.cm3Margin || 0) * 100).toFixed(2),
              }}
              components={{
                strong: <strong className={styles.strong} />,
                span: (
                  <span
                    className={generateCm3MarginClasses(
                      data?.data?.cm3Margin || 0,
                    )}
                  />
                ),
              }}
            />
          </Text>
        </Text>
      </div>
      {incomplete && (
        <div className={styles.incomplete}>
          <Tooltip
            text={t('metricIncompleteLabel')}
            widthText="275px"
            orientation="left"
          >
            <InfoIcon />
          </Tooltip>
        </div>
      )}
    </Card>
  )
}
