import { Alert, AlertVariantEnum, AlertIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { QuotationSummaryStatusProps } from './summary-status.type'

export const QuotationSummaryStatus = ({
  status,
  isLoading,
}: QuotationSummaryStatusProps) => {
  const { t } = useTranslation('screenQuotationList')

  if (isLoading) {
    return <Skeleton height="7.5rem" />
  }

  const statusObjects = {
    pending: {
      variant: 'warning',
      title: t('quotationShowSummaryStatusPendingTitle'),
      description: t('quotationShowSummaryStatusPendingDescription'),
    },
    active: {
      variant: 'primary',
      title: t('quotationShowSummaryStatusActiveTitle'),
      description: t('quotationShowSummaryStatusActiveDescription'),
    },
    accepted: {
      variant: 'success',
      title: t('quotationShowSummaryStatusAcceptedTitle'),
      description: t('quotationShowSummaryStatusAcceptedDescription'),
    },
    pending_approval: {
      variant: 'warning',
      title: t('quotationShowSummaryStatusPendingApprovalTitle'),
      description: t('quotationShowSummaryStatusPendingApprovalDescription'),
    },
    expired: {
      variant: 'danger',
      title: t('quotationShowSummaryStatusExpiredTitle'),
      description: t('quotationShowSummaryStatusExpiredDescription'),
    },
    rejected: {
      variant: 'danger',
      title: t('quotationShowSummaryStatusRejectedTitle'),
      description: t('quotationShowSummaryStatusRejectedDescription'),
    },
    cancelled: {
      variant: 'danger',
      title: t('quotationShowSummaryStatusCancelledTitle'),
      description: t('quotationShowSummaryStatusCancelledDescription'),
    },
  }

  const statusObject = statusObjects[status]

  return (
    <Alert
      leftIcon={<AlertIcon />}
      variant={statusObject.variant as AlertVariantEnum}
      title={statusObject.title}
    >
      {statusObject.description}
    </Alert>
  )
}
