import { useEffect, useState } from 'react'
import { ProfileProps } from '@/types'
import {
  onErrorMessage,
  onSuccessMessage,
  useValidationErrors,
} from '@/helpers'

import { useTranslation } from 'react-i18next'

import {
  Button,
  DrawerStepperProgress,
  Input,
  InputPhone,
  Label,
  Skeleton,
  Text,
} from '@mercai/clever'

import { ROUTES_ADMIN } from '@/router'
import { useProfileDetail, useUpdateProfile } from '@/services'

import { buyerSchema } from './buyer-drawer-form.constants'
import { BuyerDrawerFormProps } from './buyer-drawer-form.type'

import styles from './buyer-drawer-form.module.css'

export const BuyerDrawerForm = ({
  uuid,
  isOpen,
  onHandleOpen,
  setBuyerProfile,
}: BuyerDrawerFormProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const [values, setValues] = useState<Partial<ProfileProps>>()

  const { data, isLoading } = useProfileDetail(!isOpen ? undefined : uuid)

  const { errors, isValid, onHandleListenErrors } = useValidationErrors(
    buyerSchema(t),
    values,
  )

  const { mutateAsync: updateProfile, isPending } = useUpdateProfile()

  const onHandleSubmit = async () => {
    if (!isValid) {
      onHandleListenErrors(true)
      return
    }

    onHandleListenErrors(false)

    const result = await updateProfile(values as Partial<ProfileProps>)

    if (result.success) {
      onSuccessMessage(
        t('buyerDrawerFormSuccessTitle'),
        t('buyerDrawerFormSuccessMessage'),
      )

      setBuyerProfile(result?.data as ProfileProps)

      onHandleOpen()
    } else {
      onErrorMessage(
        t('buyerDrawerFormErrorTitle'),
        t('buyerDrawerFormErrorMessage'),
      )
    }
  }

  const onChangeValues = (key: string, newValue: string) => {
    setValues({ ...values, [key]: newValue } as ProfileProps)
  }

  const Loading = () => (
    <>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
      <div>
        <Label>
          <Skeleton height="2rem" width="40%" />
        </Label>
        <Skeleton height="2rem" width="100%" />
      </div>
    </>
  )

  useEffect(() => {
    setValues({
      uuid,
      companyName: data?.data?.companyName,
      email: data?.data?.email,
      phone: data?.data?.phone,
    })
  }, [data])

  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      initialStep="root"
      translations={{ optional: '' }}
      title={t('buyerDrawerFormTitle')}
      description={t('buyerDrawerFormDescription')}
      steps={{
        root: {
          isOptional: false,
          backNavigate: '',
          component: () => (
            <div className={styles.core}>
              <div className={styles['core-header']}>
                <Text variant="text-large-bold">
                  {t('buyerDrawerFormInformationTitle')}
                </Text>
                <Button
                  variant="secondary"
                  target="_blank"
                  href={`/${ROUTES_ADMIN.PROFILE.path}/${uuid}`}
                >
                  {t('buyerDrawerFormProfileButton')}
                </Button>
              </div>
              <div className={styles['core-content']}>
                {!isLoading ? (
                  <>
                    <div>
                      <Label>{t('buyerDrawerFormCompanyNameLabel')}</Label>
                      <Input
                        placeholder={t('buyerDrawerFormCompanyNamePlaceholder')}
                        value={values?.companyName}
                        onChange={(value) =>
                          onChangeValues('companyName', value)
                        }
                        error={
                          errors?.companyName
                            ? (errors?.companyName as string)
                            : ''
                        }
                      />
                    </div>
                    <div>
                      <Label>{t('buyerDrawerFormEmailLabel')}</Label>
                      <Input
                        placeholder={t('buyerDrawerFormEmailPlaceholder')}
                        value={values?.email}
                        onChange={(value) => onChangeValues('email', value)}
                        error={errors?.email ? (errors?.email as string) : ''}
                      />
                    </div>
                    <div>
                      <Label>{t('buyerDrawerFormPhoneLabel')}</Label>
                      <InputPhone
                        placeholder={t('buyerDrawerFormPhonePlaceholder')}
                        value={values?.phone}
                        onChange={(value) => onChangeValues('phone', value)}
                        error={errors?.phone ? (errors?.phone as string) : ''}
                      />
                    </div>
                  </>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={onHandleOpen}>
                {t('drawerFormBack')}
              </Button>
              <Button
                onClick={onHandleSubmit}
                disabled={isPending}
                isActive={!isPending}
              >
                {t('drawerFormSubmit')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
