import { InputNumber, Label, Select, Text } from '@mercai/clever'

import { SupplierProductFormProps } from './supplier-product-form.types'
import { useTranslation } from 'react-i18next'

import styles from './supplier-product-form.module.css'
import { SupplierProductPackageEnum } from '@/types'
import { useSupplierWarehouseList } from '@/services'
import { useEffect } from 'react'

export const SupplierProductForm = ({
  values,
  productItem,
  errors,
  onChangeValues,
  supplierUuid,
}: SupplierProductFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenSupplierList')

  const product = values?.catalogProduct || productItem

  const onHandleValues = (key: string, val: string | number | boolean) => {
    onChangeValues({ ...values, [key]: val })
  }

  const { data: warehouses } = useSupplierWarehouseList({
    params: { supplier__uuid: supplierUuid },
  })

  useEffect(() => {
    onHandleValues('catalogProductUuid', product?.uuid || '')
  }, [])

  return (
    <div>
      <div className={styles.product}>
        <div>
          <Text variant="text-base">
            <strong>{product?.attributes?.title}</strong>
          </Text>
        </div>
        <div className={styles.row}>
          <Text>
            <strong>{t('newSupplierProductsFormCategoryLabel')}</strong>{' '}
            {product?.category?.name}
          </Text>
        </div>

        {product?.reference && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormReferenceLabel')}</strong>{' '}
              {product.reference}
            </Text>
          </div>
        )}

        {product?.attributes?.fluidity && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormFluidityLabel')}</strong>{' '}
              {product.attributes.fluidity} g/10m
            </Text>
          </div>
        )}

        {product?.attributes?.density && (
          <div className={styles.row}>
            <Text>
              <strong>{t('newSupplierProductsFormDensityLabel')}</strong>{' '}
              {product.attributes.density} g/cm³
            </Text>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Text variant="text-large-bold">
            {t('supplierProductsFormTitle')}
          </Text>
          <Text>{t('supplierProductsFormDescription')}</Text>
        </div>
        <div className={styles['content-form']}>
          <div>
            <Label>{t('supplierProductsFormMinimumDeliveryTimeLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t(
                'supplierProductsFormMinimumDeliveryTimePlaceholder',
              )}
              value={values?.minDeliveryDays}
              onChange={(newValue) =>
                onHandleValues('minDeliveryDays', newValue)
              }
              error={errors?.minDeliveryDays as string}
              suffix={t('supplierProductsFormMinimumDeliveryTimeSuffix')}
              decimalPrecision={0}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormAvailableLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormAvailablePlaceholder')}
              value={values?.quantity}
              onChange={(newValue) => onHandleValues('quantity', newValue)}
              error={errors?.quantity as string}
              suffix=" Kg"
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormPriceLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormPricePlaceholder')}
              value={values?.price}
              onChange={(newValue) => onHandleValues('price', newValue)}
              error={errors?.price as string}
              suffix=" USD"
              decimalPrecision={3}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormMinLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('supplierProductsFormMinPlaceholder')}
              value={values?.minimumPurchaseQuantity}
              onChange={(newValue) =>
                onHandleValues('minimumPurchaseQuantity', newValue)
              }
              error={errors?.minimumPurchaseQuantity as string}
              suffix=" Kg"
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormWarehouseLabel')}</Label>
            <Select
              options={
                warehouses?.data?.results?.map?.((warehouse) => ({
                  label: `${warehouse.name}`,
                  value: warehouse?.uuid || '',
                })) || []
              }
              placeholder={t('supplierProductsFormWarehousePlaceholder')}
              value={values?.supplierWarehouseUuid}
              onChange={(newValue) =>
                onHandleValues('supplierWarehouseUuid', newValue)
              }
              error={errors?.supplierWarehouseUuid as string}
            />
          </div>
          <div>
            <Label>{t('supplierProductsFormPackingLabel')}</Label>
            <Select
              options={Object.keys(SupplierProductPackageEnum).map((key) => ({
                label: tEnums(`supplier_product_package_${key}`),
                value: key,
              }))}
              placeholder={t('supplierProductsFormPackingPlaceholder')}
              value={values?.package}
              onChange={(newValue) => onHandleValues('package', newValue)}
              error={errors?.package as string}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
