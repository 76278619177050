import { Text, Skeleton, FolderIcon } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { SummaryStateHistoryProps } from './summary-state-history.type'

import styles from './summary-state-history.module.css'
import { CardItem } from '../card-item/card-item'
import { formatDate } from '../../show.constants'
import { Link } from 'react-router-dom'
import { ROUTES_ADMIN } from '@/router'

const QuotationSummaryStateHistoryDefault = ({
  createdAt,
  orders,
  quotationRequest,
  creatorEmail,
  keyAccountManager,
}: SummaryStateHistoryProps) => {
  const { t } = useTranslation('screenQuotationV2Show')

  return (
    <CardItem
      icon={<FolderIcon />}
      title={t('quotationShowSummaryStateHistoryTitle')}
    >
      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryStateHistoryCreatedAt')}:
          </Text>

          <Text variant="text-base">
            {createdAt ? formatDate(createdAt) : t('quotationShowSummaryEmpty')}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryStateHistoryRFQ')}:
          </Text>

          {quotationRequest ? (
            <Link
              to={ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(
                quotationRequest.uuid,
              )}
              className={styles.link}
              target="_blank"
            >
              {`#${quotationRequest.reference}`}
            </Link>
          ) : (
            <Text>-</Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryStateHistoryOrders')}:
          </Text>
          {!!orders && orders?.length > 0 ? (
            orders?.map((order) => (
              <Link
                to={`${ROUTES_ADMIN.ANALYTICS.ORDER_TRACKER.fullPath()}?search=${order.reference}`}
                className={styles.link}
                target="_blank"
                key={order?.uuid}
              >
                {`#${order?.reference}`}
              </Link>
            ))
          ) : (
            <Text>-</Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryStateHistoryCreator')}:
          </Text>

          <Text variant="text-base">
            {creatorEmail || t('quotationShowSummaryEmpty')}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryStateHistoryKAM')}:
          </Text>

          <Text variant="text-base">
            {keyAccountManager || t('quotationShowSummaryEmpty')}
          </Text>
        </div>
      </div>
    </CardItem>
  )
}

const QuotationSummaryStateHistoryLoading = () => {
  const { t } = useTranslation('screenQuotationV2Show')
  return (
    <CardItem
      icon={<FolderIcon />}
      title={t('quotationShowSummaryStateHistoryTitle')}
    >
      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="40%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="50%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="30%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="40%" />
        </div>
      </div>
    </CardItem>
  )
}

export const QuotationSummaryStateHistory = (
  props: SummaryStateHistoryProps,
) => {
  if (props.isLoading) {
    return <QuotationSummaryStateHistoryLoading />
  }

  return <QuotationSummaryStateHistoryDefault {...props} />
}
