import { useTranslation } from 'react-i18next'
import { Button, Card, Text, ReceiptIcon, Skeleton } from '@mercai/clever'

import { downloadFile } from '@/helpers'
import { ROUTES_ADMIN } from '@/router'

import type { QuotationRequestShowQuotationProps } from './quotation-list.type'

import styles from './quotation-list.module.css'

export const QuotationRequestShowQuotationList = ({
  file,
  quotations,
  status,
  isLoading,
}: QuotationRequestShowQuotationProps) => {
  const { t } = useTranslation('screenQuotationRequestShow')
  const { t: tEnums } = useTranslation('enums')

  const onHandleDownload = () => {
    downloadFile(`${file}`)
  }

  const showListQuotations = quotations && quotations?.length > 0

  if (isLoading) {
    return (
      <Card className={styles.core}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Skeleton height="1.5rem" width="1.5rem" />

            <Skeleton height="1.5rem" width="10rem" />
          </div>

          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="100%" />
          <Skeleton height="1rem" width="100%" />
        </div>

        <ul className={styles['list-box']}>
          {[0, 1, 2]?.map((quotation) => (
            <li key={quotation} className={styles['list-box-item']}>
              <div className={styles['list-box-item-content']}>
                <Skeleton height="1rem" width="10rem" />
                <Skeleton height="1rem" width="10rem" />
              </div>

              <Skeleton height="2.5rem" width="6rem" />
            </li>
          ))}
        </ul>
      </Card>
    )
  }

  return (
    <Card className={styles.core}>
      <div className={styles.header}>
        <div className={styles.title}>
          <ReceiptIcon />
          <Text variant="title-large">{t('quotationListTitle')}</Text>
        </div>

        <Text>{t('quotationListDescription')}</Text>
      </div>

      {file && (
        <div className={styles['file-box']}>
          <Text variant="title-base">{t('quotationListSendedFile')}</Text>

          <Button variant="secondary" onClick={onHandleDownload}>
            {t('quotationListDownloadFile')}
          </Button>
        </div>
      )}

      {showListQuotations ? (
        <ul className={styles['list-box']}>
          {quotations?.map((quotation) => (
            <li key={quotation.uuid} className={styles['list-box-item']}>
              <div className={styles['list-box-item-content']}>
                <Text variant="title-base">
                  {t('quotationListQuotationLabel', {
                    reference: quotation.reference,
                  })}
                </Text>

                <Text>
                  <strong>{t('quotationListStatusLabel')}: </strong>
                  {tEnums(`quotation_status_${quotation.status}`)}
                </Text>
              </div>

              <Button
                variant="primary"
                href={`${ROUTES_ADMIN.BUSINESS.QUOTATION_OLD.SHOW.fullPath(
                  quotation.uuid,
                )}&search=${quotation.reference}`}
              >
                {t('quotationListLinkLabel')}
              </Button>
            </li>
          ))}
        </ul>
      ) : (
        <div className={styles['empty-box']}>
          <img
            src="/images/quotation-request-empty-quotations.png"
            alt="empty quotations"
          />

          <div className={styles['empty-box-content']}>
            <Text variant="title-base">{t('quotationListEmptyTitle')}</Text>
            <Text>{t('quotationListEmptyDescription')}</Text>
          </div>
        </div>
      )}

      {status === 'pending' && showListQuotations && (
        <Text className={styles['more-quotations']}>
          {t('quotationListMoreQuotation')}
        </Text>
      )}
    </Card>
  )
}
