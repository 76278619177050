import { SupplierProps } from './supplier.type'

export enum LogisticShipmentDeliveryOptionEnum {
  pick_up = 'pick_up',
  receive_at_address = 'receive_at_address',
}

export enum LogisticShipmentStatusEnum {
  'pending' = 'pending',
  'delivery_scheduled' = 'delivery_scheduled',
  'on_hold' = 'on_hold',
  'in_transit' = 'in_transit',
  'partially_delivered' = 'partially_delivered',
  'delivered' = 'delivered',
  'cancelled' = 'cancelled',
  'refused' = 'refused',
}

export enum LogisticShipmentVehicleTypeEnum {
  motorcycle = 'motorcycle',
  sedan = 'sedan',
  pickup_short = 'pickup_short',
  pickup_large = 'pickup_large',
  bobtail = 'bobtail',
  torton_short = 'torton_short',
  torton_large = 'torton_large',
  trailer_short = 'trailer_short',
  trailer_large = 'trailer_large',
}

export interface LogisticShipmentTransportOptionsProps {
  uuid: string
  driver: string
  vehicleType: keyof typeof LogisticShipmentVehicleTypeEnum
  vehicleIdentifier: string
  transportCompany: string
  customerSelectedDeliveryDate: string
  customerSelectedDeliveryPeriod: 'morning' | 'afternoon'
  trackingInfo: string
}

export interface LogisticShipmentProps {
  uuid: string
  reference: string
  order: string
  buyer: string
  transportOptions: LogisticShipmentTransportOptionsProps
  trackingInfo: string
  price: string
  lastMileCost: string
  firstMileCost: string
  customsCost: string
  deliveryOption: keyof typeof LogisticShipmentDeliveryOptionEnum
  receipt: string
  expectedDeliveryDays: number
  expectedDeliveryDate: string
  expectedDeliveryAt: string
  status: keyof typeof LogisticShipmentStatusEnum
  statusHistory: Record<string, string>
  statusReason: string
  deliveredAt: string
  createdAt: string
  updatedAt: string
  supplier: SupplierProps
  supplierUuid: string
  supplierPaidAt: string
  extraCost: string
  extraCostReason: string
  originAddress: string
  destinationAddress: string

  shipmentInvoice: string
  shipmentReceipt: string

  deliveryList: string
  customsCertificate: string
  originCertificate: string
  customsInvoice: string
}
