import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Button } from '@mercai/clever'

import { Header } from '@/components'
import { ROUTES_ADMIN } from '@/router'

import { AgentsList } from './agents'
import { ConversationsList } from './conversations'

import styles from './ai-center.module.css'

export const AiCenter = () => {
  const { t } = useTranslation('screenAiCenter')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const object: Record<string, ReactNode> = {
    agent: <AgentsList />,
    conversation: <ConversationsList />,
  }

  return (
    <div>
      <Header title={t('title')} description={t('description')} />

      <div className={styles.content}>
        <div className={styles.tabs}>
          <Button
            variant="secondary"
            isActive={page === 'agent'}
            model="pill"
            href={ROUTES_ADMIN.AI_CENTER.AGENT.fullPath()}
          >
            {t('buttonAgentTabs')}
          </Button>
          <Button
            variant="secondary"
            isActive={page === 'conversation'}
            model="pill"
            href={ROUTES_ADMIN.AI_CENTER.CONVERSATION.fullPath()}
          >
            {t('buttonConversationTabs')}
          </Button>
        </div>

        {object?.[page as keyof typeof object]}
      </div>
    </div>
  )
}
