import { useMutation, useQuery } from '@tanstack/react-query'

import { IAAgentProps, IAAgentListWithPagesProps } from '@/types'

import { QUERIES_ADMIN } from './api.constants'
import { api } from './api.service'
import { AI_AGENTS_URL, AI_AGENT_URL } from './api.urls'

export const useAIAgentList = ({
  params,
}: {
  params?: Record<string, string | number>
}) => {
  const getAgentList = async (): Promise<{
    success: boolean
    message: string
    data?: IAAgentListWithPagesProps
  }> => {
    try {
      const copyParams = { ...params }

      const offset = copyParams?.page
        ? (+(copyParams?.page || 0) - 1) * +copyParams.limit
        : 0

      delete copyParams.page

      const formattedParams = Object.keys(copyParams).reduce((acc, key) => {
        if (copyParams?.[key]) {
          return {
            ...acc,
            [key]: copyParams?.[key],
          }
        }

        return acc
      }, {})

      const { data } = await api.get(AI_AGENTS_URL, {
        params: {
          ...(formattedParams as Record<string, string>),
          offset,
        },
      })

      return {
        success: true,
        message: 'Agents fetched successfully!',
        data: data as IAAgentListWithPagesProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get suppliers!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.AI_AGENT.module, params],
    queryFn: () => getAgentList(),
  })
}

export const useAIAgentDetail = (uuid?: string) => {
  const getAgentDetail = async (): Promise<{
    success: boolean
    message: string
    data?: IAAgentProps
  }> => {
    try {
      const { data } = await api.get(AI_AGENT_URL(uuid))

      return {
        success: true,
        message: 'Agent fetched successfully!',
        data: data as IAAgentProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to get supplier!',
        data: undefined,
      }
    }
  }

  return useQuery({
    queryKey: [QUERIES_ADMIN.AI_AGENT.module, uuid],
    queryFn: () => getAgentDetail(),
    enabled: !!uuid,
  })
}

export const useAICreateAgent = () => {
  const createAgent = async (
    payload: Partial<IAAgentProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: IAAgentProps
  }> => {
    try {
      const { data } = await api.post(AI_AGENTS_URL, payload)

      return {
        success: true,
        message: 'Agent created successfully!',
        data: data as IAAgentProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to create supplier!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_AGENT.module],
    mutationFn: (payload: Partial<IAAgentProps>) => createAgent(payload),
  })
}

export const useAIUpdateAgent = () => {
  const updateAgent = async (
    payload: Partial<IAAgentProps>,
  ): Promise<{
    success: boolean
    message: string
    data?: IAAgentProps
  }> => {
    try {
      const { data } = await api.patch(AI_AGENT_URL(payload.uuid), payload)

      return {
        success: true,
        message: 'Agent updated successfully!',
        data: data as IAAgentProps,
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to update supplier!',
        data: undefined,
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_AGENT.module],
    mutationFn: (payload: Partial<IAAgentProps>) => updateAgent(payload),
  })
}

export const useAIDeleteAgent = () => {
  const deleteAgent = async (
    uuid: string,
  ): Promise<{
    success: boolean
    message: string
  }> => {
    try {
      await api.delete(AI_AGENT_URL(uuid))

      return {
        success: true,
        message: 'Agent deleted successfully!',
      }
    } catch (err) {
      return {
        success: false,
        message: 'Failed to delete supplier!',
      }
    }
  }

  return useMutation({
    mutationKey: [QUERIES_ADMIN.AI_AGENT.module],
    mutationFn: (uuid: string) => deleteAgent(uuid),
  })
}
