import { useTranslation } from 'react-i18next'
import { Button, CalendarIcon, Skeleton, Text } from '@mercai/clever'
import { QuotationMetrics, QuotationStatusSelect, Tabs } from '../../components'
import { useQuotationDetail } from '@/services'
import { Link, useLocation, useParams } from 'react-router-dom'

import styles from './show.module.css'
import { ROUTES_ADMIN } from '@/router'
import { QuotationSummaryProducts } from './components/summary-products'
import { QuotationSummaryProfile } from './components/summary-profile'
import { QuotationSummaryDelivery } from './components/summary-delivery'
import { calculateQuotationValues, trackEvent, useParamsState } from '@/helpers'
import { ReactNode, useEffect, useMemo } from 'react'
import { getQuotationMetricsPayload } from '../../helpers'
import { CalculateMarginPayloadProps, QuotationProps } from '@/types'
import { CardItem } from './components/card-item/card-item'
import { formatDate } from './show.constants'
import { QuotationSummaryValues } from './components/summary-values'
import { QuotationSummaryStateHistory } from './components/summary-state-history'

export const QuotationV2Show = () => {
  const { t } = useTranslation('screenQuotationV2Show')

  const { uuid } = useParams()

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const [queryParams, setQueryParams] = useParamsState({
    tab: 'all',
  })

  const handleParams = (changedKey: string, newValue: string | number) => {
    setQueryParams({ ...queryParams, [changedKey]: `${newValue}` })
  }

  const { data: dataQuotation, isLoading } = useQuotationDetail(uuid || '')

  const dataForMetrics = useMemo(() => {
    if (dataQuotation?.data) {
      return getQuotationMetricsPayload({
        quotationValues: dataQuotation?.data as QuotationProps,
      })
    }
    return {}
  }, [dataQuotation])

  const { discount, iva, subTotal, total } = calculateQuotationValues(
    dataQuotation?.data?.products || [],
    dataQuotation?.data?.deliveryOption === 'pick_up'
      ? 0
      : +(dataQuotation?.data?.totalFreightPrice || 0),
    +(dataQuotation?.data?.discount || 0),
    dataQuotation?.data?.includeIva,
  )

  const tabsContent: Record<string, ReactNode> = {
    all: (
      <div className={styles['all-container']}>
        <div>
          <CardItem
            title={t('quotationExpiresAtTitle')}
            icon={<CalendarIcon />}
          >
            {!isLoading ? (
              <Text>{formatDate(dataQuotation?.data?.expiresAt || '')}</Text>
            ) : (
              <Skeleton height="1.2rem" width="10rem" />
            )}
          </CardItem>

          <QuotationSummaryStateHistory
            isLoading={isLoading}
            createdAt={dataQuotation?.data?.createdAt}
            quotationRequest={dataQuotation?.data?.quotationRequest}
            orders={dataQuotation?.data?.orders}
            creatorEmail={dataQuotation?.data?.creatorEmail}
            keyAccountManager={
              dataQuotation?.data?.buyerProfile
                ?.keyAccountManager as unknown as string
            }
          />
        </div>
        <div>
          <QuotationSummaryProfile
            uuid={dataQuotation?.data?.buyerProfile?.uuid || ''}
            companyName={dataQuotation?.data?.buyerProfile?.companyName || ''}
            email={dataQuotation?.data?.buyerProfile?.email || ''}
            name={
              dataQuotation?.data?.buyerProfile?.usersNames
                ? dataQuotation?.data?.buyerProfile?.usersNames.join(', ')
                : '-'
            }
            phone={dataQuotation?.data?.buyerProfile?.phone || ''}
            moscow={
              dataQuotation?.data?.buyerProfile?.moscowClassification || ''
            }
            customerLevel={
              (dataQuotation?.data?.buyerProfile
                ?.customerLevel as unknown as string) || ''
            }
            isLoading={isLoading}
          />
          <QuotationSummaryValues
            isLoading={isLoading}
            discount={discount}
            iva={iva}
            subTotal={subTotal}
            total={total}
            totalFreight={
              dataQuotation?.data?.deliveryOption === 'pick_up'
                ? 0
                : +(dataQuotation?.data?.totalFreightPrice || 0)
            }
            paymentForm={dataQuotation?.data?.paymentFormOption || ''}
          />
        </div>
      </div>
    ),
    products: (
      <>
        <QuotationSummaryProducts
          isLoading={isLoading}
          products={dataQuotation?.data?.products || []}
          dollarQuotation={dataQuotation?.data?.dollarExchangeRate || 0}
        />
      </>
    ),
    logistic: (
      <>
        <QuotationSummaryDelivery
          deliveryAddress={dataQuotation?.data?.deliveryAddress}
          delivery={dataQuotation?.data?.deliveryOption || ''}
          dollarQuotation={dataQuotation?.data?.dollarExchangeRate || 0}
          totalFreightPrice={dataQuotation?.data?.totalFreightPrice || 0}
          firstMileFreightCost={dataQuotation?.data?.firstMileFreightCost || 0}
          lastMileFreightCost={dataQuotation?.data?.lastMileFreightCost || 0}
          customsFreightCost={dataQuotation?.data?.customsFreightCost || 0}
          financingCost={dataQuotation?.data?.financingCost || 0}
          expectedDeliveryDays={dataQuotation?.data?.expectedDeliveryDays}
          isLoading={isLoading}
        />
      </>
    ),
  }

  useEffect(() => {
    trackEvent('adminQuotationShowPageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <Text variant="text-small" className={styles.breadcrumb}>
          <Link
            to={ROUTES_ADMIN.BUSINESS.QUOTATION.fullPath()}
          >{`${t('quotationTitle')}`}</Link>
          {` / `}
          <span>{dataQuotation?.data?.reference}</span>
        </Text>
        <div className={styles['header-content']}>
          <div>
            <div className={styles['sub-title']}>
              <Text variant="title-large">
                {t(
                  dataQuotation?.data?.products?.length === 1
                    ? 'quotationIdentifier'
                    : 'quotationIdentifierPlural',
                  {
                    quotation: dataQuotation?.data?.reference,
                    quantity: dataQuotation?.data?.products?.length,
                  },
                )}
              </Text>
              <QuotationStatusSelect quotation={dataQuotation?.data} />
            </div>
            <Text
              variant="text-base"
              className={styles['sub-title-instructions']}
            >
              {t('instructionGenerateProposals')}
            </Text>
          </div>
          <div className={styles['header-actions']}>
            <Button
              variant="secondary"
              href={ROUTES_ADMIN.BUSINESS.QUOTATION.UPDATE.fullPath(uuid || '')}
            >
              {t('quotationUpdateQuotationButton')}
            </Button>
          </div>
        </div>
      </header>

      <Tabs
        items={[
          {
            title: t('quotationTabAll'),
            isActive: queryParams.tab === 'all' || !queryParams.tab,
            onSelect: () => handleParams('tab', 'all'),
          },
          {
            title: t('quotationTabProducts'),
            isActive: queryParams.tab === 'products',
            onSelect: () => handleParams('tab', 'products'),
          },
          {
            title: t('quotationTabLogistic'),
            isActive: queryParams.tab === 'logistic',
            onSelect: () => handleParams('tab', 'logistic'),
          },
        ]}
      />

      <QuotationMetrics
        values={dataForMetrics as CalculateMarginPayloadProps}
      />

      <div className={styles.content}>
        {tabsContent?.[queryParams?.tab || 'all']}
      </div>
    </div>
  )
}
