import { useTranslation } from 'react-i18next'

import {
  DatePicker,
  FileIcon,
  InputNumber,
  QuestionFillIcon,
  Radio,
  Select,
  Text,
  Tooltip,
  WalletIcon,
} from '@mercai/clever'
import { Accordion } from '../accordion'

import { OrderDataProps } from './order-data.type'

import styles from './order-data.module.css'
// import { useTransportCompanyList } from '@/services'
import { TransportCompanyProps, QuotationDeliveryEnum } from '@/types'

export const OrderData = ({
  position,
  isComplete,
  quotationValues,
  setQuotationValues,
  activeItems,
  setActiveItems,
  errors,
  getMetrics,
  getSuggestedUnitPrice,
}: OrderDataProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  const onHandleValues = (
    key: string,
    value: number | string | boolean | undefined | TransportCompanyProps,
  ) => {
    setQuotationValues({
      ...quotationValues,
      [key]: value,
      ...(key === 'purchaseOnCredit' && !value && { financingCost: undefined }),
      ...(key === 'logisticTransportCompany' && {
        logisticTransportCompanyUuid: (value as TransportCompanyProps)?.uuid,
      }),
    })
  }

  // const { data: dataTransportCompany } = useTransportCompanyList({
  //   params: { limit: 99 },
  // })

  return (
    <Accordion
      activeItems={activeItems}
      setActiveItems={setActiveItems}
      title={`${position}. ${t('orderDataTitle')}`}
      id="order-data"
      key="order-data"
      isComplete={isComplete}
    >
      <div className={styles.core}>
        <div className={styles['info-block']}>
          <div className={styles.title}>
            <FileIcon className={styles.icon} />
            <Text variant="title-medium">{t('orderDataGeneralInfoTitle')}</Text>
          </div>
          <div className={styles['info-block-content']}>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataDueDateLabel')} <span>*</span>
              </Text>
              <DatePicker
                placeholder={t('orderDataDueDatePlaceholder')}
                hasTime
                value={quotationValues?.expiresAt}
                onChange={(newValue) => onHandleValues('expiresAt', newValue)}
                error={errors?.expiresAt as string}
                onBlur={() => getMetrics()}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataPostDeliveryDaysLabel')} <span>*</span>
              </Text>
              <InputNumber
                suffix={t('orderDataPostDeliveryDaysSufixLabel')}
                placeholder={t('orderDataPostDeliveryDaysPlaceholder')}
                value={quotationValues?.expectedDeliveryDays}
                error={errors?.expectedDeliveryDays as string}
                decimalPrecision={0}
                min={0}
                stepSize={1}
                showIcons
                onChange={(newValue) =>
                  onHandleValues('expectedDeliveryDays', newValue)
                }
                onBlur={() => getMetrics()}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">{t('orderDataDiscountLabel')}</Text>
              <InputNumber
                prefix="$ "
                placeholder={t('orderDataDiscountPlaceholder')}
                value={
                  quotationValues?.discount ? +quotationValues.discount : 0
                }
                error={errors?.discount as string}
                stepSize={100}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) => onHandleValues('discount', newValue)}
                onBlur={() => getMetrics()}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataShippingToBuyerLabel')} <span>*</span>
              </Text>
              <InputNumber
                prefix="$ "
                placeholder={t('orderDataShippingToBuyerPlaceholder')}
                value={quotationValues?.totalFreightPrice || 0}
                error={errors?.totalFreightPrice as string}
                min={0}
                stepSize={100}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues('totalFreightPrice', newValue)
                }
                onBlur={() => getMetrics()}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataDeliveryOptionLabel')} <span>*</span>
              </Text>
              <Select
                value={quotationValues?.deliveryOption}
                options={Object.keys(QuotationDeliveryEnum).map((item) => ({
                  label: tEnums(`logistic_shipment_delivery_option_${item}`),
                  value: item,
                }))}
                onChange={(value) => {
                  getMetrics()
                  onHandleValues('deliveryOption', value)
                }}
                placeholder={t('orderDataDeliveryOptionPlaceholder')}
                error={errors?.deliveryOption as string}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataIncludeVATLabel')}
                <Tooltip
                  text={t('orderDataIncludeVATDescription')}
                  widthText="15rem"
                  orientation="right"
                >
                  <QuestionFillIcon />
                </Tooltip>
              </Text>
              <div className={styles['radio-group']}>
                <Radio
                  value={!!quotationValues?.includeIva}
                  onChange={() => {
                    getMetrics()
                    onHandleValues('includeIva', true)
                  }}
                >
                  {t('yesLabel')}
                </Radio>

                <Radio
                  value={!quotationValues?.includeIva}
                  onChange={() => {
                    getMetrics()
                    onHandleValues('includeIva', false)
                  }}
                >
                  {t('noLabel')}
                </Radio>
              </div>
            </div>
          </div>
        </div>

        <div className={styles['info-block']}>
          <div className={styles.title}>
            <WalletIcon className={styles.icon} />
            <Text variant="title-medium">
              {t('orderDataAssociatedCostsTitle')}
            </Text>
          </div>
          <div className={styles['info-block-content']}>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataFirstMileCostLabel')}{' '}
                <Tooltip
                  text={t('orderDataCIADescription')}
                  widthText="17.25rem"
                >
                  <QuestionFillIcon />
                </Tooltip>{' '}
                <span>*</span>
              </Text>
              <InputNumber
                placeholder={t('orderDataFirstMileCostPlaceholder')}
                value={quotationValues?.firstMileFreightCost || 0}
                error={errors?.firstMileFreightCost as string}
                prefix={'$ '}
                stepSize={100}
                min={0}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues('firstMileFreightCost', newValue)
                }
                onBlur={() => {
                  getMetrics()
                  getSuggestedUnitPrice()
                }}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataLastMileCostLabel')}{' '}
                <Tooltip
                  text={t('orderDataCIADescription')}
                  widthText="17.25rem"
                >
                  <QuestionFillIcon />
                </Tooltip>{' '}
                <span>*</span>
              </Text>
              <InputNumber
                placeholder={t('orderDataLastMileCostPlaceholder')}
                value={quotationValues?.lastMileFreightCost || 0}
                error={errors?.lastMileFreightCost as string}
                prefix={'$ '}
                stepSize={100}
                min={0}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues('lastMileFreightCost', newValue)
                }
                onBlur={() => {
                  getMetrics()
                  getSuggestedUnitPrice()
                }}
              />
            </div>
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataCustomsCostLabel')}{' '}
                <Tooltip
                  text={t('orderDataCIADescription')}
                  widthText="17.25rem"
                >
                  <QuestionFillIcon />
                </Tooltip>{' '}
                <span>*</span>
              </Text>
              <InputNumber
                placeholder={t('orderDataCustomsCostPlaceholder')}
                value={quotationValues?.customsFreightCost || 0}
                error={errors?.customsFreightCost as string}
                prefix={'$ '}
                stepSize={100}
                min={0}
                showIcons
                decimalPrecision={2}
                onChange={(newValue) =>
                  onHandleValues('customsFreightCost', newValue)
                }
                onBlur={() => {
                  getMetrics()
                  getSuggestedUnitPrice()
                }}
              />
            </div>
            {/* <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataCarrierLabel')} <span>*</span>
              </Text>
              <Select
                value={quotationValues?.logisticTransportCompany?.uuid}
                options={
                  dataTransportCompany?.data?.results?.map((item) => ({
                    label: item.name,
                    value: item.uuid,
                  })) || []
                }
                onChange={(value) => {
                  getMetrics()
                  onHandleValues(
                    'logisticTransportCompany',
                    dataTransportCompany?.data?.results?.find(
                      (item) => item.uuid === value,
                    ) as TransportCompanyProps,
                  )
                }}
                placeholder={t('orderDataCarrierPlaceholder')}
              />
            </div> */}
            <div className={styles['info-block-content-item']}>
              <Text variant="text-base">
                {t('orderDataCreditPurchaseLabel')}
              </Text>
              <div className={styles['radio-group']}>
                <Radio
                  value={!!quotationValues?.purchaseOnCredit}
                  onChange={() => onHandleValues('purchaseOnCredit', true)}
                >
                  {t('yesLabel')}
                </Radio>

                <Radio
                  value={!quotationValues?.purchaseOnCredit}
                  onChange={() => onHandleValues('purchaseOnCredit', false)}
                >
                  {t('noLabel')}
                </Radio>
              </div>
            </div>

            {quotationValues?.purchaseOnCredit && (
              <div className={styles['info-block-content-item']}>
                <Text variant="text-base">
                  {t('orderDataCustomsCostDeliveryFinancingCostLabel')}{' '}
                  <span>*</span>
                </Text>
                <InputNumber
                  placeholder={t(
                    'orderDataCustomsCostDeliveryFinancingCostPlaceholder',
                  )}
                  value={
                    quotationValues?.financingCost
                      ? quotationValues?.financingCost * 100
                      : 0
                  }
                  error={errors?.financingCost as string}
                  suffix={'%'}
                  stepSize={1}
                  min={0}
                  showIcons
                  decimalPrecision={2}
                  onChange={(newValue) =>
                    onHandleValues(
                      'financingCost',
                      (+(newValue || 1) / 100).toFixed(4),
                    )
                  }
                  onBlur={() => getMetrics()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Accordion>
  )
}
