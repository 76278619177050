import { Card, Text } from '@mercai/clever'

import { HeaderProps } from './header.type'

import styles from './header.module.css'

export const Header = ({ description, title }: HeaderProps) => {
  return (
    <Card className={styles.core}>
      <div className={styles.image} />

      <div className={styles.content}>
        <Text variant="title-large" className={styles.title}>
          {title}
        </Text>

        <Text className={styles.description}>{description}</Text>
      </div>
    </Card>
  )
}
