import * as Yup from 'yup'

export const schemaSupplierProducts = (t: (key: string) => string) => ({
  minDeliveryDays: Yup.string().required(t('requiredMessage')),
  quantity: Yup.string().required(t('requiredMessage')),
  price: Yup.string().required(t('requiredMessage')),
  minimumPurchaseQuantity: Yup.string().required(t('requiredMessage')),
  supplierWarehouseUuid: Yup.string().required(t('requiredMessage')),
  package: Yup.string().required(t('requiredMessage')),
})
