import { useTranslation } from 'react-i18next'
import { Button, Text, PlusIcon, Tag, TrashIcon } from '@mercai/clever'

import { formatCurrency } from '@/helpers'

import type { SupplierProductListProps } from './supplier-product-list.type'

import type { SupplierProductProps } from '@/types'

import styles from './supplier-product-list.module.css'

export const SupplierProductList = ({
  onSelectedProduct,
  onClickNewProduct,
  onClickRemoveProduct,
  supplierProductItems,
}: SupplierProductListProps) => {
  const { t } = useTranslation('screenQuotationList')

  const onHandleClickRemove = (
    event: React.MouseEvent<HTMLButtonElement>,
    productItem: Partial<SupplierProductProps>,
  ) => {
    event.stopPropagation()

    onClickRemoveProduct(productItem)
  }

  return (
    <div className={styles.core}>
      {supplierProductItems?.map((productItem) => (
        <button
          className={styles['product-item']}
          type="button"
          key={productItem.uuid}
          onClick={() => onSelectedProduct(productItem)}
        >
          <Text variant="title-base">
            {productItem?.catalogProduct?.attributes?.title}
          </Text>

          <div className={styles['product-item-row']}>
            <Text>
              <strong>{t('quotationFormProductListItemQuantity')}:</strong>{' '}
              {productItem.quantity ? `${productItem.quantity} kg` : ' - '}
            </Text>

            {+(productItem?.quantity || 0) < 500 && (
              <Tag variant="basic" model="light" className={styles.tag}>
                {t('quotationFormProductListItemSample')}
              </Tag>
            )}
          </div>

          <Text>
            <strong>{t('quotationFormProductListItemPrice')}:</strong>{' '}
            {productItem.price
              ? formatCurrency(productItem?.price, 'es-MX', 'USD', 3)
              : ' - '}
          </Text>

          <Text>
            <strong>{t('quotationFormProductListItemTotalPrice')}:</strong>{' '}
            {productItem.price && productItem?.quantity
              ? formatCurrency(
                  productItem.price * productItem?.quantity,
                  'es-MX',
                  'USD',
                  3,
                )
              : ' - '}
          </Text>
          <Button
            variant="danger-light"
            model="square"
            className={styles['product-item-remove-button']}
            onClick={(event) => onHandleClickRemove(event, productItem)}
          >
            <TrashIcon />
          </Button>
        </button>
      ))}

      <Button variant="light" onClick={onClickNewProduct}>
        <PlusIcon /> {t('quotationFormProductListButton')}
      </Button>
    </div>
  )
}
