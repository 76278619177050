import { Text, Modal, Button } from '@mercai/clever'

import { QuotationsModalProps } from './quotations-modal.types'

import styles from './quotations-modal.module.css'
import { useTranslation } from 'react-i18next'

import { ROUTES_ADMIN } from '@/router'

export const QuotationsModal = ({
  quotations,
  isOpen,
  onHandleClose,
}: QuotationsModalProps) => {
  const { t } = useTranslation('componentsQuotationsModal')

  return (
    <Modal.Root isOpen={!!isOpen} onHandleOpen={onHandleClose} size="small">
      <div className={styles.header}>
        <div className={styles.title}>
          <Text variant="text-large-bold">{t('title')}</Text>
        </div>
        <Text variant="text-base">{t('subtitle')}</Text>
      </div>
      <Modal.Body>
        <div className={styles.container}>
          {quotations?.map((quotation, index) => (
            <div key={index} className={styles.card}>
              <div>
                <Text variant="text-base" className={styles['card-label']}>
                  <strong>{t('referenceLabel')} </strong>
                  {quotation?.reference}
                </Text>
                <div>
                  <Text variant="text-base" className={styles['card-label']}>
                    <strong>{t('statusLabel')} </strong>
                    {quotation?.status}
                  </Text>
                </div>
              </div>
              <Button
                variant="secondary"
                href={ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(
                  quotation?.uuid || '',
                )}
                model="square"
              >
                {t('viewButton')}
              </Button>
            </div>
          ))}
          <Button
            className={styles['header-close-button']}
            variant="secondary"
            onClick={onHandleClose}
          >
            {t('closeButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}
