import { Text, TruckIcon, Skeleton } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber } from '@/helpers'

import type { SummaryDeliveryProps } from './summary-delivery.type'

import styles from './summary-delivery.module.css'
import { CardItem } from '../card-item/card-item'
import { AddressProps } from '@/types'
import { Link } from 'react-router-dom'

const QuotationSummaryDeliveryDefault = ({
  deliveryAddress,
  totalFreightPrice,
  customsFreightCost,
  financingCost,
  firstMileFreightCost,
  lastMileFreightCost,
  dollarQuotation,
  delivery,
  expectedDeliveryDays,
}: SummaryDeliveryProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2Show')

  const mxnAmountPrice = formatCurrency(
    totalFreightPrice * dollarQuotation,
    'es-MX',
    'MXN',
  )
  const usdAmountPrice = formatCurrency(totalFreightPrice, 'es-MX', 'USD')

  const formatAddress = (address?: AddressProps): string => {
    if (!address) return ''

    const {
      street,
      number,
      complement,
      neighborhood,
      postalCode,
      state,
      reference,
    } = address

    const complementPart = complement ? `int. ${complement}` : ''
    const neighborhoodPart = neighborhood ? `(${neighborhood})` : ''
    const referencePart = reference ? `REF. ${reference}` : ''

    return `${street} ${number} ${complementPart} ${neighborhoodPart}, ${postalCode} ${state}. ${referencePart}`.trim()
  }

  const address = formatAddress(deliveryAddress)

  return (
    <CardItem
      icon={<TruckIcon />}
      title={`${t('quotationShowSummaryDeliveryTitle')} 1`}
    >
      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryDelivery')}:{' '}
          </Text>
          <Text variant="text-base">
            {tEnums(`logistic_shipment_delivery_option_${delivery}`)}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryDeliveryAddress')}:{' '}
          </Text>
          <Text variant="text-base">
            {address || t('quotationShowSummaryEmpty')}{' '}
            {!!address && (
              <Link
                target="_blank"
                to={`https://www.google.com/maps/search/${address}`}
                className={styles.link}
              >
                Google Maps
              </Link>
            )}
          </Text>
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryFreightPrice')}:{' '}
          </Text>

          {totalFreightPrice ? (
            <Text variant="text-base">
              {mxnAmountPrice} ({usdAmountPrice})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('quotationShowSummaryDeliveryFree')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryFirstMileFreightCost')}:{' '}
          </Text>

          {firstMileFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                firstMileFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(firstMileFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('quotationShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryLastMileFreightCost')}:{' '}
          </Text>

          {lastMileFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                lastMileFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(lastMileFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('quotationShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryCustomsFreightCost')}:{' '}
          </Text>

          {customsFreightCost ? (
            <Text variant="text-base">
              {formatCurrency(
                customsFreightCost * dollarQuotation,
                'es-MX',
                'MXN',
              )}{' '}
              ({formatCurrency(customsFreightCost, 'es-MX', 'USD')})
            </Text>
          ) : (
            <Text variant="text-base">
              {t('quotationShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryFinancingCost')}:{' '}
          </Text>

          {financingCost ? (
            <Text variant="text-base">
              {formatNumber(financingCost * 100, 'es-MX', 2)}%
            </Text>
          ) : (
            <Text variant="text-base">
              {t('quotationShowSummaryDeliveryUninformedData')}
            </Text>
          )}
        </div>

        <div className={styles['content-row']}>
          <Text variant="title-base">
            {t('quotationShowSummaryDeliveryDeliveryDate')}:{' '}
          </Text>
          <Text variant="text-base">
            {expectedDeliveryDays
              ? `${expectedDeliveryDays} ${t('quotationShowSummaryDeliveryDaysAfterPayment')}`
              : t('quotationShowSummaryDeliveryDateNotDefined')}
          </Text>
        </div>
      </div>
    </CardItem>
  )
}

const QuotationSummaryDeliveryLoading = () => {
  const { t } = useTranslation('screenQuotationV2Show')
  return (
    <CardItem
      icon={<TruckIcon />}
      title={`${t('quotationShowSummaryDeliveryTitle')} 1`}
    >
      <div className={styles.content}>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="30%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="60%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="50%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="30%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="60%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="50%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="40%" />
        </div>
        <div className={styles['content-row']}>
          <Skeleton height="1rem" width="30%" />
        </div>
      </div>
    </CardItem>
  )
}

export const QuotationSummaryDelivery = (props: SummaryDeliveryProps) => {
  if (props.isLoading) {
    return <QuotationSummaryDeliveryLoading />
  }

  return <QuotationSummaryDeliveryDefault {...props} />
}
