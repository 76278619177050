import { Button, Drawer } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { calculateQuotationValues } from '@/helpers'
import { useProfileDetail, useQuotationDetail } from '@/services'

import {
  QuotationHeader,
  QuotationSummaryStatus,
  QuotationSummaryDelivery,
  QuotationSummaryProducts,
  QuotationSummaryValues,
  QuotationSummaryProfile,
  QuotationSummaryPayment,
  QuotationSummaryPaymentCondition,
} from './components'

import styles from './drawer-show.module.css'

import type { DrawerShowProps } from './drawer-show.type'
import { appQuotationLink } from '@/constants'

export const QuotationDrawerShow = ({
  isOpen,
  onHandleOpen,
  quotationUuid,
}: DrawerShowProps) => {
  const { t } = useTranslation('screenQuotationList')

  const { data: quotation, isLoading } = useQuotationDetail(quotationUuid)
  const { data: profileData, isLoading: profileIsLoading } = useProfileDetail(
    quotation?.data?.buyerProfile?.uuid,
  )

  const { discount, iva, subTotal, total } = calculateQuotationValues(
    quotation?.data?.products || [],
    quotation?.data?.deliveryOption === 'pick_up'
      ? 0
      : +(quotation?.data?.totalFreightPrice || 0),
    +(quotation?.data?.discount || 0),
    quotation?.data?.includeIva,
  )

  return (
    <Drawer isOpen={isOpen} onHandleOpen={() => onHandleOpen(false)}>
      <div className={styles['header-wrap']}>
        <QuotationHeader
          reference={quotation?.data?.reference || ''}
          createdAt={quotation?.data?.createdAt || ''}
          expiresAt={quotation?.data?.expiresAt || ''}
          onHandleOpen={onHandleOpen}
          isLoading={isLoading}
        />
      </div>

      <div className={styles.content}>
        <QuotationSummaryStatus
          status={quotation?.data?.status || 'pending'}
          isLoading={isLoading}
        />

        <QuotationSummaryProfile
          companyName={quotation?.data?.buyerProfile?.companyName || ''}
          email={quotation?.data?.buyerProfile?.email || ''}
          name={
            quotation?.data?.buyerProfile?.usersNames
              ? quotation?.data?.buyerProfile?.usersNames.join(', ')
              : '-'
          }
          phone={quotation?.data?.buyerProfile?.phone || ''}
          isLoading={isLoading}
        />

        <QuotationSummaryPaymentCondition
          financeProfileData={profileData?.data?.financeProfile}
          isLoading={profileIsLoading}
        />

        <QuotationSummaryProducts
          dollarQuotation={quotation?.data?.dollarExchangeRate || 0}
          products={quotation?.data?.products || []}
          isLoading={isLoading}
        />

        <QuotationSummaryPayment isLoading={isLoading} />

        <QuotationSummaryDelivery
          buyerAddress={quotation?.data?.deliveryAddress?.city || ''}
          delivery={quotation?.data?.deliveryOption || ''}
          dollarQuotation={quotation?.data?.dollarExchangeRate || 0}
          totalFreightPrice={quotation?.data?.totalFreightPrice || 0}
          firstMileFreightCost={quotation?.data?.firstMileFreightCost || 0}
          lastMileFreightCost={quotation?.data?.lastMileFreightCost || 0}
          customsFreightCost={quotation?.data?.customsFreightCost || 0}
          financingCost={quotation?.data?.financingCost || 0}
          expectedDeliveryDays={quotation?.data?.expectedDeliveryDays}
          isLoading={isLoading}
        />

        <div className={styles['summary-values-wrap']}>
          <QuotationSummaryValues
            isLoading={isLoading}
            discount={discount}
            dollarQuotation={+(quotation?.data?.dollarExchangeRate || 0)}
            iva={iva}
            subTotal={subTotal}
            total={total}
            totalFreight={
              quotation?.data?.deliveryOption === 'pick_up'
                ? 0
                : +(quotation?.data?.totalFreightPrice || 0)
            }
          />
        </div>
      </div>

      <div className={styles.footer}>
        <Button variant="secondary" onClick={() => onHandleOpen(false)}>
          {t('quotationShowButtonClose')}
        </Button>

        <Button
          variant="light"
          href={appQuotationLink(quotation?.data?.uuid || '')}
          target="_blank"
        >
          {t('quotationShowButtonAppLink')}
        </Button>
      </div>
    </Drawer>
  )
}
