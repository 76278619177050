import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Button, DrawerStepperProgress } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import {
  useValidationErrors,
  onErrorMessage,
  onSuccessMessage,
} from '@/helpers'

import {
  useAIAgentDetail,
  useAICreateAgent,
  useAIUpdateAgent,
  QUERIES_ADMIN,
} from '@/services'

import { IAAgentProps } from '@/types'

import { schemaAgent } from './drawer-form.constants'

import { AgentForm } from './components'

import type { DrawerAgentFormProps } from './drawer-form.type'

export const AgentDrawerForm = ({
  isOpen,
  onHandleOpen,
  agentUuid,
}: DrawerAgentFormProps) => {
  const { t } = useTranslation('screenAiCenter')

  const isNewRegister = !agentUuid

  const queryClient = useQueryClient()

  const [data, setData] = useState<Partial<IAAgentProps>>({})

  const {
    isValid: isValidAgent,
    errors: agentErrors,
    onHandleListenErrors: onHandleListenErrorsAgent,
  } = useValidationErrors(schemaAgent(t), data)

  const { data: dataAgent } = useAIAgentDetail(agentUuid || '')

  const { mutateAsync: mutateAsyncUpdateAgent } = useAIUpdateAgent()
  const { mutateAsync: mutateAsyncCreateAgent } = useAICreateAgent()

  const onSubmitAgentForm = async () => {
    if (!isValidAgent) {
      onHandleListenErrorsAgent(true)
      return
    }

    onHandleListenErrorsAgent(false)

    let response: {
      success: boolean
      message: string
      data?: IAAgentProps
    } = {
      success: false,
      message: '',
      data: {} as IAAgentProps,
    }

    if (isNewRegister) {
      response = await mutateAsyncCreateAgent(data as IAAgentProps)
    } else {
      response = await mutateAsyncUpdateAgent(data as IAAgentProps)
    }

    if (response.success) {
      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.AI_AGENT.module],
      })

      onSuccessMessage(response.message)

      onHandleOpen(false)
    } else {
      if (isNewRegister) {
        onErrorMessage(
          t('agentFormErrorCreateTitle'),
          t('agentFormErrorCreateMessage'),
        )
      } else {
        onErrorMessage(
          t('agentFormErrorUpdateTitle'),
          t('agentFormErrorUpdateMessage'),
        )
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      if (isNewRegister) {
        setData({})
      } else {
        if (dataAgent?.data) {
          setData({
            ...dataAgent.data,
          })
        }
      }
    }
  }, [isOpen, dataAgent])

  return (
    <DrawerStepperProgress
      translations={{
        optional: t('agentFormOptional'),
      }}
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      title={
        isNewRegister ? t('agentFormTitleCreate') : t('agentFormTitleUpdate')
      }
      description={
        isNewRegister
          ? t('agentFormSubTitleCreate')
          : t('agentFormSubTitleUpdate')
      }
      initialStep={'root'}
      steps={{
        root: {
          title: t('agentFormGeneralFormTitle'),
          isOptional: false,
          backNavigate: 'root',
          stepNumber: 0,
          component: (
            <AgentForm
              errors={agentErrors as Record<string, string>}
              values={data || {}}
              onChangeValues={setData}
            />
          ),
          footer: ({ onNavigateToPreviousStep }) => (
            <>
              <Button
                variant="secondary"
                onClick={() => onNavigateToPreviousStep()}
              >
                {t('agentFormNavigateButtonBack')}
              </Button>

              <Button onClick={() => onSubmitAgentForm()}>
                {t('agentFormNavigateButtonSaveAndNext')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
