import { ProfileProps } from './profiles.type'
import { AddressProps } from './address.type'
import { CatalogProductProps } from './catalog-product.type'
import { QuotationProps } from './quotation.type'

export enum QuotationRequestItemCategoryEnum {
  'pp',
  'pe',
  'pc',
  'abs',
  'eva',
  'ps',
  'tpe',
  'pet',
  'pvc',
  'pa',
}

export interface QuotationRequestItemProductProps {
  title: string
  density: number
  fluidity: number
  category: string
  size: string
  quantityPerPackage: string
  itemDataSheetPath: string
}

export interface QuotationRequestItemProps {
  uuid: string
  product: Partial<QuotationRequestItemProductProps>
  catalogProductUuid?: string
  catalogProduct?: CatalogProductProps
  quantity: number
  targetPrice: number
  purchaseFrequency: string
  observation: string
  isSimilarAccepted: boolean
  createdAt: string
}

export enum QuotationRequestStatusEnum {
  'pending' = 'pending',
  'processed' = 'processed',
  'completed' = 'completed',
  'expired' = 'expired',
  'invalid' = 'invalid',
  'disqualified' = 'disqualified',
  'qualified' = 'qualified',
  'approved_for_quotation' = 'approved_for_quotation',
}

export enum QuotationRequestOriginEnum {
  'quotation_request',
  'catalog',
  'backoffice_quotation_request',
  'open_quotation_request',
  'open_catalog',
  'product_match',
}

export interface QuotationRequestRefusedByProps {
  id: string
  name: string
  email: string
  phone: string
  isActive: string
  allowEmail: string
  allowWhatsapp: string
}

export interface QuotationRequestProps {
  uuid: string
  file: string
  filePath: string
  reference: string
  status: keyof typeof QuotationRequestStatusEnum
  address: Partial<AddressProps>
  addressUuid: string
  items: Partial<QuotationRequestItemProps>[] | QuotationRequestItemProps[]
  origin: keyof typeof QuotationRequestOriginEnum
  quotations?: QuotationProps[]
  createdAt: string
  updatedAt: string
  buyerProfile: ProfileProps
  buyerProfileUuid: string
  creatorUser: {
    name: string
    email: string
  }
  refusedReason?: string
  refusedBy?: QuotationRequestRefusedByProps
  trafficSource?: string
}

export interface QuotationRequestListWithPagesProps {
  count: number
  next?: string
  previous?: string
  results?: QuotationRequestProps[]
}

export enum QuotationRequestSplitEnum {
  keep_in_current = 'keep_in_current',
  create_for_each = 'create_for_each',
  create_with_selected = 'create_with_selected',
}

export interface QuotationRequestClassificationProps {
  pendingAcquisition: string
  pendingSupply: string
  automatingRfqs: string
  invalid: string
  expired: string
  total: string
}

export interface QuotationRequestSlasProps {
  approvedForQuotationToProcessedConversion: number
  approvedForQuotationToProcessedConversionVariation: number
  approvedForQuotationToProcessedSla: number
  approvedForQuotationToProcessedSlaVariation: number
  newUsers: number
  newUsersVariation: number
  pendingToApprovedForQuotationConversion: number
  pendingToApprovedForQuotationConversionVariation: number
  pendingToApprovedForQuotationSla: number
  pendingToApprovedForQuotationSlaVariation: number
  qualifiedToProcessedConversion: number
  qualifiedToProcessedConversionVariation: number
  qualifiedToProcessedSla: number
  qualifiedToProcessedSlaVariation: number
  quotationRequestAutomatedCount: number
  quotationRequestAutomatedVariation: number
}
