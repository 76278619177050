import { useTranslation } from 'react-i18next'
import { Select, Label, InputNumber } from '@mercai/clever'

import { useSupplierWarehouseList } from '@/services'
import { SupplierProductPackageEnum } from '@/types'

import type { SupplierProductFormProps } from './supplier-product-form.type'

import styles from './supplier-product-form.module.css'

export const SupplierProductForm = ({
  values,
  onChangeValues,
  errors,
  supplierUuid,
}: SupplierProductFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenCatalogProduct')

  const onHandleValues = (key: string, val: string | number | boolean) => {
    onChangeValues({ ...values, [key]: val })
  }

  const { data: warehouses } = useSupplierWarehouseList({
    params: {
      supplier__uuid:
        supplierUuid || values?.supplierWarehouse?.supplier?.uuid || '',
    },
  })

  return (
    <div>
      <div className={styles.content}>
        <div className={styles['content-form']}>
          <div>
            <Label>
              {t('detailSupplierProductsFormMinimumDeliveryTimeLabel')}
            </Label>
            <InputNumber
              showIcons
              placeholder={t(
                'detailSupplierProductsFormMinimumDeliveryTimePlaceholder',
              )}
              value={values?.minDeliveryDays}
              onChange={(newValue) =>
                onHandleValues('minDeliveryDays', newValue)
              }
              error={errors?.minDeliveryDays as string}
              suffix={t('detailSupplierProductsFormMinimumDeliveryTimeSuffix')}
              decimalPrecision={0}
            />
          </div>
          <div>
            <Label>{t('detailSupplierProductsFormAvailableLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('detailSupplierProductsFormAvailablePlaceholder')}
              value={values?.quantity}
              onChange={(newValue) => onHandleValues('quantity', newValue)}
              error={errors?.quantity as string}
              suffix=" Kg"
            />
          </div>
          <div>
            <Label>{t('detailSupplierProductsFormPriceLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('detailSupplierProductsFormPricePlaceholder')}
              value={values?.price}
              onChange={(newValue) => onHandleValues('price', newValue)}
              error={errors?.price as string}
              suffix=" USD"
              decimalPrecision={3}
            />
          </div>
          <div>
            <Label>{t('detailSupplierProductsFormMinLabel')}</Label>
            <InputNumber
              showIcons
              placeholder={t('detailSupplierProductsFormMinPlaceholder')}
              value={values?.minimumPurchaseQuantity}
              onChange={(newValue) =>
                onHandleValues('minimumPurchaseQuantity', newValue)
              }
              error={errors?.minimumPurchaseQuantity as string}
              suffix=" Kg"
            />
          </div>
          <div>
            <Label>{t('detailSupplierProductsFormWarehouseLabel')}</Label>
            <Select
              options={
                warehouses?.data?.results?.map?.((warehouse) => ({
                  label: `${warehouse.name}`,
                  value: warehouse?.uuid || '',
                })) || []
              }
              placeholder={t('detailSupplierProductsFormWarehousePlaceholder')}
              value={values?.supplierWarehouseUuid}
              onChange={(newValue) =>
                onHandleValues('supplierWarehouseUuid', newValue)
              }
              error={errors?.supplierWarehouseUuid as string}
            />
          </div>
          <div>
            <Label>{t('detailSupplierProductsFormPackingLabel')}</Label>
            <Select
              options={Object.keys(SupplierProductPackageEnum).map((key) => ({
                label: tEnums(`supplier_product_package_${key}`),
                value: key,
              }))}
              placeholder={t('detailSupplierProductsFormPackingPlaceholder')}
              value={values?.package}
              onChange={(newValue) => onHandleValues('package', newValue)}
              error={errors?.package as string}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
