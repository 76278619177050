import { SVGProps } from 'react'

export const StarsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M5.99935 3.16675L7.66602 6.83341L11.3327 8.50008L7.66602 10.1667L5.99935 13.8334L4.33268 10.1667L0.666016 8.50008L4.33268 6.83341L5.99935 3.16675ZM5.99935 6.38675L5.33268 7.83341L3.88602 8.50008L5.33268 9.16675L5.99935 10.6134L6.66602 9.16675L8.11268 8.50008L6.66602 7.83341L5.99935 6.38675ZM12.666 6.50008L11.826 4.67341L9.99935 3.83341L11.826 3.00008L12.666 1.16675L13.4993 3.00008L15.3327 3.83341L13.4993 4.67341L12.666 6.50008ZM12.666 15.8334L11.826 14.0067L9.99935 13.1667L11.826 12.3334L12.666 10.5001L13.4993 12.3334L15.3327 13.1667L13.4993 14.0067L12.666 15.8334Z" />
    </svg>
  )
}
