import { ReactNode, useCallback, useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  Button,
  DropDown,
  FilterIcon,
  TableColumns,
  Tag,
  Text,
  Tooltip,
  Select,
  Skeleton,
  Pagination,
  PlusIcon,
  TableColumnsColumnProps,
  TrashIcon,
  InputSearch,
  ArrowRightIcon,
  AlertIcon,
  AlertHighlightIcon,
  BurguerIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import {
  formatDate,
  useParamsState,
  onConfirmDangerMessage,
  onErrorMessage,
  onSuccessMessage,
  trackEvent,
} from '@/helpers'

import {
  useQuotationRequestList,
  useDeleteQuotationRequest,
  useQuotationRequestClassification,
  useQuotationRequestSlas,
} from '@/services'

import { useAuth } from '@/providers'

import { ROUTES_ADMIN } from '@/router'

import { QuotationRequestProps, QuotationRequestStatusEnum } from '@/types'

import {
  QuotationRequestDrawerFilter,
  QuotationRequestDrawerForm,
} from './components'

import { TagMoscow } from '../../../profile/components'

import type { QuotationRequestListProps } from './list.type'

import styles from './list.module.css'
import {
  BusinessMetricCards,
  CatalogProductsCarousel,
  Tabs,
} from '../../components'

import {
  generateOrderingButtonClasses,
  getReferenceColumnStatus,
  getStatusByTab,
  metricsByTabs,
  ReferenceColumnStatusEnum,
} from './list.constants'

import { EmptyState } from '@/components'
import { BusinessMetricCardProps } from '../../components/business-metric-card/business-metric-card.type'

export const QuotationRequestV2List = ({
  buyerProfile,
}: QuotationRequestListProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationRequestListV2')

  const location = useLocation()
  const page = location.pathname.split('/').pop()

  const { isSuperUser } = useAuth()

  const [isOpenDrawerFilter, setIsOpenDrawerFilter] = useState(false)
  const [isOpenDrawerForm, setIsOpenDrawerForm] = useState(false)
  const [quotationRequestUuid, setQuotationRequestUuid] = useState('')

  const [totalPages, setTotalPages] = useState(1)

  const onHandleOpenDrawerForm = (isOpen: boolean) => {
    setIsOpenDrawerForm(isOpen)

    if (!isOpen) {
      setQuotationRequestUuid('')
    }
  }

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
    status: '',
    ordering: '',
    moscow: '',
    createdAtBefore: '',
    createdAtAfter: '',
    rootCategory: '',
    tab: 'all',
  })

  const getContentTotalFiltered = () => {
    const activeFilters = Object.keys(filter)?.filter(
      (key) =>
        !!filter[key] &&
        key !== 'page' &&
        key !== 'limit' &&
        key !== 'tab' &&
        key !== 'classification_group' &&
        key !== 'ordering',
    ).length

    if (activeFilters > 0) {
      return (
        <Tag variant="primary" model="light" size="small">
          {activeFilters}
        </Tag>
      )
    }

    return ''
  }

  const handleFilter = (changedKey: string, newValue: string | number) => {
    if (changedKey === 'page') {
      setFilter({ ...filter, [changedKey]: `${newValue}` })
    } else if (changedKey === 'tab') {
      setFilter({
        ...filter,
        [changedKey]: `${newValue}`,
        page: '1',
        classification_group: '',
      })
    } else {
      setFilter({ ...filter, [changedKey]: `${newValue}`, page: '1' })
    }
  }

  const onHandleOrdering = (ordering: string) => {
    if (ordering === filter.ordering) {
      return handleFilter('ordering', '')
    }

    handleFilter('ordering', ordering)
  }

  const { data, isLoading, refetch } = useQuotationRequestList(
    buyerProfile
      ? {
          ...filter,
          buyerProfile,
          status: filter?.tab
            ? `${getStatusByTab(filter.tab)}${filter.status}`
            : filter.status || '',
          tab: '',
        }
      : {
          ...filter,
          status: filter?.tab
            ? `${getStatusByTab(filter.tab)}${filter.status}`
            : filter.status || '',
          tab: '',
        },
  )

  const { data: dataClassification, isLoading: isLoadingClassification } =
    useQuotationRequestClassification()

  const { data: dataSlas, isLoading: isLoadingSlas } = useQuotationRequestSlas()

  const { isPending, mutateAsync: mutateDelete } = useDeleteQuotationRequest()

  const onHandleDelete = async (uuid: string) => {
    const confirm = await onConfirmDangerMessage(
      t('quotationRequestTableDeleteConfirmTitle'),
      t('quotationRequestTableDeleteConfirmMessage'),
      t('quotationRequestTableDeleteConfirmButtonDelete'),
      t('quotationRequestTableDeleteConfirmButtonCancel'),
    )

    if (confirm) {
      const result = await mutateDelete(uuid)

      if (result.success) {
        onSuccessMessage(
          t('quotationRequestTableDeleteSuccessTitle'),
          t('quotationRequestTableDeleteSuccessMessage'),
        )

        refetch()
      } else {
        onErrorMessage(
          t('quotationRequestTableDeleteErrorTitle'),
          t('quotationRequestTableDeleteErrorMessage'),
        )
      }
    }
  }

  const refereceColumn = ({ record }: { record: QuotationRequestProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    const referenceStatusObject: Record<ReferenceColumnStatusEnum, ReactNode> =
      {
        default: (
          <div className={`${styles['reference-column']}`}>
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
          </div>
        ),
        success: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-success']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipSuccess')}
              orientation="right"
            >
              <AlertHighlightIcon />
            </Tooltip>
          </div>
        ),
        warning: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-warning']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipWarning')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
        danger: (
          <div
            className={`${styles['reference-column']} ${styles['reference-column-danger']}`}
          >
            <div>
              <Text>#{record?.reference}</Text>
              <Text variant="text-small" className={styles['gray-400']}>
                {record?.createdAt ? formatDate(record?.createdAt) : ''}
              </Text>
            </div>
            <Tooltip
              text={t('quotationRequestTableReferenceTooltipDanger')}
              orientation="right"
            >
              <AlertIcon />
            </Tooltip>
          </div>
        ),
      }

    return referenceStatusObject[referenceStatus]
  }

  const bodyColumn = ({ record }: { record: QuotationRequestProps }) => {
    const referenceStatus = getReferenceColumnStatus(record)

    if (record?.status === 'pending' && referenceStatus === 'danger') {
      return styles['column-danger']
    }

    return ''
  }

  const stateObject: Record<QuotationRequestStatusEnum, ReactNode> = {
    approved_for_quotation: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_approved_for_quotation')}
      </Tag>
    ),
    completed: (
      <Tag variant="success" model="light">
        {tEnums('quotation_request_status_completed')}
      </Tag>
    ),
    disqualified: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_disqualified')}
      </Tag>
    ),
    qualified: (
      <Tag variant="info" model="light">
        {tEnums('quotation_request_status_qualified')}
      </Tag>
    ),
    expired: (
      <Tag variant="basic" model="light">
        {tEnums('quotation_request_status_expired')}
      </Tag>
    ),
    invalid: (
      <Tag variant="danger" model="light">
        {tEnums('quotation_request_status_invalid')}
      </Tag>
    ),
    processed: (
      <Tag variant="primary" model="light">
        {tEnums('quotation_request_status_processed')}
      </Tag>
    ),
    pending: (
      <Tag variant="warning" model="light">
        {tEnums('quotation_request_status_pending')}
      </Tag>
    ),
  }

  const columns: TableColumnsColumnProps<QuotationRequestProps>[] = [
    {
      title: t('quotationRequestTableReference'),
      key: 'reference',
      dataKey: 'reference',
      width: '9.6rem',
      render: ({ record }) => refereceColumn({ record }),
    },
    {
      title: t('quotationRequestTableBuyerName'),
      key: 'buyerProfile.companyName',
      dataKey: 'buyerProfile.companyName',
      render: ({ record }) => (
        <div className={styles['buyer-profile']}>
          <Tooltip
            text={record?.buyerProfile?.companyName || '-'}
            orientation="top"
          >
            <Link
              target="_blank"
              to={ROUTES_ADMIN.PROFILE.SHOW.fullPath(
                record?.buyerProfile?.uuid,
              )}
              className={styles.link}
            >
              {record?.buyerProfile?.companyName || '-'}
            </Link>
          </Tooltip>
          <Tooltip text={record?.buyerProfile?.email || '-'}>
            <div>
              <Text variant="text-small" className={styles.email}>
                {record?.buyerProfile?.email || '-'}
              </Text>
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('quotationRequestTableState'),
      key: 'status',
      dataKey: 'status',
      render: ({ record }) =>
        stateObject[
          (record?.status || 'pending') as QuotationRequestStatusEnum
        ] || '-',
      width: '15.4rem',
    },
    {
      title: t('quotationRequestTablePhone'),
      key: 'buyerProfile.phone',
      dataKey: 'buyerProfile.phone',
      width: '10rem',
    },
    {
      title: t('quotationRequestTableType'),
      key: 'buyerProfile.type',
      dataKey: 'buyerProfile.type',
      width: '6.5rem',
      render: ({ record }) =>
        record?.buyerProfile?.type
          ? tEnums(`profile_type_${record?.buyerProfile?.type}`)
          : '-',
    },
    {
      title: t('quotationRequestTableMoscow'),
      key: 'buyerProfile.moscowClassification',
      dataKey: 'buyerProfile.moscowClassification',
      width: '10.6rem',
      render: ({ record }) => (
        <TagMoscow
          moscowClassification={record?.buyerProfile?.moscowClassification}
        />
      ),
    },
    {
      title: isSuperUser ? t('quotationRequestTableActions') : '',
      key: 'uuid',
      dataKey: 'uuid',
      width: isSuperUser ? '8rem' : '4.375rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            model="square"
            variant="primary"
            href={ROUTES_ADMIN.BUSINESS.RFQ.SHOW.fullPath(record.uuid)}
          >
            <ArrowRightIcon />
          </Button>
          {isSuperUser && (
            <Button
              model="square"
              variant="danger"
              onClick={() => onHandleDelete(record.uuid)}
            >
              <TrashIcon />
            </Button>
          )}
        </div>
      ),
      renderLoading: (
        <div className={styles['action-buttons']}>
          <Skeleton height="2rem" width="2rem" />
          {isSuperUser && <Skeleton height="2rem" width="2rem" />}
        </div>
      ),
    },
  ]

  const ExpandedComponent = ({ record }: { record: QuotationRequestProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('quotationRequestTableSellerName')}</strong>
            {': '}
            {(record?.buyerProfile?.keyAccountManager as unknown as string) ||
              '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableCustomerLevel')}</strong>
            {': '}
            {record?.buyerProfile?.customerLevel
              ? tEnums(
                  `profile_customer_${record.buyerProfile.customerLevel}`.toLocaleLowerCase(),
                )
              : '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableOrigin')}</strong>
            {': '}
            {record?.origin
              ? tEnums(`quotation_request_origin_${record.origin}`)
              : '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableCreatorName')}</strong>
            {': '}
            {record?.creatorUser?.email || '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableQuotations')}</strong>
            {': '}
            {record?.quotations && record?.quotations?.length > 0 ? (
              <div className={styles['column-quotation']}>
                {record?.quotations?.map((quotation) => (
                  <Link
                    key={quotation?.uuid}
                    to={`${ROUTES_ADMIN.BUSINESS.QUOTATION.SHOW.fullPath(
                      quotation?.uuid,
                    )}&search=${quotation.reference}`}
                    className={styles.link}
                    target="_blank"
                  >
                    {`#${quotation?.reference}`}
                  </Link>
                ))}
              </div>
            ) : (
              <Text>-</Text>
            )}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableRefusedByEmail')}</strong>
            {': '}
            {record?.refusedBy?.email || '-'}
          </Text>
          <Text>
            <strong>{t('quotationRequestTableRefusedReason')}</strong>
            {': '}
            {record?.refusedReason ? (
              <Tooltip
                text={record?.refusedReason}
              >{`${record?.refusedReason.substring(0, 50)}...`}</Tooltip>
            ) : (
              '-'
            )}
          </Text>

          <Text>
            <strong>{t('quotationRequestTableTrafficSource')}</strong>
            {': '}
            {record?.trafficSource || '-'}
          </Text>
        </div>
        <div className={styles['expanded-content-products']}>
          <CatalogProductsCarousel products={record?.items || []} />
        </div>
      </div>
    )
  }

  const Ordering = useCallback(
    () => (
      <DropDown.Root>
        <DropDown.ButtonTrigger variant="secondary" hasArrow={false}>
          <BurguerIcon />
          {t('quotationRequestTableOrderingButton')}
          {filter?.ordering && (
            <Tag variant="primary" model="light" size="small">
              1
            </Tag>
          )}
        </DropDown.ButtonTrigger>
        <DropDown.Content
          orientation="bottom-right"
          className={styles.ordering}
        >
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'createdAt',
            )}
            onClick={() => onHandleOrdering('createdAt')}
          >
            {t('quotationRequestTableOrderingCreatedAtButton')}
          </div>
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'buyer_profile__moscow_classification',
            )}
            onClick={() =>
              onHandleOrdering('buyer_profile__moscow_classification')
            }
          >
            {t('quotationRequestTableOrderingMoscowButton')}
          </div>
          <div
            className={generateOrderingButtonClasses(
              filter?.ordering === 'buyer_profile__company_name',
            )}
            onClick={() => onHandleOrdering('buyer_profile__company_name')}
          >
            {t('quotationRequestTableOrderingAZButton')}
          </div>
        </DropDown.Content>
      </DropDown.Root>
    ),
    [filter],
  )

  const metricsComponents: Record<string, BusinessMetricCardProps> = {
    'sla-rfq-pending-to-approved-for-quotation': {
      change: dataSlas?.data?.pendingToApprovedForQuotationSlaVariation || 0,
      label: t('quotationRequestSlaRfqPendingToApprovedForQuotationGeneral'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.pendingToApprovedForQuotationSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
    },
    'conversion-rfq-pending-to-approved-for-quotation': {
      label: t('quotationRequestConversionRfqPendingToApprovedForQuotation'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.pendingToApprovedForQuotationConversion || 0).toFixed(2)}%`,
      change:
        dataSlas?.data?.pendingToApprovedForQuotationConversionVariation || 0,
    },
    'number-of-customers-acquired-in-month': {
      label: t('quotationRequestNumberOfCustomersAcquiredInMonth'),
      isLoading: isLoadingSlas,
      value: `${dataSlas?.data?.newUsers || 0}`,
      change: dataSlas?.data?.newUsersVariation || 0,
    },
    'sla-rfq-approved-for-quotation-to-processed': {
      change: dataSlas?.data?.approvedForQuotationToProcessedSlaVariation || 0,
      label: t('quotationRequestSlaRfqApprovedForQuotationToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.approvedForQuotationToProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
    },
    'cr-rfq-approved-for-quotation-to-processed': {
      label: t('quotationRequestCrRfqApprovedForQuotationToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.approvedForQuotationToProcessedConversion || 0).toFixed(2)}%`,
      change:
        dataSlas?.data?.approvedForQuotationToProcessedConversionVariation || 0,
    },
    'rfqs-created': {
      label: t('quotationRequestRfqsCreated'),
      isLoading: isLoadingSlas,
      value: `${dataSlas?.data?.quotationRequestAutomatedCount || 0}`,
      change: dataSlas?.data?.quotationRequestAutomatedVariation || 0,
    },
    'cr-percentage-created-to-processed': {
      change: dataSlas?.data?.qualifiedToProcessedSlaVariation || 0,
      label: t('quotationRequestCrPercentageCreatedToProcessed'),
      isLoading: isLoadingSlas,
      value: `${(dataSlas?.data?.qualifiedToProcessedSla || 0).toFixed(2)}${t('quotationRequestHours')}`,
    },
  }

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminRfqListPageView', { page })
  }, [])

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles['header-content']}>
          <div>
            <Text variant="title-large">RFQ</Text>
            <div>
              <Text variant="text-base" className={styles['sub-title']}>
                {t('quotationRequestSubtitle')}
              </Text>
            </div>
          </div>
          <div className={styles['header-actions']}>
            <Button onClick={() => onHandleOpenDrawerForm(true)}>
              <PlusIcon />
              {t('quotationRequestTableNewRFQButton')}
            </Button>
          </div>
        </div>
      </header>

      <Tabs
        isLoading={isLoadingClassification}
        hasCount
        items={[
          {
            title: t('quotationRequestTabAll'),
            isActive: !filter.tab || filter.tab === 'all',
            onSelect: () => handleFilter('tab', 'all'),
            count: dataClassification?.data?.total || 0,
          },
          {
            title: t('quotationRequestTabPendingAcquisition'),
            isActive: filter.tab === 'pending-acquisition',
            onSelect: () => handleFilter('tab', 'pending-acquisition'),
            count: dataClassification?.data?.pendingAcquisition || 0,
          },
          {
            title: t('quotationRequestTabPendingSupply'),
            isActive: filter.tab === 'pending-supply',
            onSelect: () => handleFilter('tab', 'pending-supply'),
            count: dataClassification?.data?.pendingSupply || 0,
          },
          {
            title: t('quotationRequestTabAutomatingRfqs'),
            isActive: filter.tab === 'automating-rfqs',
            onSelect: () => handleFilter('tab', 'automating-rfqs'),
            count: dataClassification?.data?.automatingRfqs || 0,
          },
          {
            title: t('quotationRequestTabInvalid'),
            isActive: filter.tab === 'invalid',
            onSelect: () => handleFilter('tab', 'invalid'),
            count: dataClassification?.data?.invalid || 0,
          },
          {
            title: t('quotationRequestTabExpired'),
            isActive: filter.tab === 'expired',
            onSelect: () => handleFilter('tab', 'expired'),
            count: dataClassification?.data?.expired || 0,
          },
        ]}
      />

      <BusinessMetricCards
        metrics={metricsByTabs[filter.tab || 'all']?.map(
          (key) => metricsComponents[key],
        )}
      />

      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('tableSearchPlaceholder'),
            }}
          />

          <Button
            variant="secondary"
            onClick={() => setIsOpenDrawerFilter(true)}
          >
            <FilterIcon />
            {t('quotationRequestTableFilterButton')}
            {getContentTotalFiltered()}
          </Button>

          <Ordering />
        </div>
      </div>

      <TableColumns<QuotationRequestProps>
        isLoading={isLoading || isPending}
        columns={columns}
        expandableComponent={ExpandedComponent}
        classNames={{
          bodyColumn,
        }}
        data={data?.data?.results || []}
      />
      {!isLoading && data?.data?.results?.length === 0 && (
        <EmptyState
          title={t('quotationRequestTableEmptyTitle')}
          description={t('quotationRequestTableEmptyDescription')}
        />
      )}

      {data?.data?.results && data.data.results.length >= 1 && (
        <div className={styles.pagination}>
          <div className={styles['pagination-show-registers']}>
            <Text>
              {t('quotationRequestTableShowRegisters', {
                showNumber:
                  (data?.data?.count || 0) < +filter.limit
                    ? data?.data?.count
                    : +filter.limit,
                totalNumber: data?.data?.count || 0,
              })}
            </Text>

            <Select
              value={filter.limit}
              onChange={(value) => handleFilter('limit', value)}
              options={[
                { label: '10', value: '10' },
                { label: '20', value: '20' },
                { label: '50', value: '50' },
                { label: '100', value: '100' },
              ]}
            />
          </div>

          <Pagination
            totalPages={totalPages}
            onChange={(page) => handleFilter('page', page)}
            currentPage={+filter.page as number}
          />
        </div>
      )}

      <QuotationRequestDrawerFilter
        isOpen={isOpenDrawerFilter}
        onHandleOpen={() => setIsOpenDrawerFilter((prev) => !prev)}
        filter={filter}
        onHandleFilter={setFilter}
      />

      <QuotationRequestDrawerForm
        isOpen={isOpenDrawerForm}
        onHandleOpen={onHandleOpenDrawerForm}
        quotationRequestUuid={quotationRequestUuid}
      />
    </div>
  )
}
