import { Button, Modal, Text, WarningOutlineIcon } from '@mercai/clever'

import { useTranslation } from 'react-i18next'

import { QuotationDialogProps } from './quotation-dialog.types'

import styles from './quotation-dialog.module.css'

export const QuotationDialog = ({
  isOpen,
  onHandleClose,
  onHandleSubmit,
}: QuotationDialogProps) => {
  const { t } = useTranslation('screenBusinessV2')

  return (
    <Modal.Root isOpen={isOpen} onHandleOpen={onHandleClose} size="small">
      <Modal.Body className={styles['modal-body']}>
        <div className={styles['modal-header']}>
          <div className={styles.icon}>
            <WarningOutlineIcon />
          </div>
          <Text variant="text-large-bold">{t('quotationDialogTitle')}</Text>
        </div>
        <Text variant="text-base">{t('quotationDialogDescription')}</Text>
        <div className={styles['modal-footer']}>
          <Button variant="secondary" onClick={onHandleClose}>
            {t('quotationDialogCancelButton')}
          </Button>
          <Button variant="primary" onClick={onHandleSubmit}>
            {t('quotationDialogConfirmButton')}
          </Button>
        </div>
      </Modal.Body>
    </Modal.Root>
  )
}
