import * as Yup from 'yup'

export const buyerSchema = (t: (key: string) => string) => {
  return {
    companyName: Yup.string().required(t('requiredMessage')),
    email: Yup.string()
      .email(t('invalidFormatMessage'))
      .required(t('requiredMessage')),
    phone: Yup.string().required(t('requiredMessage')),
  }
}
