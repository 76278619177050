import {
  Input,
  InputPhone,
  Label,
  Tooltip,
  QuestionFillIcon,
  Radio,
  Text,
  Button,
  PlusIcon,
  Select,
  InputDebounce,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { SupplierFormProps } from './supplier-form.type'

import styles from './supplier-form.module.css'
import { BaseAddressProps, SupplierWarehouseProps } from '@/types'
import { deepCopy } from '@/helpers'
import { useGetAddress } from '@/services'
import { useState } from 'react'
import { AddressOptions } from './components'

const defaultWarehouse: SupplierWarehouseProps = {
  complement: '',
  number: '',
  reference: '',
  city: '',
  country: '',
  neighborhood: '',
  postalCode: '',
  state: '',
  street: '',
}

export const SupplierForm = ({
  onChangeValues,
  values,
  errors,
}: SupplierFormProps) => {
  const { t } = useTranslation('screenSupplierList')

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const { mutateAsync, isPending } = useGetAddress()

  const [selectedAddress, setSelectedAddress] = useState<BaseAddressProps[]>([])
  const [address, setAddress] = useState<BaseAddressProps[][]>([])

  const onSelectAddress = (index: number, address: BaseAddressProps) => {
    const valuesAux = deepCopy(values)

    if (valuesAux.supplierWarehouse?.[index]) {
      valuesAux.supplierWarehouse[index] = {
        ...valuesAux.supplierWarehouse[index],
        city: address?.city,
        country: address?.country,
        neighborhood: address?.neighborhood,
        postalCode: address?.postalCode,
        state: address?.state,
      }

      onChangeValues(valuesAux)
    }

    const selectedAddressAux = [...(selectedAddress || [])]
    selectedAddressAux[index] = address
    setSelectedAddress(selectedAddressAux)
  }

  const onHandleWarehouseValues = (
    index: number,
    key: string,
    value: string | number,
  ) => {
    const valuesAux = deepCopy(values)

    if (valuesAux?.supplierWarehouse?.[index]) {
      valuesAux.supplierWarehouse[index] = {
        ...valuesAux.supplierWarehouse[index],
        [key]: value,
      }
    }

    onChangeValues({ ...valuesAux })
  }

  const onHandleAddWarehouse = () => {
    const valuesAux = deepCopy(values)

    if (valuesAux?.supplierWarehouse) {
      valuesAux.supplierWarehouse.push(defaultWarehouse)
    } else {
      valuesAux.supplierWarehouse = [defaultWarehouse]
    }

    onChangeValues({ ...valuesAux })
  }

  const onSearchAddress = async (index: number, value: string) => {
    if (!values?.supplierWarehouse?.[index]?.country) {
      onHandleWarehouseValues(index, 'country', 'MX')
    }

    onHandleWarehouseValues(index, 'postalCode', value)

    if (!value) return

    const response = await mutateAsync({
      postalCode: value,
      country: (values?.supplierWarehouse?.[index]?.country as 'MX') || 'MX',
    })

    if (response.success && response.data?.length === 1) {
      const selectedAddressAux = [...(selectedAddress || [])]
      selectedAddressAux[index] = response?.data?.[0]
      setSelectedAddress(selectedAddressAux)

      const valuesAux = deepCopy(values)

      if (valuesAux.supplierWarehouse?.[index]) {
        valuesAux.supplierWarehouse[index] = {
          ...valuesAux.supplierWarehouse[index],
          city: response?.data?.[0]?.city,
          country: response?.data?.[0]?.country,
          neighborhood: response?.data?.[0]?.neighborhood,
          postalCode: response?.data?.[0]?.postalCode,
          state: response?.data?.[0]?.state,
        }

        onChangeValues(valuesAux)
      }
    }

    const addressAux = address
    addressAux[index] = response.data || []
    setAddress(addressAux)
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('supplierFormProfileFormNameLabel')}</Label>
        <Input
          placeholder={t('supplierFormProfileFormNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name as string}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormEmailLabel')}{' '}
          <span>{t('optionalLabel')}</span>
        </Label>
        <Input
          placeholder={t('supplierFormProfileFormEmailPlaceholder')}
          value={values?.email}
          onChange={(newValue) => onHandleValues('email', newValue)}
          error={errors?.email as string}
        />
      </div>

      <div>
        <Label>
          {t('supplierFormProfileFormPhoneLabel')}
          <span>{t('optionalLabel')}</span>
        </Label>
        <InputPhone
          placeholder={t('supplierFormProfileFormPhonePlaceholder')}
          value={values?.phone}
          onChange={(newValue) => onHandleValues('phone', newValue)}
          error={errors?.phone as string}
        />
      </div>

      <div>
        <div>
          <Label>
            {t('supplierFormProfileFormForeignLabel')}
            <Tooltip
              text={t('supplierFormProfileFormForeignDescription')}
              widthText="16rem"
            >
              <QuestionFillIcon />
            </Tooltip>
          </Label>
        </div>

        <Radio
          value={!!values?.isForeign}
          onChange={() => onHandleValues('isForeign', true)}
        >
          {t('supplierFormProfileFormForeignOptionsYes')}
        </Radio>

        <Radio
          value={!values?.isForeign}
          onChange={() => onHandleValues('isForeign', false)}
        >
          {t('supplierFormProfileFormForeignOptionsNo')}
        </Radio>
      </div>

      <div>
        {values?.supplierWarehouse?.map((supplierWarehouse, i) => (
          <div key={`supplier-warehouse-${i}`} className={styles.warehouse}>
            <Text
              variant="text-large-bold"
              className={styles['warehouse-title']}
            >
              {t('supplierFormProfileFormWarehouseTitle')}
              {Array.isArray(values?.supplierWarehouse) &&
                values?.supplierWarehouse.length > 1 &&
                ` ${i + 1}`}
            </Text>
            <div className={styles.row}>
              <div>
                <Label>{t('supplierFormProfileFormWarehouseNameLabel')}</Label>
                <Input
                  placeholder={t(
                    'supplierFormProfileFormWarehouseNamePlaceholder',
                  )}
                  value={supplierWarehouse?.name}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'name', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.name}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseCountryLabel')}
                </Label>
                <Select
                  placeholder={t(
                    'supplierFormProfileFormWarehouseCountryPlaceholder',
                  )}
                  options={[
                    {
                      label: t(
                        'supplierFormProfileFormWarehouseCountryMxLabel',
                      ),
                      value: 'MX',
                    },
                    {
                      label: t(
                        'supplierFormProfileFormWarehouseCountryUsLabel',
                      ),
                      value: 'US',
                    },
                  ]}
                  value={supplierWarehouse?.country}
                  onChange={(newValue) =>
                    onHandleWarehouseValues(i, 'country', newValue)
                  }
                  error={errors?.[`supplierWarehouse[${i}]`]?.country}
                />
              </div>
              <div>
                <Label>
                  {t('supplierFormProfileFormWarehouseZipCodeLabel')}
                </Label>
                <InputDebounce
                  placeholder={t(
                    'supplierFormProfileFormWarehouseZipCodePlaceholder',
                  )}
                  value={supplierWarehouse?.postalCode || ''}
                  onChange={(newValue) => onSearchAddress(i, newValue)}
                  error={errors?.[`supplierWarehouse[${i}]`]?.postalCode}
                />
              </div>
            </div>

            <AddressOptions
              addresses={address[i] || []}
              onSelectAddress={(address) => onSelectAddress(i, address)}
              selectedAddress={selectedAddress[i]}
              isLoading={isPending}
            />

            <div className={styles.card}>
              <div className={styles.row}>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseStateLabel')}
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseStatePlaceholder',
                    )}
                    value={supplierWarehouse?.state}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'state', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.state}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseCityLabel')}
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseCityPlaceholder',
                    )}
                    value={supplierWarehouse?.city}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'city', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.city}
                  />
                </div>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseNeighborhoodLabel')}
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseNeighborhoodPlaceholder',
                    )}
                    value={supplierWarehouse?.neighborhood}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'neighborhood', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.neighborhood}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseStreetLabel')}
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseStreetPlaceholder',
                    )}
                    value={supplierWarehouse?.street}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'street', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.street}
                  />
                </div>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseNumberLabel')}
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseNumberPlaceholder',
                    )}
                    value={supplierWarehouse?.number}
                    type="number"
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'number', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.number}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseComplementLabel')}
                    <span>{t('optionalLabel')}</span>
                  </Label>
                  <Input
                    placeholder={t(
                      'supplierFormProfileFormWarehouseComplementPlaceholder',
                    )}
                    value={supplierWarehouse?.complement}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'complement', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.complement}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <Label>
                    {t('supplierFormProfileFormWarehouseReferenceLabel')}
                    <span>{t('optionalLabel')}</span>
                  </Label>
                  <Input
                    value={supplierWarehouse?.reference}
                    onChange={(newValue) =>
                      onHandleWarehouseValues(i, 'reference', newValue)
                    }
                    error={errors?.[`supplierWarehouse[${i}]`]?.reference}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className={styles.row}>
          <Button variant="secondary" onClick={onHandleAddWarehouse}>
            {t('supplierFormProfileFormWarehouseAddButton')}
            <PlusIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
