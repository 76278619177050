import {
  Input,
  InputDebounce,
  Label,
  MultiSelect,
  Radio,
  Select,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AddressFormProps } from './address-form.type'

import styles from './address-form.module.css'
import { useGetAddress } from '@/services'
import { useState } from 'react'
import { BaseAddressProps, LogisticShipmentVehicleTypeEnum } from '@/types'
import { AddressOptions } from './components'

export const AddressForm = ({
  onChangeValues,
  values,
  errors,
}: AddressFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationList')

  const { data: addressResponse, mutateAsync, isPending } = useGetAddress()

  const [selectedAddress, setSelectedAddress] = useState<BaseAddressProps>()

  const onHandleValues = (
    key: string,
    value: boolean | string | number | string[],
  ) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onSelectAddress = (address: BaseAddressProps) => {
    onChangeValues({
      ...values,
      city: address?.city,
      country: address?.country,
      neighborhood: address?.neighborhood,
      postalCode: address?.postalCode,
      state: address?.state,
    })
    setSelectedAddress(address)
  }

  const onSearchAddress = async (value: string) => {
    const country = (values?.country || 'MX') as 'MX' | 'US'
    onChangeValues({ ...values, country, postalCode: value })

    if (!values?.postalCode) return

    const response = await mutateAsync({
      postalCode: value,
      country,
    })

    if (response.success && response.data?.length === 1) {
      setSelectedAddress(response?.data?.[0])

      onChangeValues({
        ...values,
        city: response?.data?.[0]?.city,
        country: response?.data?.[0]?.country,
        neighborhood: response?.data?.[0]?.neighborhood,
        postalCode: response?.data?.[0]?.postalCode,
        state: response?.data?.[0]?.state,
      })
    }
  }

  return (
    <div className={styles.core}>
      <div className={styles.flex}>
        <div style={{ flex: 1 }}>
          <Label>{t('quotationFormAddressCountryCode')}</Label>
          <Select
            value={values?.country}
            options={[
              {
                label: t('quotationFormAddressCountryMXCode'),
                value: 'MX',
              },
              {
                label: t('quotationFormAddressCountryUSCode'),
                value: 'US',
              },
            ]}
            onChange={(value) => {
              onHandleValues('country', value)
            }}
          />
        </div>
        <div>
          <Label>{t('quotationFormAddressFormPostalCode')}</Label>
          <InputDebounce
            value={values?.postalCode || ''}
            onChange={onSearchAddress}
            error={errors?.postalCode ? (errors?.postalCode as string) : ''}
          />
        </div>
      </div>

      {(addressResponse?.data || isPending) && (
        <AddressOptions
          addresses={addressResponse?.data || []}
          onSelectAddress={onSelectAddress}
          selectedAddress={selectedAddress}
          isLoading={isPending}
        />
      )}

      <div className={styles.card}>
        <div>
          <Label>{t('quotationFormAddressFormState')}</Label>
          <Input
            value={values?.state}
            onChange={(newValue) => onHandleValues('state', newValue)}
            error={errors?.state}
          />
        </div>

        <div>
          <Label>{t('quotationFormAddressFormCity')}</Label>
          <Input
            value={values?.city}
            onChange={(newValue) => onHandleValues('city', newValue)}
            error={errors?.city}
          />
        </div>

        <div>
          <Label>{t('quotationFormAddressFormNeighborhood')}</Label>
          <Input
            value={values?.neighborhood}
            onChange={(newValue) => onHandleValues('neighborhood', newValue)}
            error={errors?.neighborhood}
          />
        </div>

        <div className={styles.row}>
          <div>
            <Label>{t('quotationFormAddressFormStreet')}</Label>
            <Input
              value={values?.street}
              onChange={(newValue) => onHandleValues('street', newValue)}
              error={errors?.street}
            />
          </div>

          <div>
            <Label>{t('quotationFormAddressFormNumber')}</Label>
            <Input
              value={values?.number ? String(values?.number) : ''}
              onChange={(newValue) => onHandleValues('number', newValue)}
              error={errors?.number}
            />
          </div>
        </div>

        <div>
          <Label>
            {t('quotationFormAddressFormReference')}{' '}
            <span>{t('quotationFormAddressFormFieldOptional')}</span>
          </Label>
          <Input
            isTextArea
            value={values?.reference}
            onChange={(newValue) => onHandleValues('reference', newValue)}
            error={errors?.reference}
          />
        </div>
        <div>
          <Label>
            {t('quotationFormAddressFormNeedsUnloadingSupport')}{' '}
            <span>{t('quotationFormAddressFormFieldOptional')}</span>
          </Label>
          <Radio
            value={!!values?.needsUnloadingSupport}
            onChange={() => onHandleValues('needsUnloadingSupport', true)}
          >
            {t('quotationFormAddressFormYes')}
          </Radio>

          <Radio
            value={!values?.needsUnloadingSupport}
            onChange={() => onHandleValues('needsUnloadingSupport', false)}
          >
            {t('quotationFormAddressFormNo')}
          </Radio>
        </div>
        <div>
          <Label>
            {t('quotationFormAddressFormRestrictedVehicles')}{' '}
            <span>{t('quotationFormAddressFormFieldOptional')}</span>
          </Label>
          <MultiSelect
            options={Object.values(LogisticShipmentVehicleTypeEnum).map(
              (value) => ({
                label: tEnums(`logistic_shipment_type_${value}`),
                value,
              }),
            )}
            values={values.restrictedVehicles}
            onChange={(newValue) =>
              onHandleValues('restrictedVehicles', newValue)
            }
          />
        </div>
      </div>
    </div>
  )
}
