import { SVGProps } from 'react'

export const ExpandCircleDownIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.0535 6.39398L8.00016 8.44732L5.94683 6.39398L5.00016 7.33398L8.00016 10.334L11.0002 7.33398L10.0535 6.39398ZM8.00016 1.33398C4.32016 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.32016 14.6673 8.00016 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 8.00016 1.33398ZM8.00016 13.334C5.0535 13.334 2.66683 10.9473 2.66683 8.00065C2.66683 5.05398 5.0535 2.66732 8.00016 2.66732C10.9468 2.66732 13.3335 5.05398 13.3335 8.00065C13.3335 10.9473 10.9468 13.334 8.00016 13.334Z" />
    </svg>
  )
}
