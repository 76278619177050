const AUTH_BASE = `/auth/`

const AUTH_SIGN_IN = `sign-in`
const AUTH_SIGN_OUT = `sign-out`

const ADMIN_BASE = `/`

const ADMIN_BUSINESS = `business`
const ADMIN_BUSINESS_RFQ_OLD = `quotation-request`
const ADMIN_BUSINESS_RFQ = `quotation-request-v2`
const ADMIN_BUSINESS_QUOTATION_OLD = `quotation`
const ADMIN_BUSINESS_QUOTATION = `quotation-v2`
const ADMIN_BUSINESS_ORDER = `order`

const ADMIN_PROFILE = `profile`

const ADMIN_CATALOG = `catalog`
const ADMIN_CATALOG_PRODUCT = `product`
const ADMIN_CATALOG_PROCESSOR = `processor`

const ADMIN_SUPPLY = 'supply'
const ADMIN_SUPPLY_SUPPLIERS = 'suppliers'
const ADMIN_SUPPLY_TRANSPORT_COMPANIES = 'transport-companies'

const ADMIN_ANALYTICS = `analytics`
const ADMIN_ANALYTICS_ORDER_TRACKER = `order-tracker`
const ADMIN_ANALYTICS_DASHBOARD = `dashboard`
const ADMIN_ANALYTICS_TOTAL_ECONOMICS = `total-economics`

const ADMIN_ORDER_CENTER = `order-center`
const ADMIN_ORDER_CENTER_ALL = `all`
const ADMIN_ORDER_CENTER_SALE = `sale`
const ADMIN_ORDER_CENTER_PROFILE = `profile`
const ADMIN_ORDER_CENTER_FINANCE = `finance`
const ADMIN_ORDER_CENTER_LOGISTIC = `logistic`

const ADMIN_AI_CENTER = `ai-center`
const ADMIN_AI_AGENT = `agent`
const ADMIN_AI_CONVERSATION = `conversation`
const ADMIN_BOARD = `board`
const BOARD_SALE = `sale-board`

export const ROUTES_AUTH = {
  path: AUTH_BASE,
  fullPath: () => AUTH_BASE,

  SIGN_IN: {
    path: AUTH_SIGN_IN,
    fullPath: () => `${AUTH_BASE}${AUTH_SIGN_IN}`,
  },
  SIGN_OUT: {
    path: AUTH_SIGN_OUT,
    fullPath: () => `${AUTH_BASE}${AUTH_SIGN_OUT}`,
  },
}

export const ROUTES_ADMIN = {
  path: ADMIN_BASE,
  fullPath: () => ADMIN_BASE,

  BOARD: {
    path: ADMIN_BOARD,
    fullPath: () => `${ADMIN_BASE}${ADMIN_BOARD}`,

    SALE: {
      path: `${ADMIN_BOARD}/${BOARD_SALE}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_BOARD}/${BOARD_SALE}`,
    },
  },

  BUSINESS: {
    path: `${ADMIN_BUSINESS}/:page`,
    fullPath: (page: string) => `${ADMIN_BASE}${ADMIN_BUSINESS}/${page}`,

    RFQ_OLD: {
      path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ_OLD}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ_OLD}`,

      SHOW: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ_OLD}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ_OLD}/${uuid}`,
      },
    },

    RFQ: {
      path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ}`,

      SHOW: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ}/generate-quotation/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_RFQ}/generate-quotation/${uuid}`,
      },
    },

    QUOTATION_OLD: {
      path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION_OLD}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION_OLD}`,

      SHOW: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION_OLD}?show=:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION_OLD}?show=true&uuid=${uuid}`,
      },
    },

    QUOTATION: {
      path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}`,

      SHOW: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}/${uuid}`,
      },

      UPDATE: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}/update/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_QUOTATION}/update/${uuid}`,
      },
    },

    ORDER: {
      path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_ORDER}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_ORDER}`,

      SHOW: {
        path: `${ADMIN_BUSINESS}/${ADMIN_BUSINESS_ORDER}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_BUSINESS}/${ADMIN_BUSINESS_ORDER}?show=true&uuid=${uuid}`,
      },
    },
  },

  CATALOG: {
    path: ADMIN_CATALOG,
    fullPath: () => `${ADMIN_BASE}${ADMIN_CATALOG}`,

    PRODUCT: {
      path: `${ADMIN_CATALOG}/${ADMIN_CATALOG_PRODUCT}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_CATALOG}/${ADMIN_CATALOG_PRODUCT}`,

      SHOW: {
        path: `${ADMIN_CATALOG}/${ADMIN_CATALOG_PRODUCT}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_CATALOG}/${ADMIN_CATALOG_PRODUCT}/${uuid}`,
      },
    },

    PROCESSOR: {
      path: `${ADMIN_CATALOG}/${ADMIN_CATALOG_PROCESSOR}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_CATALOG}/${ADMIN_CATALOG_PROCESSOR}`,
    },
  },

  PROFILE: {
    path: ADMIN_PROFILE,
    fullPath: () => `${ADMIN_BASE}${ADMIN_PROFILE}`,

    SHOW: {
      path: `${ADMIN_PROFILE}/:uuid`,
      fullPath: (uuid: string) => `${ADMIN_BASE}${ADMIN_PROFILE}/${uuid}`,
    },
  },
  SUPPLY: {
    path: ADMIN_SUPPLY,
    fullPath: () => `${ADMIN_BASE}${ADMIN_SUPPLY}`,

    SUPPLIERS: {
      path: `${ADMIN_SUPPLY}/${ADMIN_SUPPLY_SUPPLIERS}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_SUPPLY}/${ADMIN_SUPPLY_SUPPLIERS}`,

      SHOW: {
        path: `${ADMIN_SUPPLY}/${ADMIN_SUPPLY_SUPPLIERS}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_SUPPLY}/${ADMIN_SUPPLY_SUPPLIERS}/${uuid}`,
      },
    },

    TRANSPORT_COMPANIES: {
      path: `${ADMIN_SUPPLY}/${ADMIN_SUPPLY_TRANSPORT_COMPANIES}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_SUPPLY}/${ADMIN_SUPPLY_TRANSPORT_COMPANIES}`,

      SHOW: {
        path: `${ADMIN_SUPPLY}/${ADMIN_SUPPLY_TRANSPORT_COMPANIES}/:uuid`,
        fullPath: (uuid: string) =>
          `${ADMIN_BASE}${ADMIN_SUPPLY}/${ADMIN_SUPPLY_TRANSPORT_COMPANIES}/${uuid}`,
      },
    },
  },
  ANALYTICS: {
    path: ADMIN_ANALYTICS,
    fullPath: () => `${ADMIN_BASE}${ADMIN_ANALYTICS}`,

    DASHBOARD: {
      path: `${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_DASHBOARD}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_DASHBOARD}`,
    },

    ORDER_TRACKER: {
      path: `${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_ORDER_TRACKER}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_ORDER_TRACKER}`,
      FILTER: {
        path: `${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_ORDER_TRACKER}`,
        fullPath: (search: string) =>
          `${ADMIN_BASE}${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_ORDER_TRACKER}?search=${search}`,
      },
    },

    TOTAL_ECONOMICS: {
      path: `${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_TOTAL_ECONOMICS}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ANALYTICS}/${ADMIN_ANALYTICS_TOTAL_ECONOMICS}`,
    },
  },
  ORDER_CENTER: {
    path: `${ADMIN_ORDER_CENTER}/:page`,
    fullPath: (page: string) => `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${page}`,

    ALL: {
      path: `${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_ALL}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_ALL}`,
    },

    SALE: {
      path: `${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_SALE}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_SALE}`,
    },

    PROFILE: {
      path: `${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_PROFILE}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_PROFILE}`,
    },

    FINANCE: {
      path: `${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_FINANCE}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_FINANCE}`,
    },

    LOGISTIC: {
      path: `${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_LOGISTIC}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_ORDER_CENTER}/${ADMIN_ORDER_CENTER_LOGISTIC}`,
    },
  },

  AI_CENTER: {
    path: `${ADMIN_AI_CENTER}/:page`,
    fullPath: (page: string) => `${ADMIN_BASE}${ADMIN_AI_CENTER}/${page}`,

    AGENT: {
      path: `${ADMIN_AI_CENTER}/${ADMIN_AI_AGENT}`,
      fullPath: () => `${ADMIN_BASE}${ADMIN_AI_CENTER}/${ADMIN_AI_AGENT}`,
    },

    CONVERSATION: {
      path: `${ADMIN_AI_CENTER}/${ADMIN_AI_CONVERSATION}`,
      fullPath: () =>
        `${ADMIN_BASE}${ADMIN_AI_CENTER}/${ADMIN_AI_CONVERSATION}`,
    },
  },
}
