import { DragDropFileList, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { getFileNameAndExtension } from '@/helpers'
import { api, QUOTATION_REQUEST_UPLOAD_URL } from '@/services'

import { UploadFileProps } from './upload-file.type'

import styles from './upload-file.module.css'

export const UploadFile = ({
  onSetFile,
  errorMissingFile,
  file,
}: UploadFileProps) => {
  const { t } = useTranslation('screenQuotationRequestListV2')

  const getFileValue = () => {
    if (!file) {
      return []
    }

    const { fileName, extension } = getFileNameAndExtension(file)

    return [
      {
        id: new Date().getTime(),
        name: `${fileName}.${extension}`,
        url: file as string,
        uploaded: true,
      },
    ]
  }

  return (
    <div className={styles.core}>
      <Text>{t('uploadFileDescription')}</Text>

      <DragDropFileList
        axiosInstance={api}
        urlAdd={QUOTATION_REQUEST_UPLOAD_URL}
        configsRequest={{
          fileField: 'file',
          method: 'POST',
        }}
        initialData={getFileValue()}
        successedUpload={(file) => onSetFile(`${file?.filename}`)}
        translations={{
          clickToUpload: t('uploadFileButton'),
          dragFile: t('uploadFileDrag'),
          sizeFile: t('uploadFileSize'),
        }}
        error={errorMissingFile ? t('uploadFileErrorMissingFile') : undefined}
      />
    </div>
  )
}
