import {
  SummaryValues as SummaryValuesDS,
  Skeleton,
  Text,
  MonetizationIcon,
} from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import { formatCurrency } from '@/helpers'

import { CardItem } from '../card-item/card-item'

import type { SummaryValuesProps } from './summary-values.type'

import styles from './summary-values.module.css'

const QuotationSummaryValuesDefault = ({
  totalFreight,
  iva,
  subTotal,
  total,
  discount,
  paymentForm,
}: SummaryValuesProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationV2Show')

  return (
    <CardItem title="Total" icon={<MonetizationIcon />}>
      <div className={styles['payment-form']}>
        <Text>{t('quotationShowSummaryPaymentFormOption')}:</Text>
        <Text>
          <strong>
            {paymentForm
              ? tEnums(`financial_profile_payment_forms_${paymentForm}`)
              : '-'}
          </strong>
        </Text>
      </div>

      <div className={styles.values}>
        <SummaryValuesDS.Root>
          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              {t('quotationShowSummaryValuesSubTotal')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              {formatCurrency(subTotal)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          {!discount || (
            <SummaryValuesDS.Row>
              <SummaryValuesDS.Column type="label">
                {t('quotationShowSummaryValuesDiscount')}
              </SummaryValuesDS.Column>
              <SummaryValuesDS.Column type="value" direction="right">
                - {formatCurrency(discount)}
              </SummaryValuesDS.Column>
            </SummaryValuesDS.Row>
          )}

          {iva > 0 && (
            <SummaryValuesDS.Row>
              <SummaryValuesDS.Column type="label">
                {t('quotationShowSummaryValuesIVA')}
              </SummaryValuesDS.Column>
              <SummaryValuesDS.Column type="value" direction="right">
                {formatCurrency(iva)}
              </SummaryValuesDS.Column>
            </SummaryValuesDS.Row>
          )}

          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              {t('quotationShowSummaryValuesDelivery')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              {formatCurrency(totalFreight)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          <SummaryValuesDS.Row type="strip">
            <SummaryValuesDS.Column type="bookend">
              {t('quotationShowSummaryValuesAmount')}
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="bookend" direction="right">
              {formatCurrency(total)}
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>
        </SummaryValuesDS.Root>
      </div>
    </CardItem>
  )
}

const QuotationSummaryValuesLoading = () => {
  return (
    <CardItem title="Total" icon={<MonetizationIcon />}>
      <div className={styles['payment-form']}>
        <Skeleton width="100%" height="1.5rem" />
      </div>

      <div className={styles.values}>
        <SummaryValuesDS.Root>
          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="bookend">
              <Skeleton width="5rem" height="1.5rem" />
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="bookend" direction="right">
              <Skeleton width="3rem" height="1.5rem" />
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              <Skeleton width="7rem" height="1rem" />
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              <Skeleton width="6rem" height="1rem" />
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              <Skeleton width="7rem" height="1rem" />
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              <Skeleton width="6rem" height="1rem" />
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          <SummaryValuesDS.Row>
            <SummaryValuesDS.Column type="label">
              <Skeleton width="7rem" height="1rem" />
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="value" direction="right">
              <Skeleton width="6rem" height="1rem" />
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>

          <SummaryValuesDS.Row type="strip">
            <SummaryValuesDS.Column type="bookend">
              <Skeleton width="5rem" height="1.6rem" />
            </SummaryValuesDS.Column>
            <SummaryValuesDS.Column type="bookend" direction="right">
              <Skeleton width="7rem" height="1.6rem" />
            </SummaryValuesDS.Column>
          </SummaryValuesDS.Row>
        </SummaryValuesDS.Root>
      </div>
    </CardItem>
  )
}

export const QuotationSummaryValues = (props: SummaryValuesProps) => {
  if (props.isLoading) {
    return <QuotationSummaryValuesLoading />
  }

  return <QuotationSummaryValuesDefault {...props} />
}
