export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)

  const dateOptions: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }

  const formattedDate = date.toLocaleDateString('es-ES', dateOptions)
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions)

  return `${formattedDate} ${formattedTime}`
}
