import styles from './container.module.css'

import { ContainerProps } from './container.type'

const Container = ({ children, className }: ContainerProps) => {
  return (
    <div className={`${styles.container} ${className || ''}`}>{children}</div>
  )
}

export default Container
