import { CalculateMarginProps } from '@/types'
import styles from './quotation-metrics.module.css'

export const variants: Record<string, string> = {
  danger: styles['icon--danger'],
  success: styles['icon--success'],
  warning: styles['icon--warning'],
}

export const textVariants: Record<string, string> = {
  danger: styles.danger,
  success: styles.success,
  warning: styles.warning,
}

export const generateGrossMarginClasses = (grossMargin: number) => {
  if (grossMargin <= 0) return `${textVariants.danger}`

  if (grossMargin > 0 && grossMargin < 0.07) return `${textVariants.warning}`

  if (grossMargin >= 0.07) return `${textVariants.success}`

  return ``
}

export const generateCm3MarginClasses = (cm3Margin: number) => {
  if (cm3Margin <= 0) return `${textVariants.danger}`

  if (cm3Margin > 0 && cm3Margin < 0.02) return `${textVariants.warning}`

  if (cm3Margin >= 0.02) return `${textVariants.success}`

  return ``
}

export const generateIconClasses = (data: CalculateMarginProps) => {
  if (data?.grossMargin <= 0 || data?.cm3Margin <= 0)
    return `${styles.icon} ${variants.danger}`

  if (
    (data?.grossMargin > 0 && data?.grossMargin < 0.07) ||
    (data?.cm3Margin > 0 && data?.cm3Margin < 0.02)
  )
    return `${styles.icon} ${variants.warning}`

  if (data?.grossMargin >= 0.07 && data?.cm3Margin >= 0.02)
    return `${styles.icon} ${variants.success}`

  return `${styles.icon} `
}
