import { DrawerStepperProgress } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { ProductDrawerFormProps } from './product-drawer-form.type'

import { ProductForm } from '../product-form'

export const ProductDrawerForm = ({
  isOpen,
  onHandleOpen,
  values,
  onChangeValues,
  onHandleSubmit,
  catalogCategoryList = [],
}: ProductDrawerFormProps) => {
  const { t } = useTranslation('screenBusinessV2')
  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      initialStep="root"
      onHandleOpen={() => onHandleOpen?.()}
      translations={{ optional: '' }}
      title={
        values?.uuid
          ? t('productDrawerFormUpdateTitle')
          : t('productDrawerFormAddTitle')
      }
      steps={{
        root: {
          backNavigate: '',
          isOptional: false,
          component: ({ onNavigateToPreviousStep }) => (
            <ProductForm
              values={values}
              onChangeValues={onChangeValues}
              onHandleSubmit={onHandleSubmit}
              onNavigateToPreviousStep={onNavigateToPreviousStep}
              catalogCategoryList={catalogCategoryList}
            />
          ),
        },
      }}
    />
  )
}
