import { Radio, Text } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { PaymentFormProps } from './payment-form.type'

import styles from './payment-form.module.css'
import {
  FinanceProfilePaymentFormsEnum,
  FinanceProfileTransactionPaymentMethodsEnum,
} from '@/types'

export const PaymentForm = ({
  onChangeValues,
  values,
  financeProfileData,
}: PaymentFormProps) => {
  const { t } = useTranslation('screenQuotationV2List')
  const { t: tEnums } = useTranslation('enums')

  const onHandleValues = (key: string, value: string | number) => {
    onChangeValues({ ...values, [key]: value })
  }

  const paymentFormDescriptions: Record<
    keyof typeof FinanceProfilePaymentFormsEnum,
    string
  > = {
    upfront: t('quotationFormPaymentFormUpFrontDescription'),
    partial: t('quotationFormPaymentFormPartialDescription', {
      value:
        (financeProfileData?.upfrontPaymentPercentage
          ? +financeProfileData.upfrontPaymentPercentage
          : 0) * 100,
      value2:
        100 -
        (financeProfileData?.upfrontPaymentPercentage
          ? +financeProfileData.upfrontPaymentPercentage
          : 0) *
          100,
    }),
    on_delivery: t('quotationFormPaymentFormOnDeliveryDescription'),
    xepelin_credit: t('quotationFormPaymentFormXepelinCreditDescription'),
    internal_credit: t('quotationFormPaymentFormInternalCreditDescription'),
  }

  const getPaymentForms = (
    key: keyof typeof FinanceProfilePaymentFormsEnum,
  ) => {
    return (
      <Radio
        key={key}
        value={values?.paymentFormOption === key}
        onChange={() => onHandleValues('paymentFormOption', key)}
      >
        <div className={styles['radio-label']}>
          <p className={styles['radio-label-title']}>
            {tEnums(`financial_profile_payment_forms_${key}`)}
          </p>
          <p>{paymentFormDescriptions[key]}</p>
        </div>
      </Radio>
    )
  }

  const getPaymentMethods = (
    key: keyof typeof FinanceProfileTransactionPaymentMethodsEnum,
  ) => {
    return (
      <Radio
        key={key}
        value={values?.paymentMethodOption === key}
        onChange={() => onHandleValues('paymentMethodOption', key)}
      >
        <div className={styles['radio-label']}>
          <p className={styles['radio-label-title']}>
            {tEnums(`financial_profile_transaction_payment_methods_${key}`)}
          </p>
        </div>
      </Radio>
    )
  }

  return (
    <div className={styles.core}>
      <section>
        <div className={styles['section-title']}>
          <Text variant="text-large-bold">
            {t('quotationFormPaymentFormConditionsTitle')}
          </Text>
          <Text variant="text-medium">
            {t('quotationFormPaymentFormConditionsSubtitle')}
          </Text>
        </div>
        <div className={styles['radios-box']}>
          {financeProfileData?.enabledPaymentForms.map((value) =>
            getPaymentForms(value),
          )}
        </div>
      </section>

      <section>
        <div className={styles['section-title']}>
          <Text variant="text-large-bold">
            {t('quotationFormPaymentFormPaymentMethodsTitle')}
          </Text>
          <Text variant="text-medium">
            {t('quotationFormPaymentFormPaymentMethodsSubtitle')}
          </Text>
        </div>
        <div className={styles['radios-box']}>
          {financeProfileData?.enabledPaymentMethods.map((value) =>
            getPaymentMethods(value),
          )}
        </div>
      </section>
    </div>
  )
}
