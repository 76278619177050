import {
  Button,
  DrawerStepperProgress,
  InputSearch,
  Radio,
  Skeleton,
  Text,
} from '@mercai/clever'

import { SelectCatalogProductDrawerProps } from './select-catalog-product-drawer.types'
import { useState } from 'react'
import { useCatalogProductList } from '@/services'

import styles from './select-catalog-product-drawer.module.css'
import { CatalogProductProps } from '@/types'
import { useTranslation } from 'react-i18next'

export const SelectCatalogProductDrawer = ({
  productItem,
  onSelectProductItem,
  isOpen,
  onHandleOpen,
}: SelectCatalogProductDrawerProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const [search, setSearch] = useState('')

  const { data, isLoading } = useCatalogProductList({ search, limit: 10 })

  const onHandleSelect = (productItem: CatalogProductProps) => {
    onSelectProductItem(productItem)
    onHandleOpen()
  }

  const Loading = () => {
    return [1, 2, 3].map((val) => (
      <div key={val} className={styles.product}>
        <Skeleton height="1.5rem" width="100%" />
        <Skeleton height="1.2rem" width="40%" />
        <Skeleton height="1.2rem" width="45%" />
        <Skeleton height="1.2rem" width="25%" />
      </div>
    ))
  }

  const ProductButton = ({ product }: { product: CatalogProductProps }) => {
    const { attributes, category, reference, uuid } = product
    return (
      <button
        key={uuid}
        className={`${styles.product} ${uuid === productItem?.uuid ? styles.active : ''}`}
        onClick={() => onHandleSelect(product)}
      >
        <div className={styles.radio}>
          <Radio
            value={uuid === productItem?.uuid}
            onChange={() => onHandleSelect(product)}
          />
        </div>
        <div>
          <div className={styles.title}>
            <Text variant="text-base">
              <strong>{attributes?.title}</strong>
            </Text>
          </div>
          <div className={styles.row}>
            <Text>
              <strong>{t('catalogProductsItemCategoryLabel')}:</strong>{' '}
              {category?.name}
            </Text>
          </div>

          {attributes?.fluidity && (
            <div className={styles.row}>
              <Text>
                <strong>{t('catalogProductsItemFluidityLabel')}:</strong>{' '}
                {attributes?.fluidity} g/10m
              </Text>
            </div>
          )}

          {attributes?.density && (
            <div className={styles.row}>
              <Text>
                <strong>{t('catalogProductsItemDensityLabel')}</strong>{' '}
                {attributes?.density} g/cm³
              </Text>
            </div>
          )}

          {reference && (
            <div className={styles.row}>
              <Text>
                <strong>SKU:</strong> {reference}
              </Text>
            </div>
          )}
        </div>
      </button>
    )
  }

  return (
    <DrawerStepperProgress
      isOpen={isOpen}
      onHandleOpen={onHandleOpen}
      initialStep="root"
      translations={{ optional: '' }}
      title={t('selectCatalogProductDrawerTitle')}
      description={t('selectCatalogProductDrawerDescription')}
      steps={{
        root: {
          isOptional: false,
          backNavigate: 'root',
          component: () => (
            <div className={styles.core}>
              <InputSearch
                value={search}
                onChange={setSearch}
                translations={{
                  inputPlaceholder: t(
                    'selectCatalogProductDrawerSearchPlaceholder',
                  ),
                }}
              />

              {isLoading && <Loading />}

              {!isLoading && (
                <div className={styles.products}>
                  {!data?.data?.results?.find(
                    (product) => product.uuid === productItem?.uuid,
                  ) &&
                    productItem && <ProductButton product={productItem} />}
                  {data?.data?.results?.map((product) => (
                    <ProductButton product={product} key={product?.uuid} />
                  ))}
                </div>
              )}
            </div>
          ),
          footer: () => (
            <>
              <Button variant="secondary" onClick={onHandleOpen}>
                {t('drawerFormBack')}
              </Button>
            </>
          ),
        },
      }}
    />
  )
}
