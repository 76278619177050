import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  InputSearch,
  Label,
  PersonAddOutlineIcon,
  Radio,
  Skeleton,
  TableColumns,
  TableColumnsColumnProps,
  Text,
} from '@mercai/clever'

import { SupplierProductProps, SupplierProps } from '@/types'

import { SupplierProductsTableProps } from './supplier-products-table.type'

import styles from './supplier-products-table.module.css'

import { useSupplierList } from '@/services'
import { formatCurrency, formatDate, formatNumber } from '@/helpers'
import { EmptyState } from '@/components'

export const SupplierProductsTable = ({
  isLoading,
  data,
  selectedSupplierProduct,
  onSelectedSupplierProduct,
  setSupplier,
  supplier,
}: SupplierProductsTableProps) => {
  const { t } = useTranslation('screenBusinessV2')

  const buttonRef = useRef<HTMLDivElement | null>(null)
  const popperRef = useRef<HTMLDivElement | null>(null)

  const [filterIsOpen, setFilterIsOpen] = useState(false)

  const [position, setPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  })

  const [searchFilter, setSearchFilter] = useState('')

  const { data: dataSupplier, isLoading: isLoadingSupplier } = useSupplierList({
    isEnable: filterIsOpen,
    params: { search: searchFilter },
  })

  const onToggleSupplier = (value: SupplierProps) => {
    setSupplier({ ...value })
    setFilterIsOpen(false)
  }

  const setPopperPosition = () => {
    if (buttonRef.current) {
      const rectButton = buttonRef.current.getBoundingClientRect()
      setPosition({
        top: rectButton.bottom + window.scrollY + 10,
        left: rectButton.left + window.scrollX - 400,
      })
    }
  }

  const handleShowPopper = () => {
    setPopperPosition()
    setFilterIsOpen((prev) => !prev)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (
      popperRef.current &&
      !popperRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setFilterIsOpen(false)
    }
  }

  const columns: TableColumnsColumnProps<SupplierProductProps>[] = [
    {
      title: t('catalogProductsTableName'),
      dataKey: 'supplierWarehouse.supplier.name',
      key: 'supplierWarehouse.supplier.name',
      render: ({ value, index, record }) => (
        <div className={styles['table-supplier-column']}>
          <Radio
            value={selectedSupplierProduct === index}
            onChange={() => onSelectedSupplierProduct?.(index)}
          />
          <span>
            <Text className={styles['table-supplier-name']}>
              {value || '-'}
            </Text>
            <Text
              variant="text-small"
              className={styles['table-supplier-email']}
            >
              {record?.supplierWarehouse?.supplier?.email || '-'}
            </Text>
          </span>
        </div>
      ),
      renderHeader: ({ title }) => (
        <div className={styles['table-supplier-header']}>
          <Text className={styles['table-supplier-header-text']}>
            <strong>{title}</strong>
          </Text>
          <div ref={buttonRef} onClick={handleShowPopper}>
            <PersonAddOutlineIcon />
          </div>
        </div>
      ),
    },
    {
      title: t('catalogProductsTableMin'),
      dataKey: 'minimumPurchaseQuantity',
      key: 'minimumPurchaseQuantity',
      width: '12rem',
      render: ({ record }) =>
        record?.minimumPurchaseQuantity
          ? `${formatNumber(record.minimumPurchaseQuantity)} Kg`
          : '-',
    },
    {
      title: t('catalogProductsTablePrice'),
      key: 'priceWithoutIVA',
      dataKey: 'price',
      width: '12rem',
      render: ({ record }) =>
        record?.price ? formatCurrency(record.price, 'es-MX', 'USD') : '-',
    },
    {
      title: t('catalogProductsTableLocation'),
      key: 'supplierWarehouseUuid',
      dataKey: 'supplierWarehouseUuid',
      width: '12rem',
      render: ({ record }) => (
        <div className={styles['warehouse-column']}>
          {record?.supplierWarehouse?.city ? (
            <>
              <img
                src={
                  record?.supplierWarehouse?.supplier?.isForeign
                    ? '/images/flag-us.svg'
                    : '/images/flag-mx.svg'
                }
                alt={'flag'}
              />
              {record?.supplierWarehouse?.city}
            </>
          ) : (
            '-'
          )}
        </div>
      ),
    },
  ]

  const FilterRow = useCallback(
    ({
      supplierData,
      hidden = false,
    }: {
      supplierData: SupplierProps
      hidden?: boolean
    }) => {
      return (
        <div
          key={supplierData?.uuid}
          className={styles['filter-list-item']}
          style={{ display: hidden ? 'none' : 'flex' }}
        >
          <Radio
            value={false}
            onChange={() => onToggleSupplier(supplierData)}
          />
          <Text>{supplierData?.name}</Text>
        </div>
      )
    },
    [
      isLoading,
      data,
      selectedSupplierProduct,
      onSelectedSupplierProduct,
      setSupplier,
      supplier,
    ],
  )

  const FilterLoading = () => {
    return (
      <>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.2rem" />
        </div>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.8rem" />
        </div>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.2rem" />
        </div>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.2rem" />
        </div>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.8rem" />
        </div>
        <div className={styles['filter-list-item']}>
          <Skeleton height="1.5rem" width="1.5rem" />
          <Skeleton height="1.5rem" width="12.2rem" />
        </div>
      </>
    )
  }

  const ExpandedComponent = ({ record }: { record: SupplierProductProps }) => {
    return (
      <div className={styles['expanded-content']}>
        <div>
          <Text>
            <strong>{t('catalogProductsTableQuantity')}</strong>
            {': '}
            {record?.quantity ? formatNumber(record.quantity) : '-'}
            {' Kg'}
          </Text>
          <Text>
            <strong>{t('catalogProductsTableUpdatedAt')}</strong>
            {': '}
            {record?.updatedAt ? formatDate(record.updatedAt) : '-'}
          </Text>
        </div>
        <div>
          <Text>
            <strong>{t('catalogProductsTableCountry')}</strong>
            {': '}
            {record?.supplierWarehouse?.country || '-'}
          </Text>
        </div>
      </div>
    )
  }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    if (filterIsOpen) {
      interval = setInterval(setPopperPosition, 10)

      setPopperPosition()
    }

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [filterIsOpen])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles['table-container']}>
      <TableColumns
        columns={columns}
        data={data || []}
        selectedRow={selectedSupplierProduct}
        onSelectRow={onSelectedSupplierProduct}
        isLoading={isLoading}
        expandableComponent={ExpandedComponent}
      />

      {!isLoading && data?.length === 0 && (
        <EmptyState
          title={t('supplierProductsTableEmptyTitle')}
          description={t('supplierProductsTableEmptyDescription')}
          buttonText={t('supplierProductsTableEmptyButton')}
          onClickButton={() => {
            setFilterIsOpen(true)
          }}
        />
      )}
      {filterIsOpen && (
        <>
          <div
            ref={popperRef}
            className={styles.popper}
            style={{ top: position.top, left: position.left }}
          >
            <div className={styles['filter-wrapper']}>
              <div>
                <Label>{t('supplierProductsTableSupplierFilterLabel')}</Label>
                <InputSearch
                  className={styles['filter-search-input']}
                  value={searchFilter}
                  onChange={setSearchFilter}
                />
              </div>
              <div className={styles['filter-list']}>
                {isLoadingSupplier && <FilterLoading />}
                {!isLoadingSupplier && (
                  <FilterRow
                    supplierData={supplier}
                    key={supplier?.uuid}
                    hidden={
                      !supplier ||
                      !!dataSupplier?.data?.results?.find(
                        (sup) => sup?.uuid === supplier?.uuid,
                      )
                    }
                  />
                )}
                {!isLoadingSupplier &&
                  dataSupplier?.data?.results?.map((supp) => (
                    <FilterRow supplierData={supp} key={supp?.uuid} />
                  ))}
                {!isLoadingSupplier &&
                  dataSupplier?.data?.results?.length === 0 &&
                  !supplier && (
                    <Text>{t('supplierProductsTableSupplierFilterEmpty')}</Text>
                  )}
              </div>
            </div>
          </div>
          <div className={styles['popper-backdrop']} />
        </>
      )}
    </div>
  )
}
