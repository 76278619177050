import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Checkbox,
  DropDown,
  TableColumns,
  Text,
  Select,
  Pagination,
  SettingIcon,
  TableColumnsColumnProps,
  InputSearch,
  ArrowRightIcon,
  PlusIcon,
} from '@mercai/clever'

import { DEFAULT_LIMIT_PAGE } from '@/constants'

import { DrawerFormAIChat } from '@/components'

import {
  formatDate,
  useParamsState,
  useCookieState,
  trackEvent,
} from '@/helpers'

import { useAIConversationList } from '@/services'
import { IAConversationProps } from '@/types'

import styles from './list.module.css'

export const ConversationsList = () => {
  const { t } = useTranslation('screenAiCenter')

  const [totalPages, setTotalPages] = useState(1)

  const [columnsKeys, setColumnsKeys] = useCookieState<string[]>(
    '@mercai/conversations-list-columns',
    ['createdBy', 'status', 'messages', 'createdAt', 'actions'],
  )

  const [filter, setFilter] = useParamsState({
    page: '1',
    limit: `${DEFAULT_LIMIT_PAGE}`,
    search: '',
  })

  const [queryParams, setQueryParams] = useParamsState({
    uuid: '',
    show: '',
    form: '',
  })

  const onHandleColumnsShow = (key: string) => {
    let newColumnsKeys = columnsKeys

    if (columnsKeys.includes(key)) {
      newColumnsKeys = columnsKeys.filter((item) => item !== key)
    } else {
      newColumnsKeys = [...columnsKeys, key]
    }

    setColumnsKeys(newColumnsKeys)
  }

  const onHandleNewRegister = () => {
    setQueryParams({ ...queryParams, show: '', form: 'true', uuid: '' })
  }

  const getColumnsShow = useCallback(
    (key: string) => columnsKeys.includes(key),
    [columnsKeys],
  )

  const handleFilter = (changedKey: string, newValue: string) => {
    setFilter({ ...filter, [changedKey]: newValue })
  }

  const { data, isLoading } = useAIConversationList({ params: filter })

  useEffect(() => {
    if (data?.data?.count) {
      setTotalPages(Math.ceil(data?.data?.count / +filter.limit))
    }
  }, [data])

  useEffect(() => {
    trackEvent('adminAiCenterConversationsListPageView')
  }, [])

  const onHandleEditRegister = (uuid: string) => {
    setQueryParams({ ...queryParams, form: 'true', show: '', uuid })
  }

  const columns: TableColumnsColumnProps<IAConversationProps>[] = [
    {
      title: t('conversationTableCreatedBy'),
      key: 'createdBy',
      dataKey: 'createdBy',
    },
    {
      title: t('conversationTableStatus'),
      key: 'status',
      dataKey: 'status',
    },
    {
      title: t('conversationTableMessages'),
      key: 'messages',
      dataKey: 'messages',
      render: ({ value }) => `${value?.length} messages`,
    },
    {
      title: t('conversationTableCreatedAt'),
      key: 'createdAt',
      dataKey: 'createdAt',
      render: ({ value }) => (value ? formatDate(value) : '-'),
    },
    {
      title: t('conversationTableActions'),
      key: 'uuid',
      dataKey: 'uuid',
      width: '5rem',
      render: ({ record }) => (
        <div className={styles['action-buttons']}>
          <Button
            variant="primary"
            onClick={() => onHandleEditRegister(record.uuid || '')}
            model="square"
          >
            <ArrowRightIcon />
          </Button>
        </div>
      ),
    },
  ]

  const memoizedColumns = useMemo(
    () => columns.filter((column) => getColumnsShow(column.key)).map((c) => c),
    [columns, getColumnsShow],
  )

  return (
    <div>
      <div className={styles.filter}>
        <div className={styles['filter-group']}>
          <InputSearch
            value={filter.search as string}
            onChange={(value) => handleFilter('search', value)}
            className={styles['search-input']}
            translations={{
              inputPlaceholder: t('searchFilterPlaceholder'),
            }}
          />
        </div>

        <div className={styles['filter-group']}>
          <Button onClick={onHandleNewRegister}>
            <PlusIcon />
            {t('conversationTableNewRegister')}
          </Button>

          <DropDown.Root>
            <DropDown.ButtonTrigger
              variant="secondary"
              model="square"
              hasArrow={false}
            >
              <SettingIcon />
            </DropDown.ButtonTrigger>

            <DropDown.Content orientation="bottom-right">
              {columns?.map((column) => (
                <DropDown.Item key={column.dataKey}>
                  <Checkbox
                    onChange={() => onHandleColumnsShow(column.key)}
                    value={getColumnsShow(column.key)}
                  >
                    {column.title}
                  </Checkbox>
                </DropDown.Item>
              ))}
            </DropDown.Content>
          </DropDown.Root>
        </div>
      </div>

      <TableColumns<IAConversationProps>
        isLoading={isLoading}
        columns={memoizedColumns}
        data={data?.data?.results || []}
      />

      <div className={styles.pagination}>
        <div className={styles['pagination-show-registers']}>
          <Text>
            {t('agentTableShowRegisters', {
              showNumber:
                (data?.data?.count || 0) < +filter.limit
                  ? data?.data?.count
                  : +filter.limit,
              totalNumber: data?.data?.count || 0,
            })}
          </Text>

          <Select
            value={filter.limit}
            onChange={(value) => handleFilter('limit', value)}
            options={[
              { label: '10', value: '10' },
              { label: '20', value: '20' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
          />
        </div>

        <Pagination
          totalPages={totalPages}
          onChange={(page) => handleFilter('page', `${page}`)}
          currentPage={+filter.page as number}
        />
      </div>

      <DrawerFormAIChat
        isOpen={queryParams.form === 'true'}
        onClose={() => setQueryParams({ ...queryParams, form: '' })}
        uuid={queryParams.uuid}
      />
    </div>
  )
}
