import { useEffect, useState } from 'react'
import { Input } from '../../core'

import { InputDebounceProps } from './input-debounce.type'

export const InputDebounce = ({
  onChange,
  value,
  className,
  error,
  placeholder,
}: InputDebounceProps) => {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) setLocalValue(value)
  }, [value])

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localValue === value) return
      onChange(localValue)
    }, 500)
    return () => clearTimeout(handler)
  }, [localValue, onChange])

  return (
    <Input
      className={className}
      placeholder={placeholder}
      value={localValue}
      onChange={setLocalValue}
      error={error}
    />
  )
}
