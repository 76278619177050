import { Button, Input, Select, Label, PlusIcon, Switch } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AIAgentFormProps } from './agent-form.type'

import styles from './agent-form.module.css'

export const AgentForm = ({
  onChangeValues,
  values,
  errors,
}: AIAgentFormProps) => {
  const { t } = useTranslation('screenAiCenter')

  const onHandleValues = (key: string, value: string | number | boolean) => {
    onChangeValues({ ...values, [key]: value })
  }

  const onHandleContext = (key: number, value: string) => {
    const context = values?.context || []
    context[key] = value
    onChangeValues({ ...values, context })
  }

  const onAddContext = () => {
    const context = values?.context || []
    context.push('')
    onChangeValues({ ...values, context })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('agentFormNameLabel')}</Label>
        <Input
          placeholder={t('agentFormNamePlaceholder')}
          value={values?.name}
          onChange={(newValue) => onHandleValues('name', newValue)}
          error={errors?.name as string}
        />
      </div>

      <div>
        <Label>{t('agentFormRoleLabel')}</Label>
        <Select
          placeholder={t('agentFormRolePlaceholder')}
          value={values?.role}
          onChange={(newValue) => onHandleValues('role', newValue)}
          error={errors?.role as string}
          options={[
            {
              label: 'Customer',
              value: 'customer',
            },
            {
              label: 'Admin',
              value: 'admin',
            },
          ]}
        />
      </div>

      <div>
        <Label>{t('agentFormTypeLabel')}</Label>
        <Select
          placeholder={t('agentFormTypePlaceholder')}
          value={values?.type}
          onChange={(newValue) => onHandleValues('type', newValue)}
          error={errors?.type as string}
          options={[
            {
              label: 'Flow',
              value: 'flow',
            },
            {
              label: 'System',
              value: 'system',
            },
          ]}
        />
      </div>

      {values?.type === 'flow' && (
        <div>
          <Label>{t('agentFormIdentifyLabel')}</Label>
          <Input
            placeholder={t('agentFormIdentifyPlaceholder')}
            value={values?.identify}
            onChange={(newValue) => onHandleValues('identify', newValue)}
            isTextArea
            error={errors?.identify as string}
          />
        </div>
      )}

      <div className={styles['items-container']}>
        <Label>{t('agentFormContextLabel')}</Label>

        <div className={styles.items}>
          {values?.context?.map((item, index) => (
            <Input
              key={index}
              placeholder={t('agentFormContextPlaceholder')}
              value={typeof item === 'object' ? JSON.stringify(item) : item}
              isTextArea
              onChange={(newValue) => onHandleContext(index, newValue)}
              className={styles.item}
            />
          ))}

          <Button onClick={onAddContext}>
            <PlusIcon />
            {t('agentFormAddContext')}
          </Button>
        </div>
      </div>

      <div>
        <Label>{t('agentFormActiveLabel')}</Label>
        <Switch
          checked={values?.active || false}
          onChange={(checked) => onHandleValues('active', checked)}
        />
      </div>
    </div>
  )
}
