import { Input, Label, MultiSelect, Radio } from '@mercai/clever'
import { useTranslation } from 'react-i18next'

import type { AddressFormProps } from './address-form.type'

import styles from './address-form.module.css'
import { LogisticShipmentVehicleTypeEnum } from '@/types'

export const AddressForm = ({
  onChangeValues,
  values,
  errors,
}: AddressFormProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenQuotationList')

  const onHandleValues = (
    key: string,
    value: boolean | string | number | string[],
  ) => {
    onChangeValues({ ...values, [key]: value })
  }

  return (
    <div className={styles.core}>
      <div>
        <Label>{t('quotationFormAddressFormPostalCode')}</Label>
        <Input
          value={values?.postalCode}
          onChange={(newValue) => onHandleValues('postalCode', newValue)}
          error={errors?.postalCode}
        />
      </div>

      <div>
        <Label>{t('quotationFormAddressFormState')}</Label>
        <Input
          value={values?.state}
          onChange={(newValue) => onHandleValues('state', newValue)}
          error={errors?.state}
        />
      </div>

      <div>
        <Label>{t('quotationFormAddressFormCity')}</Label>
        <Input
          value={values?.city}
          onChange={(newValue) => onHandleValues('city', newValue)}
          error={errors?.city}
        />
      </div>

      <div>
        <Label>{t('quotationFormAddressFormNeighborhood')}</Label>
        <Input
          value={values?.neighborhood}
          onChange={(newValue) => onHandleValues('neighborhood', newValue)}
          error={errors?.neighborhood}
        />
      </div>

      <div className={styles.row}>
        <div>
          <Label>{t('quotationFormAddressFormStreet')}</Label>
          <Input
            value={values?.street}
            onChange={(newValue) => onHandleValues('street', newValue)}
            error={errors?.street}
          />
        </div>

        <div>
          <Label>{t('quotationFormAddressFormNumber')}</Label>
          <Input
            value={values?.number ? String(values?.number) : ''}
            onChange={(newValue) => onHandleValues('number', newValue)}
            error={errors?.number}
          />
        </div>
      </div>

      <div>
        <Label>
          {t('quotationFormAddressFormReference')}{' '}
          <span>{t('quotationFormAddressFormFieldOptional')}</span>
        </Label>
        <Input
          isTextArea
          value={values?.reference}
          onChange={(newValue) => onHandleValues('reference', newValue)}
          error={errors?.reference}
        />
      </div>
      <div>
        <Label>
          {t('quotationFormAddressFormNeedsUnloadingSupport')}{' '}
          <span>{t('quotationFormAddressFormFieldOptional')}</span>
        </Label>
        <Radio
          value={!!values?.needsUnloadingSupport}
          onChange={() => onHandleValues('needsUnloadingSupport', true)}
        >
          {t('quotationFormAddressFormYes')}
        </Radio>

        <Radio
          value={!values?.needsUnloadingSupport}
          onChange={() => onHandleValues('needsUnloadingSupport', false)}
        >
          {t('quotationFormAddressFormNo')}
        </Radio>
      </div>
      <div>
        <Label>
          {t('quotationFormAddressFormRestrictedVehicles')}{' '}
          <span>{t('quotationFormAddressFormFieldOptional')}</span>
        </Label>
        <MultiSelect
          options={Object.values(LogisticShipmentVehicleTypeEnum).map(
            (value) => ({
              label: tEnums(`logistic_shipment_type_${value}`),
              value,
            }),
          )}
          values={values.restrictedVehicles}
          onChange={(newValue) =>
            onHandleValues('restrictedVehicles', newValue)
          }
        />
      </div>
    </div>
  )
}
