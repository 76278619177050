import { useTranslation } from 'react-i18next'

import {
  Button,
  CreditLetterIcon,
  EditIcon,
  PinIcon,
  ProfileIcon,
  Select,
  Text,
} from '@mercai/clever'
import { Accordion } from '../accordion'

import { GeneralInformationProps } from './general-information.type'

import styles from './general-information.module.css'

export const GeneralInformation = ({
  position,
  isComplete,
  isEditable,
  quotationValues,
  setQuotationValues,
  buyerProfile,
  address,
  activeItems,
  setActiveItems,
  onHandleAddressDrawerOpen,
  onHandleBuyerDrawerOpen,
  getMetrics,
  errors,
}: GeneralInformationProps) => {
  const { t: tEnums } = useTranslation('enums')
  const { t } = useTranslation('screenBusinessV2')

  const onChangeValues = (key: string, value: string) => {
    setQuotationValues({
      ...quotationValues,
      [key]: value,
    })
  }

  return (
    <Accordion
      activeItems={activeItems}
      setActiveItems={setActiveItems}
      title={`${position}. ${t('sectionGeneralInformationTitle')}`}
      id="general-information"
      key="general-information"
      isComplete={isEditable ? isComplete : undefined}
    >
      <div>
        <div className={styles['info-block']}>
          <div className={styles.title}>
            <ProfileIcon className={styles.icon} />
            <Text variant="title-medium">{t('buyerLabel')}</Text>
          </div>
          <div className={styles['info-row']}>
            <div className={styles['info-row-content']}>
              <Text className={styles['info-item']}>
                <strong>{t('companyLabel')}:</strong>{' '}
                {buyerProfile?.companyName || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('emailLabel')}:</strong> {buyerProfile?.email || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('customerLevelLabel')}:</strong>{' '}
                {(buyerProfile?.customerLevel as unknown as string) || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('phoneLabel')}:</strong> {buyerProfile?.phone || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('originLabel')}:</strong>{' '}
                {buyerProfile?.type
                  ? tEnums(`profile_type_${buyerProfile?.type}`)
                  : '-'}
              </Text>
            </div>
            <Button
              variant="secondary"
              model="square"
              size="small"
              onClick={onHandleBuyerDrawerOpen}
            >
              <EditIcon />
            </Button>
          </div>
          {!!errors?.buyerProfileUuid && (
            <Text variant="text-base" className={styles.error}>
              {errors.buyerProfileUuid as string}
            </Text>
          )}
        </div>

        <div className={styles['info-block']}>
          <div className={styles.title}>
            <PinIcon className={styles.icon} />
            <Text variant="title-medium">{t('deliveryLabel')}</Text>
          </div>
          <div className={styles['info-row']}>
            <div className={styles['info-row-content']}>
              <Text className={styles['info-item']}>
                <strong>{t('postalCodeLabel')}:</strong>{' '}
                {address?.postalCode || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('countryLabel')}:</strong> {address?.country || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('stateLabel')}:</strong> {address?.state || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('cityLabel')}:</strong> {address?.city || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('neighborhoodLabel')}:</strong>{' '}
                {address?.neighborhood || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('streetLabel')}:</strong> {address?.street || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('referencePointLabel')}:</strong>{' '}
                {address?.reference || '-'}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('needsUnloadingSupportLabel')}:</strong>{' '}
                {address?.needsUnloadingSupport ? t('yesLabel') : t('noLabel')}
              </Text>
              <Text className={styles['info-item']}>
                <strong>{t('restrictedVehiclesLabel')}:</strong>{' '}
                {address?.restrictedVehicles &&
                address?.restrictedVehicles?.length > 0
                  ? address?.restrictedVehicles?.map((vehicle, i) => {
                      return `${i > 0 ? ',' : ''} ${tEnums(
                        `logistic_shipment_type_${vehicle}`,
                      )}`
                    })
                  : '-'}
              </Text>
            </div>
            <Button
              variant="secondary"
              model="square"
              size="small"
              onClick={onHandleAddressDrawerOpen}
            >
              <EditIcon />
            </Button>
          </div>
          {!!errors?.deliveryAddressUuid && (
            <Text variant="text-base" className={styles.error}>
              {errors.deliveryAddressUuid as string}
            </Text>
          )}
        </div>

        {isEditable && (
          <div className={styles['info-block']}>
            <div className={styles.title}>
              <CreditLetterIcon className={styles.icon} />
              <Text variant="title-medium">{t('paymentLabel')}</Text>
            </div>
            <div className={styles['info-row-content']}>
              <div>
                <Text className={styles.label}>
                  {t('paymentConditionLabel')}
                  <span>*</span>
                </Text>
                <Select
                  value={quotationValues?.paymentFormOption}
                  onChange={(value) => {
                    onChangeValues('paymentFormOption', value)
                    getMetrics()
                  }}
                  options={
                    buyerProfile?.financeProfile?.enabledPaymentForms?.map(
                      (form) => ({
                        label: tEnums(
                          `financial_profile_payment_forms_${form}`,
                        ),
                        value: form,
                      }),
                    ) || []
                  }
                  placeholder={t('selectPlaceholder')}
                  error={errors?.paymentFormOption as string}
                />
              </div>
              <div>
                <Text className={styles.label}>
                  {t('paymentMethodLabel')}
                  <span>*</span>
                </Text>
                <Select
                  value={quotationValues?.paymentMethodOption}
                  onChange={(value) => {
                    onChangeValues('paymentMethodOption', value)
                    getMetrics()
                  }}
                  options={
                    buyerProfile?.financeProfile?.enabledPaymentMethods?.map(
                      (payment) => ({
                        label: tEnums(
                          `financial_profile_transaction_payment_methods_${payment}`,
                        ),
                        value: payment,
                      }),
                    ) || []
                  }
                  placeholder={t('selectPlaceholder')}
                  error={errors?.paymentMethodOption as string}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Accordion>
  )
}
