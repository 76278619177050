import { useTranslation } from 'react-i18next'

import {
  Alert,
  AlertIcon,
  Button,
  CheckCircleOutilineIcon,
  EditIcon,
  InputNumber,
  RefreshIcon,
  Select,
  Text,
  TrashIcon,
} from '@mercai/clever'
import { Accordion } from '../accordion'

import { CatalogProductsProps } from './catalog-products.type'

import styles from './catalog-products.module.css'
import { useEffect, useMemo, useState } from 'react'
import {
  generateButtonClasses,
  insertSupplierInSupplierProductsData,
} from './catalog-products.constants'
import {
  CatalogProductProps,
  QuotationProductPackageEnum,
  QuotationProps,
  QuotationRequestItemProps,
  QuotationRequestProps,
  SupplierProductProps,
  SupplierProps,
} from '@/types'
import { deepCopy, useParamsState } from '@/helpers'
import { SupplierProductsTable } from '../supplier-products-table'
import {
  QUERIES_ADMIN,
  useCatalogCategoryList,
  useSupplierProductsList,
  useUpdateQuotationRequest,
} from '@/services'
import { SelectCatalogProductDrawer } from '../select-catalog-product-drawer'
import { ProductCard } from '../product-card'
import { ProductDrawerForm } from '../product-drawer-form'
import { useQueryClient } from '@tanstack/react-query'
import { SupplierProductDrawerForm } from '../supplier-product-drawer-form'

export const CatalogProducts = ({
  position,
  validation,
  isEditable,
  quotationValues,
  setQuotationValues,
  activeItems,
  setActiveItems,
  catalogProducts,
  getMetrics,
  getSuggestedUnitPrice,
  suggestedUnitPrice,
  isRfq,
  quotationRequest,
  errors,
}: CatalogProductsProps) => {
  const { t: tEnums } = useTranslation('enums')

  const { t } = useTranslation('screenBusinessV2')

  const [selected, setSelected] = useState<number>(0)

  const [queryParams, setQueryParams] = useParamsState({
    form: '',
  })

  const queryClient = useQueryClient()

  const { data: catalogCategoryList } = useCatalogCategoryList()

  const { mutateAsync: mutateAsyncUpdateQuotationRequest } =
    useUpdateQuotationRequest(quotationRequest?.uuid || '')

  const [productToUpdate, setProductToUpdate] = useState<
    Partial<QuotationRequestItemProps> | undefined
  >(quotationRequest?.items?.[selected])

  const [selectedSupplierProducts, setSelectedSupplierProducts] = useState<{
    [key: string]: string
  }>({})

  const [filter, setFilter] = useState({
    limit: 999,
    catalogProduct_Uuid:
      quotationValues?.products?.[selected]?.catalogProduct?.uuid || '',
  })

  const [supplierToSupplierProduct, setSupplierToSupplierProduct] = useState<
    SupplierProps | undefined
  >()

  const { data, isLoading } = useSupplierProductsList({
    params: {
      ...filter,
    },
    isEnable: !!(isEditable && filter?.catalogProduct_Uuid),
  })

  const onHandleValues = (
    key: string,
    value:
      | boolean
      | string
      | number
      | undefined
      | SupplierProps
      | CatalogProductProps,
    addSupplier = false,
  ) => {
    const quotationValuesAux = deepCopy(quotationValues) as QuotationProps

    quotationValuesAux.products[selected] = {
      ...quotationValuesAux?.products?.[selected],
      [key]: value,
      ...(key === 'supplier' && {
        supplierUuid: (value as SupplierProps)?.uuid,
      }),
      ...(addSupplier === true && {
        isSupplierWarehouseSelected: false,
      }),
      ...(key === 'catalogProduct' && {
        catalogProductUuid: (value as CatalogProductProps)?.uuid,
      }),
    }

    setQuotationValues({
      ...quotationValuesAux,
    })
  }

  const setSupplier = (supplier: SupplierProps | undefined) => {
    setSupplierToSupplierProduct(supplier)
    setQueryParams({ ...queryParams, form: 'create-supplier-product' })
  }

  const onCompleteCreateSupplierProduct = (
    supplierProducts: SupplierProductProps,
  ) => {
    onHandleValues('supplier', supplierToSupplierProduct)
    setSelectedSupplierProducts((prev) => ({
      ...prev,
      [catalogProducts?.[selected]?.uuid || '']: supplierProducts?.uuid || '',
    }))
  }

  const supplierProductsData = useMemo(() => {
    return insertSupplierInSupplierProductsData(
      data?.data?.results,
      quotationValues,
      selected,
    )
  }, [data, quotationValues, selected])

  const onHandleSetSelectedSupplierProduct = (index: number) => {
    const supplierProducts = {
      ...supplierProductsData?.[index],
    }

    const quotationValuesAux = deepCopy(quotationValues) as QuotationProps

    quotationValuesAux.products[selected] = {
      ...quotationValuesAux?.products?.[selected],
      supplier: supplierProducts?.supplierWarehouse?.supplier as SupplierProps,
      supplierUuid: supplierProducts?.supplierWarehouse?.supplier?.uuid,
      cost: supplierProducts?.price || 0,
      package: supplierProducts?.package || '25kg_bags',
      ...(supplierProducts?.supplierWarehouse?.uuid
        ? { isSupplierWarehouseSelected: true }
        : {}),
    }

    setQuotationValues({
      ...quotationValuesAux,
    })

    setSelectedSupplierProducts((prev) => ({
      ...prev,
      [catalogProducts?.[selected]?.uuid || '']: supplierProducts?.uuid || '',
    }))
  }

  const onHandleRemoveCatalogProduct = () => {
    setSelectedSupplierProducts((prev) => {
      const values = prev
      delete values[catalogProducts?.[selected]?.uuid || '']
      return {
        ...values,
      }
    })

    const quotationValuesAux = deepCopy(quotationValues) as QuotationProps

    quotationValuesAux.products.splice(selected, 1)

    setQuotationValues({
      ...quotationValuesAux,
    })
  }

  const onSelectProductItem = (productItem: CatalogProductProps) => {
    setSelectedSupplierProducts((prev) => {
      const values = prev
      delete values[catalogProducts?.[selected]?.uuid || '']
      return {
        ...values,
      }
    })
    onHandleValues('catalogProduct', productItem)
  }

  const onHandleRfqRemoveCatalogProduct = async () => {
    const quotationRequestAux = deepCopy(
      quotationRequest,
    ) as QuotationRequestProps

    quotationRequestAux.items.splice(selected, 1)

    const response = await mutateAsyncUpdateQuotationRequest({
      ...quotationRequestAux,
    })

    if (response.success) {
      setSelectedSupplierProducts((prev) => {
        const values = prev
        delete values[catalogProducts?.[selected]?.uuid || '']
        return {
          ...values,
        }
      })

      setSelected(0)

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })
      setQueryParams({ form: '' })
    }
  }

  const onHandleSubmit = async () => {
    const quotationRequestAux = deepCopy(
      quotationRequest,
    ) as QuotationRequestProps
    quotationRequestAux.items[selected] = {
      ...quotationRequestAux?.items?.[selected],
      ...productToUpdate,
      uuid: quotationRequestAux?.items?.[selected]?.uuid,
      catalogProductUuid: productToUpdate?.catalogProduct?.uuid,
    }

    const response = await mutateAsyncUpdateQuotationRequest({
      ...quotationRequestAux,
    })

    if (response.success) {
      setSelectedSupplierProducts((prev) => ({
        ...prev,
        [catalogProducts?.[selected]?.uuid || '']: '',
      }))

      queryClient.invalidateQueries({
        queryKey: [QUERIES_ADMIN.QUOTATION_REQUEST.module],
      })
      setQueryParams({ form: '' })
    }
  }

  useEffect(() => {
    setFilter({
      ...filter,
      catalogProduct_Uuid:
        quotationValues?.products?.[selected]?.catalogProduct?.uuid || '',
    })

    if (
      quotationValues?.products?.[selected]?.supplier?.uuid &&
      !quotationValues?.products?.[selected]?.isSupplierWarehouseSelected
    ) {
      setSelectedSupplierProducts((prev) => ({
        ...prev,
        [catalogProducts?.[selected]?.uuid || '']:
          quotationValues?.products?.[selected]?.supplier?.uuid || '',
      }))
    }
  }, [quotationValues, selected])

  return (
    <Accordion
      activeItems={activeItems}
      setActiveItems={setActiveItems}
      title={`${position}. ${
        isEditable
          ? t('sectionCatalogProductsEditTitle')
          : t('sectionCatalogProductsTitle')
      }`}
      id="catalog-products"
      key="catalog-products"
      isComplete={isEditable ? validation?.isComplete : undefined}
    >
      <div className={styles.core}>
        <div className={styles['button-container']}>
          {quotationValues?.products?.map((product, i) => {
            const isCompleted =
              product?.isQuoted || validation?.productsIsQuoted?.[i]
            return (
              <Button
                key={`${product?.uuid}-${i}`}
                model="pill"
                variant="primary-outline"
                className={generateButtonClasses(
                  selected === i,
                  !!errors[`products[${i}]`],
                )}
                onClick={() => setSelected(i)}
              >
                {`${t('catalogProductsButton')} ${i + 1}`}
                {isEditable && isCompleted && <CheckCircleOutilineIcon />}
              </Button>
            )
          })}
        </div>

        {isEditable &&
          !quotationValues?.products?.[selected]?.catalogProduct && (
            <Alert
              variant="danger"
              leftIcon={<AlertIcon />}
              className={styles.warning}
              title={t('catalogProductsUnstructuredProductWarningTitle')}
            >
              {t('catalogProductsUnstructuredProductWarningDescription')}
              <Button
                variant="secondary"
                onClick={() =>
                  setQueryParams({ form: 'select-catalog-product' })
                }
              >
                {t('catalogProductsUnstructuredProductWarningButton')}
              </Button>
            </Alert>
          )}

        <ProductCard
          img={
            quotationValues?.products?.[selected]?.catalogProduct?.attributes
              ?.mainImage as string
          }
          title={
            quotationValues?.products?.[selected]?.catalogProduct?.attributes
              ?.title ||
            catalogProducts?.[selected]?.product?.title ||
            '-'
          }
          category={
            quotationValues?.products?.[selected]?.catalogProduct?.category
              ?.name ||
            catalogProducts?.[selected]?.product?.category ||
            '-'
          }
          density={
            quotationValues?.products?.[selected]?.catalogProduct?.attributes
              ?.density ||
            catalogProducts?.[selected]?.product?.density ||
            '-'
          }
          fluidity={
            quotationValues?.products?.[selected]?.catalogProduct?.attributes
              ?.fluidity ||
            catalogProducts?.[selected]?.product?.fluidity ||
            '-'
          }
          isSimilarAccepted={
            catalogProducts?.[selected]?.isSimilarAccepted || false
          }
          quantity={quotationValues?.products?.[selected]?.quantity}
          purchaseFrequency={catalogProducts?.[selected]?.purchaseFrequency}
          targetPrice={catalogProducts?.[selected]?.targetPrice}
          observation={catalogProducts?.[selected]?.observation}
          actions={
            isEditable && (
              <div className={styles['button-actions']}>
                {quotationValues?.products &&
                  quotationValues?.products?.length > 0 && (
                    <Button
                      model="square"
                      variant="secondary"
                      onClick={() =>
                        !isRfq
                          ? onHandleRemoveCatalogProduct()
                          : onHandleRfqRemoveCatalogProduct()
                      }
                    >
                      <TrashIcon />
                    </Button>
                  )}

                <Button
                  model="square"
                  variant="secondary"
                  onClick={() =>
                    setQueryParams({
                      ...queryParams,
                      form: 'select-catalog-product',
                    })
                  }
                >
                  <EditIcon />
                </Button>
              </div>
            )
          }
        />

        {isEditable && (
          <div className={styles['supplier-products-table']}>
            <SupplierProductsTable
              isLoading={isLoading}
              data={supplierProductsData}
              onSelectedSupplierProduct={onHandleSetSelectedSupplierProduct}
              selectedSupplierProduct={supplierProductsData?.findIndex(
                (val) =>
                  val?.uuid ===
                  selectedSupplierProducts?.[
                    catalogProducts?.[selected]?.uuid || ''
                  ],
              )}
              supplier={
                quotationValues?.products?.[selected]?.supplier as SupplierProps
              }
              setSupplier={setSupplier}
            />
          </div>
        )}

        {isEditable &&
          selectedSupplierProducts?.[
            catalogProducts?.[selected]?.uuid || ''
          ] !== undefined && (
            <div className={styles['supplier-product-prices']}>
              <Text variant="text-medium">
                <strong>{t('catalogProductsPricesTitle')}</strong>
              </Text>
              <div className={styles['supplier-product-prices-content']}>
                <div>
                  <Text variant="text-base" className={styles.label}>
                    {t('catalogProductsPricesUnitCostMercaiLabel')}{' '}
                    <span>*</span>
                  </Text>
                  <InputNumber
                    showIcons
                    value={quotationValues?.products?.[selected]?.cost}
                    onChange={(newValue) =>
                      onHandleValues('cost', Number(newValue)?.toFixed?.(3))
                    }
                    placeholder={t(
                      'catalogProductsPricesUnitCostMercaiPlaceholder',
                    )}
                    decimalPrecision={3}
                    prefix="$ "
                    onBlur={getMetrics}
                  />
                </div>
                <div>
                  <Text variant="text-base" className={styles.label}>
                    {t('catalogProductsPricesTraderCommissionLabel')}
                  </Text>
                  <InputNumber
                    showIcons
                    value={
                      quotationValues?.products?.[selected]?.traderCommission
                    }
                    onChange={(newValue) =>
                      onHandleValues('traderCommission', newValue)
                    }
                    placeholder={t(
                      'catalogProductsPricesTraderCommissionPlaceholder',
                    )}
                    decimalPrecision={5}
                    prefix="$ "
                    onBlur={getMetrics}
                  />
                </div>

                <div>
                  <Text variant="text-base" className={styles.label}>
                    {t('catalogProductsPricesQuantityLabel')} <span>*</span>
                  </Text>
                  <InputNumber
                    showIcons
                    value={quotationValues?.products?.[selected]?.quantity}
                    onChange={(newValue) =>
                      onHandleValues('quantity', newValue)
                    }
                    placeholder={t('catalogProductsPricesQuantityPlaceholder')}
                    decimalPrecision={3}
                    suffix=" kg"
                    onBlur={getMetrics}
                    error={
                      errors[`products[${selected}]`]
                        ?.quantity as unknown as string
                    }
                  />
                </div>

                <div>
                  <Text variant="text-base" className={styles.label}>
                    {t('catalogProductsPricesPackageLabel')} <span>*</span>
                  </Text>
                  <Select
                    placeholder={t('catalogProductsPricesPackagePlaceholder')}
                    options={Object.entries(QuotationProductPackageEnum).map(
                      ([key]) => ({
                        label: tEnums(`quotation_items_package_${key}`),
                        value: key,
                      }),
                    )}
                    value={quotationValues?.products?.[selected]?.package}
                    onChange={(newValue) => {
                      getMetrics()
                      onHandleValues('package', newValue)
                    }}
                    error={
                      errors[`products[${selected}]`]
                        ?.package as unknown as string
                    }
                  />
                </div>
              </div>
              <div className={styles['supplier-product-prices-suggested']}>
                <div
                  className={styles['supplier-product-prices-suggested-title']}
                >
                  <Text variant="text-medium">
                    <strong>
                      {t('catalogProductsPricesUnitSalePriceTitle')}
                    </strong>
                  </Text>
                  <Button
                    variant="success-light"
                    onClick={() => getSuggestedUnitPrice()}
                  >
                    {t('catalogProductsPricesUnitSalePriceButton')}
                    <RefreshIcon />
                  </Button>
                </div>
                <div
                  className={
                    styles['supplier-product-prices-content-suggested']
                  }
                >
                  <div>
                    <Text
                      variant="text-base"
                      className={`${styles.label} ${styles.suggested}`}
                    >
                      {t('catalogProductsClientPriceTargetLabel')}
                    </Text>
                    <InputNumber
                      disabled
                      value={catalogProducts?.[selected]?.targetPrice || 0}
                      placeholder={t(
                        'catalogProductsClientPriceTargetPlaceholder',
                      )}
                      decimalPrecision={3}
                      prefix="$ "
                      onBlur={getMetrics}
                    />
                  </div>
                  <div>
                    <Text
                      variant="text-base"
                      className={`${styles.label} ${styles.suggested}`}
                    >
                      {t('catalogProductsPricesSuggestedPriceLabel')}
                    </Text>
                    <InputNumber
                      disabled
                      value={
                        suggestedUnitPrice?.items?.[selected]
                          ?.unitProductPrice || 0
                      }
                      onChange={(newValue) =>
                        onHandleValues('price', Number(newValue)?.toFixed?.(3))
                      }
                      placeholder={t(
                        'catalogProductsPricesUnitSuggestedSalePricePlaceholder',
                      )}
                      decimalPrecision={3}
                      prefix="$ "
                      onBlur={getMetrics}
                    />
                  </div>
                  <div>
                    <Text variant="text-base" className={styles.label}>
                      {t('catalogProductsPricesUnitSalePriceLabel')}{' '}
                      <span>*</span>
                    </Text>
                    <InputNumber
                      showIcons
                      value={quotationValues?.products?.[selected]?.price}
                      onChange={(newValue) =>
                        onHandleValues('price', Number(newValue)?.toFixed?.(3))
                      }
                      placeholder={t(
                        'catalogProductsPricesUnitSalePricePlaceholder',
                      )}
                      decimalPrecision={3}
                      prefix="$ "
                      onBlur={getMetrics}
                      error={
                        errors[`products[${selected}]`]
                          ?.price as unknown as string
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

        {!isRfq && (
          <SelectCatalogProductDrawer
            isOpen={queryParams?.form === 'select-catalog-product'}
            onHandleOpen={() => setQueryParams({ ...queryParams, form: '' })}
            productItem={quotationValues?.products?.[selected]?.catalogProduct}
            onSelectProductItem={onSelectProductItem}
          />
        )}
        {isRfq && (
          <ProductDrawerForm
            isOpen={queryParams?.form === 'select-catalog-product'}
            onHandleOpen={() => setQueryParams({ ...queryParams, form: '' })}
            catalogCategoryList={catalogCategoryList?.data?.results || []}
            values={productToUpdate as Partial<QuotationRequestItemProps>}
            onChangeValues={(values) => setProductToUpdate(values)}
            onHandleSubmit={onHandleSubmit}
          />
        )}

        <SupplierProductDrawerForm
          isOpen={queryParams?.form === 'create-supplier-product'}
          onHandleOpen={() => setQueryParams({ ...queryParams, form: '' })}
          catalogProductUuid={
            quotationValues?.products?.[selected]?.catalogProduct?.uuid ||
            quotationValues?.products?.[selected]?.catalogProductUuid ||
            ''
          }
          supplierUuid={supplierToSupplierProduct?.uuid || ''}
          onCompleteCreateSupplierProduct={onCompleteCreateSupplierProduct}
        />
      </div>
    </Accordion>
  )
}
